import Axios from 'axios';
import React, { Component } from 'react';
import qs from 'qs';
import {
  REFERAL_INFORMATION,
  REFERENCE_TYPE,
  SUBJECTIVE_INFRMATION,
  SUBJECTIVE_STORE,
  COMPLETED_DOCTOR_VISIT,
  SOAP_DETAILS,
  LOADER_RED,
  LOADER,
  PATIENT_DATA,
  SOAP_CONFIGURATION_LIST,
  SEPARATEUPLOADEDDOCSNEW,
  TRAINING_DATA_API,
  TRAINING_DATA_AUTH_TOKEN,
  ISALLOWEDITSOAP,
  CHIEFCOMPLAINT_LIST,
  TOGGLE_PRACTICE,
  REFERRAL_DETAILS,
  DIAGNOSIS_DO_NOT_PRINT,
  PATIENT_INTERNAL_NOTES,
  GET_ARRAY_BUFFER_FORM_URL
} from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import AsideLeft from '../../components/aside/asideleft';
import copyImg from '../../assets/images/copy.png';
import icJpg from '../../assets/images/ic-jpg.png';
import icPdf from '../../assets/images/ic-pdf.png';
import { Modal, Carousel, Select, Tooltip, Input, Image } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import './subjective.scss';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import SubHeader from '../../components/subheader/subheader';
import { Link } from 'react-router-dom';
import { headerService$, practiceService, speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../../pages/speech-to-text/speech-recognition";
import API_SERVICE from '../../utils/apiService';
import { jQueryLabels, FixedCta } from '../../utils/jQueryLabels';
import MedicineTable from '../../components/drugs/medicine-table';
import Moment from 'react-moment';
const { Option } = Select;
class SubjectiveComponent extends Component {
  trainingData = [];
  constructor(props) {
    super(props);
    this.state = {
      confirmBtnStatus: true,
      patient: null,
      details: null,
      subjectives: null,
      subjective_fields: null,
      visitDataLoaded: false,
      chief_complaint: '',
      hpi: '',
      review_of_systems: '',
      internal_notes: '',
      chief_complaintCheck: false,
      hpiCheck: false,
      review_of_systemsCheck: false,
      field_status: null,
      referals: null,
      prescriptionTitle: '',
      copyPrescriptionModal: false,
      showPrescriptionDetails: true,
      prescriptionDataLoading: false,
      selectPrescription: '',
      isConfirm: false,
      speech: {
        identifier: null,
        state: false
      },
      totalVisit: '0',
      prescriptionDetailsData: [],
      viewDocumentModal: false,
      checkForEditsTrainingData: {
        "chief_complaint": undefined,
        "hpi": undefined,
        "review_of_systems": undefined
      },
      trainingDatafieldtypeSetter: {
        "chief-complaints": 0,
        "hpi": 1,
        "review-of-systems": 2
      },
      booking_id: "",
      doctor_id: "",
      isPracticing: false,
      isPracticingModal: false,
      isReferralModal: false,
      chiefComplaintList: [],
      chiefComplaintSelectedList: [],
      chiefComplaintSelectedListArr: [],
      referralDetailsArr: [],
      ccEditText: '',
      ccEditOriginalText: '',
      showInternalNotesField: true,
      internalNotesInfo: [],
      documentURL: '',
      downloadAllfiles: false,
      download_file_loading: false,
    }
    this.beforeChange.bind(this);
    this.carouselRef = React.createRef();
    this.chiefComplaintInput = React.createRef();
  }

  callTrainingDataApi = (trainingData) => {
    Axios({
      method: 'post',
      url: TRAINING_DATA_API,
      data: trainingData,
      headers: {
        Authorization: TRAINING_DATA_AUTH_TOKEN
      }
    });
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return null;
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

    if (+isPracticingHospitalId === +hospital_id) {
      this.setState({
        isPracticing: true
      })
    }

    const patient = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id
    }

    let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
    hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);

    let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;

    // this.setState({ chiefComplaintSelectedList : LS_SERVICE.get("chief_complaint_" + patient.patient_id + "_" + patient.qms_token_id)});

    this.setState({
      hospital_id,
      patient_id,
      reference_id,
      patient,
      pres_modification: hospitalConfigData.pres_modification,
      allow_hours: hospitalConfigData.allow_hours,
      allowEdit
    }, () => {
      this.handleSubjectivesData();
      this.handleReferralInformation();
      this.handlePrescriptionData();
      this.handleSoapFlow();
      this.getChiefcomplaintList();
      this.getInternalNotes();
    });
    // if (this.props.location.state !== undefined) {
    //     this.setState({
    //         patient: this.props?.location?.state?.patient
    //     }, () => {
    //         this.handleSubjectivesData();
    //         this.handleReferralInformation();
    //         this.handlePrescriptionData();
    //     })
    // } else {
    //     this.getPatientData();
    // }
    this.isEnabled();
    this.getReferralDetails();

    this.subscription = speechTextService.status().subscribe(async data => {
      const { patient } = this.state;
      if (this.state.speech.state) {
        if (this.state.speech.identifier === 'drop_list') {
          this.chiefComplaintInput.current.focus();

          let { chiefComplaintSelectedList } = this.state;
          chiefComplaintSelectedList.push(data.text.replace(".", ""));
          this.selectChiefComplaint(chiefComplaintSelectedList, chiefComplaintSelectedList);
          this.handleConfirmBtnStatus();
        }
        if (this.state.speech.identifier === 'hpi' || this.state.speech.identifier === 'review_of_systems' || this.state.speech.identifier === 'internal_notes') {
          if (this.state.speech.identifier === 'hpi') {
            this.setState({ hpi: data.text }, () => { this.handleConfirmBtnStatus() });
            LS_SERVICE.set("hpi_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
          }
          if (this.state.speech.identifier === 'review_of_systems') {
            this.setState({ review_of_systems: data.text }, () => { this.handleConfirmBtnStatus() });
            LS_SERVICE.set("review_of_systems_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
          }
          if (this.state.speech.identifier === 'internal_notes') {
            this.setState({ internal_notes: data.text }, () => { this.handleConfirmBtnStatus() });
            LS_SERVICE.set("internal_notes_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
          }
          document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
        }
      }
    });
  }

  async getInternalNotes() {
    const { showInternalNotesField, reference_id } = this.state;
    if (!showInternalNotesField) {
      return;
    }
    const notes = await Axios.get(PATIENT_INTERNAL_NOTES({ reference_type: REFERENCE_TYPE, reference_id: reference_id, type: 'soap' }));
    if (typeof notes.data != 'undefined' && typeof notes.data.data != 'undefined') {
      this.setState({
        internalNotesInfo: notes.data.data
      });
    }
  }

  getReferralDetails = () => {
    const { match: { params } } = this.props;
    const { patient_id, reference_id } = params;

    const doctor_id = LS_SERVICE.get('staff_id');

    const PARAMS = {
      patient_id: patient_id,
      speciality_id: LS_SERVICE.get('speciality_id') || null,
      reference_id: reference_id,
      doctor_id : doctor_id,
    }

    Axios.post(REFERRAL_DETAILS, qs.stringify(PARAMS))
      .then(res => {
        if (res.data.status && res.data.data.length > 0) {
          this.setState({
            referralDetailsArr: res.data.data
          })
        }
      })
  }

  componentDidUpdate() {
    setTimeout(() => {
      jQueryLabels();
      FixedCta();
      let Elem = document.querySelectorAll('.subjective-field');
      Elem.forEach(function (el) { el.style.height = el.scrollHeight + "px"; });
    }, 100);
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  autoHeight = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  }
  getPatientData = _ => {
    const { match: { params } } = this.props;
    const { reference_id } = params;
    Axios.get(PATIENT_DATA({ reference_id }))
      .then(success => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results.qmsToken.qms_token_id
        }
        this.setState({
          patient
        }, () => {
          this.handleSubjectivesData();
          this.handlePrescriptionData();
          this.handleReferralInformation();
        })
      })
      .catch(err => console.log(err))
  }

  getChiefcomplaintList(textValue = "") {
    const PARAMS = {
      value: textValue
    }

    Axios.post(CHIEFCOMPLAINT_LIST, PARAMS)
      .then(success => {
        this.setState({
          chiefComplaintList: success.data.data
        })
      })
      .catch(err => console.log(err))
  }

  handleSoapFlow = _ => {
    if (!LS_SERVICE.has('goToSoapPatientPageList') || LS_SERVICE.get('goToSoapPatientPageList').length === 0) {
      this.handleSoapInit();
    }
  }

  handleSoapInit = async _ => {
    const { hospital_id } = this.state.patient;

    const soapList = await API_SERVICE.post(SOAP_CONFIGURATION_LIST, {
      hospital_id,
      staff_id: LS_SERVICE.get('staff_id'),
      speciality_id: LS_SERVICE.get('speciality_id') || null
    });

    const pageList = [];
    const newList = [];

    const newSoapList = Object.values(soapList).map((data) => {
      return data.sort((a, b) => a.display_ordering - b.display_ordering)
    });

    Object.values(newSoapList).flat().forEach((en) => {
      if (en.display === 'Y') {
        if (!pageList.includes((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name)) {
          pageList.push((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name);
          newList.push({
            'url': en.alternate_url_format,
            'parent_module': en.parent_module,
            'module': (en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name
          })
        }
      }
    });

    LS_SERVICE.set('goToSoapPatientUrl', newList);
    LS_SERVICE.set('goToSoapPatientPageList', pageList);
  }

  handleOnChange = event => {
    const { name, value } = event.currentTarget;
    const { patient } = this.state;
    var identifierValue = event.target.id;

    LS_SERVICE.set(event.currentTarget.name + "_" + patient.patient_id + "_" + patient.qms_token_id, event.currentTarget.value);

    this.setState((prevState) => ({
      [name]: value,
      speech: {
        ...prevState.speech,
        identifier: identifierValue,
        initialText: value
      },
      checkForEditsTrainingData: {
        ...prevState.checkForEditsTrainingData,
        [event.target.id]: true
      }
    }), () => { this.handleConfirmBtnStatus(); })

  }


  handleConfirmBtnStatus = () => {
    const { subjective_fields, chiefComplaintSelectedList } = this.state;

    const field_arr = []
    Object.values(subjective_fields).forEach(sf => {
      if (sf.required) field_arr.push(sf.input_name)
    })

    const field_validation = new Array(field_arr.length).fill(false);

    field_arr.forEach((fa, idx) => {
      field_validation[idx] = (this.state[fa] == null || this.state[fa].trim() == '')
    })

    this.setState({ confirmBtnStatus: field_validation.some(fv => fv == true) });

    this.setState({ confirmBtnStatus: chiefComplaintSelectedList && chiefComplaintSelectedList.length > 0 ? false : true });
  }

  handleSubjectivesData = async () => {
    const { patient, showInternalNotesField } = this.state;
    const subjectivePromise = await Axios.get(SUBJECTIVE_INFRMATION({ reference_type: REFERENCE_TYPE, reference_id: patient?.qms_token_id }))
    if (subjectivePromise.data.status) {
      let { data, appointment } = subjectivePromise.data;
      const selectedChiefComplaintsData = subjectivePromise?.data.data.details[0]?.description_obj ? JSON.parse(subjectivePromise?.data.data.details[0]?.description_obj) : [];

      let { details, subjectives, chief_complaint, hpi, review_of_systems, internal_notes } = data;
      if (showInternalNotesField) {
        subjectives[Object.keys(subjectives).length] = {
          display_text: "Internal Notes (Not to be printed)",
          id: 3,
          input_name: "internal_notes",
          regex: "internal note[s]?",
          required: false,
          placeholder: "Type here",
          withLabel: true
        };
      }

      const { patient_note_doctor, patient_docments } = appointment;


      const docs = SEPARATEUPLOADEDDOCSNEW(patient_docments);

      let chiefComplaintList = [];
      let chiefComplaintListArr = [];
      details.forEach(sub => {
        if (sub.subjective_id === 0) {
          if (sub.master_description && sub.master_description.length > 0) {
            chiefComplaintList = sub.master_description.map(val => {
              return val.master_name !== "" && val.master_name;
            })
            chiefComplaintListArr = sub.master_description.map(val => {
              return { name: val.master_name, id: val.master_id }
            })
          }
          // } else if (sub.subjective_title === 'HPI') {
        } else if (sub.subjective_id == '1') {
          hpi = sub.description
        } else if (sub.subjective_id == '2') {
          review_of_systems = sub.description;
        } else {
          // internal_notes = sub.description
        }
      });
      let chief_complaint_draft = LS_SERVICE.get("chief_complaint_" + patient.patient_id + "_" + patient.qms_token_id);
      if (chief_complaint_draft !== null) {
        chiefComplaintList = chief_complaint_draft;
        chiefComplaintListArr = chief_complaint_draft.map(val => { return { name: val, id: null } })
      }

      this.setState({
        details,
        subjective_fields: subjectives,
        chiefComplaintSelectedList: LS_SERVICE.get("chief_complaint_" + patient.patient_id + "_" + patient.qms_token_id) !== null ? LS_SERVICE.get("chief_complaint_" + patient.patient_id + "_" + patient.qms_token_id) : (chiefComplaintList || ''),
        chiefComplaintSelectedListArr: chiefComplaintListArr,
        hpi: LS_SERVICE.get("hpi_" + patient.patient_id + "_" + patient.qms_token_id) !== null ? LS_SERVICE.get("hpi_" + patient.patient_id + "_" + patient.qms_token_id) : (hpi || ''),
        review_of_systems: LS_SERVICE.get("review_of_systems_" + patient.patient_id + "_" + patient.qms_token_id) !== null ? LS_SERVICE.get("review_of_systems_" + patient.patient_id + "_" + patient.qms_token_id) : (review_of_systems || ''),
        internal_notes: LS_SERVICE.get("internal_notes_" + patient.patient_id + "_" + patient.qms_token_id) !== null ? LS_SERVICE.get("internal_notes_" + patient.patient_id + "_" + patient.qms_token_id) : (internal_notes || ''),
        patient_note_doctor: patient_note_doctor || '',
        patient_docments: docs,
        visitDataLoaded: true,
        doctor_id: appointment?.doctor?.id,
        booking_id: appointment?.id,

      }, () => { this.handleConfirmBtnStatus() })
      console.log(patient_docments);
    }
  }

  handleReferralInformation = async () => {
    const { patient } = this.state;

    const PARAMS = {
      patient_id: patient?.patient_id
    }

    const referralData = await Axios.post(REFERAL_INFORMATION, qs.stringify(PARAMS))

    if (referralData.data.status) {
      let data = referralData.data;

      const { referals } = data;

      this.setState({
        referals
      })
    }
  }

  handleSubjectiveFormSubmit = async event => {
    if (event !== undefined && event !== null)
      event.preventDefault();

    const { hpi, review_of_systems, chief_complaint, patient, isPracticing, internal_notes } = this.state;

    if (!isPracticing) {
      this.handleModalPopup(event, 'isPracticingModal');
      return;
    }

    //Collecting Training Data
    this.setState(prevState => ({
      speech: {
        ...prevState.speech,
        state: false,
      }
    }), () => {
      speechService.init(this.state.speech)
      //Collecting Training Data
      targetArrays.map(e => {
        var obj = {
          booking_id: this.state.booking_id,
          source: this.state.doctor_id,
          doctor_id: 1605,
          step: this.state.checkForEditsTrainingData[e.id] ? 1 : 0,
          input_text: e.id === "hpi" ? hpi : e.id === "chief_complaint" ? chief_complaint : e.id === "review_of_systems" ? review_of_systems : internal_notes,
          field_type: this.state.trainingDatafieldtypeSetter[e.id]
        }
        this.trainingData.push(obj)
      })
      // console.log(trainingData)
      this.trainingData.map(e => {
        this.callTrainingDataApi(e)
      })
      //Collecting Training Data
    })

    //Collecting Training Data
    var targetArrays = []
    this.trainingData = []
    targetArrays.push(event.target[0], event.target[1], event.target[2])
    // console.log("Target Array:", targetArrays)

    const { qms_token_id, hospital_id, patient_id } = patient;

    const PARAMS = {
      reference_type: REFERENCE_TYPE,
      reference_id: qms_token_id,
      patient_id: patient_id,
      subjectives: [{
        id: 0,
        description: this.state.chiefComplaintSelectedListArr,//chief_complaint
      }, {
        id: 1,
        description: hpi
      }, {
        id: 2,
        description: review_of_systems
      }],
      internal_notes: internal_notes
    }

    let submitPromise;
    if (this.state.allowEdit) {
      this.setState({ confirmBtnStatus: true });
      submitPromise = await Axios.post(SUBJECTIVE_STORE, PARAMS);
    }

    if (this.state.allowEdit || submitPromise.data?.status) {
      const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
      const pageListArr = LS_SERVICE.get('goToSoapPatientPageList');
      let index = pageListArr.indexOf('subjective');

      let objectiveIndex = pageListArr.indexOf('objective');
      if (objectiveIndex == -1) {
        let childDevIndex = pageListArr.indexOf('child_development');
        const isPaediatrics = LS_SERVICE.get('isPaediatrics') ? LS_SERVICE.get('isPaediatrics') : false;
        if (childDevIndex == -1) {
          let vaccChartIndex = pageListArr.indexOf('vaccination_chart');
          if (vaccChartIndex == -1) {
            index++;
          } else {
            index = index + 2;
          }
        } else {
          if (isPaediatrics) {
            index++;
          } else {
            let vaccChartIndex = pageListArr.indexOf('vaccination_chart');
            if (vaccChartIndex == -1) {
              index = index + 2;
            } else {
              index = index + 3;
            }
          }
        }
      } else {
        index++;
      }

      if (index < pageListArr.length && !isTodayVisitArr.includes(qms_token_id)) {
        let soapurl = LS_SERVICE.get('goToSoapPatientUrl')[index].url;
        soapurl = soapurl.replace("HOSPITALID", hospital_id);
        soapurl = soapurl.replace("REFID", qms_token_id);
        soapurl = soapurl.replace("REFTYPE", REFERENCE_TYPE);
        soapurl = soapurl.replace("PATID", patient_id);
        this.props.history.push({
          pathname: soapurl,
          state: { patient }
        })
      } else {
        this.props.history.push({
          pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
          state: { patient }
        })
      }

      LS_SERVICE.set("chief_complaint_" + patient_id + "_" + qms_token_id, "");
      LS_SERVICE.set("hpi_" + patient_id + "_" + qms_token_id, "");
      LS_SERVICE.set("review_of_systems_" + patient_id + "_" + qms_token_id, "");
      LS_SERVICE.set("internal_notes_" + patient_id + "_" + qms_token_id, "");

    }
  }

  isEnabled = () => {
    const { hpi, chief_complaint } = this.state;
    return (typeof hpi != 'undefined' && typeof chief_complaint != 'undefined' && hpi != '' && chief_complaint != '') === 0
  }

  handlePrescriptionData = () => {
    const { patient } = this.state;
    const { patient_id } = patient;
    let doctor_id = LS_SERVICE.get("staff_id");
    let details= LS_SERVICE.get('call_patient_detail');
    let is_junior_doctor = LS_SERVICE.get('is_junior_doctor');
    if (is_junior_doctor == true && is_junior_doctor != null && is_junior_doctor != undefined) {
      doctor_id = details.doctor_id;
    }
    Axios.get(COMPLETED_DOCTOR_VISIT({ patient_id, doctor_id }))
      .then(success => {
        if (success.data.hasOwnProperty("visits") === true) {
          var final_prescription = [];
          for (var prescription of success.data.visits) {
            if (
              (LS_SERVICE.get('slot_hospital_master') == 'Y' && prescription.own_master == 'Y' && LS_SERVICE.get('slot_hospital_id') == prescription.hospitalId)
              ||
              (LS_SERVICE.get('slot_hospital_master') == 'N' && prescription.own_master == 'N')
            ) {
              final_prescription.push(prescription);
            }
          }
          this.setState({
            prescriptionData: final_prescription,
            totalVisit: final_prescription.length
          });
        }

      }).catch(err => console.log(err));

  }

  handleModalPopup = (e, popupName, data) => {
    if (e !== null) e.preventDefault();
    this.setState({
      [popupName]: !this.state[popupName]
    }, () => {
      this.setState({
        showPrescriptionDetails: true,
        prescriptionDataLoading: false
      }, () => {

        const { copyPrescriptionModal, viewDocumentModal } = this.state;

        if (copyPrescriptionModal) {
          this.setState({
            prescriptionTitle: "Please select the prescription you wish to copy from"
          })
        }

        if (viewDocumentModal) {
          this.carouselRef.current.goTo(data);
          let documentdata = this.state.patient_docments[data].src
          this.setState({
            documentURL: documentdata
          })
        }

      })
    })
  }

  prescriptionViewDetails = (e, name, date, referenceID) => {
    e.preventDefault();
    this.setState({
      showPrescriptionDetails: false,
      prescriptionDataLoading: true,
      prescriptionTitle: (
        <div className="row pb-2 emrfrm">
          <div className="col-md-6">
            <div className="text-left page-title btn-bg-accordion ml-0 rounded px-2 py-2 mt-1">{name}
              <small className="float-right">{moment(date).format('DD MMM, YYYY | hh:mm A')}</small></div>
          </div>
          <div className="col-md-6 text-right" id="accordionPastVisit">
            <div className="btn btn-outline-primary small text-uppercase mr-2 mt-1 d-inline-flex align-items-center icon-hover-white"
              onClick={(e) => this.handleProceedPrescription(e, referenceID)}>
              {/* <img height="20" src={copyImg} alt="Copy" />  */}
              <i className="icon-copy"></i>
              COPY FROM THIS PRESCRIPTION</div>
            <a href="#" className="btn btn-outline-secondary px-3 small text-uppercase mr-4 mt-1" onClick={e => this.prescriptionDetailsBack(e)}>BACK</a>
          </div>
        </div>
      )
    })
    const PARAMS = {
      reference_type: REFERENCE_TYPE,
      patient_id: this.state.patient.patient_id,
      reference_id: referenceID,  //this.state.patient.qms_token_id,
      hospital_id: LS_SERVICE.get("slot_hospital_id")
    }
    PARAMS.store_id = LS_SERVICE.get("STORE_ID");
    PARAMS.facility_id = LS_SERVICE.get("FACILITY_ID");
    PARAMS.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");
    Axios.post(SOAP_DETAILS, qs.stringify(PARAMS))
      .then((success) => {
        if (success.data.hasOwnProperty("details") === true) {
          this.setState({
            prescriptionDetailsData: success.data.details,
            prescriptionDataLoading: false
          })
        }
      })
      .catch((err) => { console.log(err) })
  }

  prescriptionDetailsBack = (e) => {
    e.preventDefault();
    this.setState({
      showPrescriptionDetails: true,
      prescriptionTitle: "Please select the prescription you wish to copy from"
    })
  }

  handleOnChangeprescription = (e, referenceID) => {
    this.setState({
      selectPrescription: referenceID,
      isConfirm: true
    })
  }

  handleCancelProceed = (e) => {
    this.setState({
      selectPrescription: '',
      isConfirm: false
    })
    this.handleModalPopup(e, 'confirmPrescriptionModal');
  }

  handleConfirmPrescription = (e) => {
    this.handleModalPopup(e, 'copyPrescriptionModal');
    this.handleModalPopup(e, 'confirmPrescriptionModal');
  }

  handleProceedPrescription = (e, referenceID) => {
    const { patient } = this.state;
    const { qms_token_id, hospital_id, patient_id } = patient;

    let reference_id = this.state.selectPrescription;
    if (referenceID !== '') {
      reference_id = referenceID
    }

    this.handleModalPopup(e, 'confirmPrescriptionModal');
    this.setState({
      selectPrescription: '',
      isConfirm: false
    }, () => {
      this.props.history.push({ // first is old reference id ( COPY PRESCRIPTION REFERNCE ID ) and second is new reference id ( IS CURRENT REFERENCE )
        pathname: `/copy-visit/selected/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}/${REFERENCE_TYPE}/${qms_token_id}`,
        state: { patient }
      });
    })
  }

  initSpeech(info) {
    let initialText = '';
    console.log("chief_complaint info: ", info);

    if (info.identifier === 'drop_list') {
      initialText = this.state.drop_list != undefined ? this.state.drop_list : '';
    }
    if (info.identifier === 'hpi') {
      initialText = this.state.hpi != undefined ? this.state.hpi : '';
    }
    if (info.identifier === 'review_of_systems') {
      initialText = this.state.review_of_systems != undefined ? this.state.review_of_systems : '';
    }
    if (info.identifier === 'internal_notes') {
      initialText = this.state.internal_notes != undefined ? this.state.internal_notes : '';
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {

        // console.log("In IF info.identifier===state.speech calling with : ", this.state.speech)
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
        }

      });
    }
    //  else {
    //     // console.log("IN Different MIC Previous Unclosed")
    //     //commented


    //     this.setState({
    //         speech: {
    //             identifier: this.state.speech.identifier,
    //             state: false,
    //             initialText: ''
    //         }
    //     }, () => {
    //         let resolverPromise= new Promise ((res, rej)=>{
    //             res(speechService.init(this.state.speech))
    //             rej("error")
    //         })
    //         // console.log("In else outside timeout calling with:", this.state.speech)
    //         // speechService.init(this.state.speech);

    //         resolverPromise
    //         .then(()=>{
    //             if (!this.state.speech.state) {
    //                 this.setState((prevState) => ({
    //                     checkForEditsTrainingData: {
    //                         ...this.state.checkForEditsTrainingData,
    //                         [info.identifier]: false
    //                     }
    //                 }))
    //             }
    //             // console.log("State in init:",this.state.speech)
    //             // setTimeout(() => {
    //                 this.setState({
    //                     speech: {
    //                         identifier: info.identifier,
    //                         state: true,
    //                         initialText: initialText
    //                     }
    //                 }, () => {
    //                     // console.log("State in init:",this.state.speech)

    //                     if (!this.state.speech.state) {
    //                         this.setState((prevState) => ({
    //                             checkForEditsTrainingData: {
    //                                 ...this.state.checkForEditsTrainingData,
    //                                 [info.identifier]: false
    //                             }
    //                         }))
    //                     }
    //                     console.log("Inside Timeout calling with:", this.state.speech)
    //                     speechService.init(this.state.speech);
    //                 });

    //             // }, 100);
    //         })

    //     });
    //     //commented

    //Working set
    // this.setState({
    //     speech: {
    //         identifier: info.identifier,
    //         state: !this.state.speech.state,
    //         initialText: initialText
    //     }
    // },()=>{
    //     console.log("In else outside timeout calling with:", this.state.speech)
    //     speechService.init(this.state.speech);

    // })
    //Working Set
    // }

    else {
      // console.log("IN Different MIC Previous Unclosed")
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
          this.handleRestartMic(info, initialText)
        }
        // console.log("State in init:",this.state.speech)

      });

    }

  }

  handleRestartMic = (info, initialText) => {
    // debugger
    this.setState({
      speech: {
        identifier: info.identifier,
        state: true,
        initialText: initialText
      }
    }, () => {
      // console.log("State in init:",this.state.speech)

      if (!this.state.speech.state) {
        this.setState((prevState) => ({
          checkForEditsTrainingData: {
            ...this.state.checkForEditsTrainingData,
            [info.identifier]: false
          }
        }))
      }

      speechService.init(this.state.speech);
    });

  }


  handleOnFocus = (e) => {
    // console.log("Previous Identifier Value:", this.state.speech.identifier)
    // console.log("Present Identifier Value:",e.target.id)
    var identifierValue = this.state.speech.identifier === e.target.id ? e.target.id : this.state.speech.identifier;
    this.setState(prevState => ({
      speech: {
        ...prevState.speech,
        identifier: identifierValue,
        state: false,
        initialText: identifierValue === "hpi" ? this.state.hpi : identifierValue === "drop_list" ? this.state.drop_list : identifierValue === "review_of_systems" ? this.state.review_of_systems : this.state.internal_notes
      },
      checkForEditsTrainingData: {
        ...prevState.checkForEditsTrainingData,
        [e.target.id]: false
      }
    }), () => {
      speechService.init(this.state.speech)
      // this.initSpeech({identifier: e.target.id})
    })

  }

  MedcineListLoad() {
    const { prescriptionDetailsData } = this.state;
    let medicineRows = {};
    for (const medInfo of prescriptionDetailsData.medicines) {
      let medKey = medInfo['drug_id'];
      medicineRows[medKey] = medicineRows[medKey] !== undefined ? (medicineRows[medKey] + 1) : 1;
    }

    let iteratedDrugs = [];
    let mederowCount = 1;
    let medicine_remarks = '';

    return prescriptionDetailsData.medicines.map((medicine, i) => {
      let medexists;
      if (!iteratedDrugs.includes(medicine.drug_id)) {
        medexists = false;
        mederowCount = 1;
        medicine_remarks = medicine.medicine_remarks
        iteratedDrugs.push(medicine.drug_id);
      } else {
        medexists = true;
        mederowCount = mederowCount + 1;
        if (medicine_remarks === '') {
          medicine_remarks = medicine.medicine_remarks;
        }
      }
      const drugRowSpan = medicineRows[medicine.drug_id] !== undefined && medicineRows[medicine.drug_id] > 1 ? medicineRows[medicine.drug_id] : 1;


      let htmlData = (
        <>
          <tr key={i}>
            <td>{medicine.drug_name}</td>
            <td>
              {(medicine.dosage !== 'undefined' && medicine.dosage !== null) ?
                prescriptionDetailsData.constants.drugDosage.filter(x => x.id === medicine.dosage)[0].description + ' ' + medicine.dosage_form_text
                : '-'
              }
            </td>
            <td>
              {(medicine.drug_frequency !== 'undefined' && medicine.drug_frequency !== null) ?
                prescriptionDetailsData.constants.drugFrequency.filter(x => x.id === medicine.drug_frequency)[0].description
                : '-'
              }
            </td>
            <td>{medicine.schedule_name}</td>
            <td>
              {(medicine.route !== 'undefined' && medicine.route !== null) ?
                prescriptionDetailsData.constants.route.filter(x => x.id === medicine.route)[0].description
                : '-'
              }
            </td>
            <td>
              {(medicine.instruction !== 'undefined' && medicine.instruction !== null) ?
                prescriptionDetailsData.constants.drugInstructions.filter(x => x.id === medicine.instruction)[0].description
                : '-'
              }
            </td>
            <td>
              {(medicine.instruction !== 'undefined' && medicine.instruction !== null) ?
                medicine.number_of_days + ' ' + medicine.number_of_days_type
                : ''}
            </td>
          </tr>

          {drugRowSpan === mederowCount && medicine_remarks !== '' && medicine_remarks !== null ?
            <tr>
              <td colspan="10" className="text-center py-2"><span className="text-muted">Remarks</span>&nbsp;{medicine_remarks}</td>
            </tr>
            : null}
        </>
      )
      return htmlData;
    });
  }

  selectChiefComplaint = (value, props) => {
    const { patient } = this.state;

    let chiefComplaintArr = [];
    if (props && props.length > 0) {
      chiefComplaintArr = props.map((data, index) => (data.value == value[index] && data.key !== undefined) ? { id: data.key, name: data.value } : { id: null, name: value[index] });
    }

    LS_SERVICE.set("chief_complaint_" + patient.patient_id + "_" + patient.qms_token_id, value);

    this.setState({
      chiefComplaintSelectedList: value,
      chiefComplaintSelectedListArr: chiefComplaintArr,
    }, () => {
      this.handleConfirmBtnStatus();
    });
  }

  optionChiefComplaintLoad() {
    if (this.state.chiefComplaintSelectedList && this.state.chiefComplaintSelectedList.length > 0) {
      return this.state.chiefComplaintSelectedList.map((obj, index) => {
        if(obj.complaint_text){
          return <Option>{obj.complaint_text}</Option>
        }
      })
    }
  }

  enablePractice = async e => {
    e.preventDefault();
    practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
    const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
      hospital_id: this.state.hospital_id,
      practice_session: 'start'
    });

    let is_practicing;
    if (practice.data && practice.data.is_practicing === 'Y') {
      is_practicing = true;
      LS_SERVICE.set('practicing', {
        status: true,
        hospital_id: this.state.hospital_id
      });
    } else {
      is_practicing = false;
    }

    this.setState({
      isPracticing: is_practicing,
    });
    this.handleModalPopup(null, 'isPracticingModal');
  }

  handleReferralDetails = () => {
    this.setState({
      isReferralModal: true
    });
  }

  ccTags(data) {
    return (
      <div className="ant-select-selection-overflow-item" style={{ opacity: 1 }}>
        <span className="ant-select-selection-item" title={data.label}>
          <Tooltip title="Double click to edit">
            <span className="ant-select-selection-item-content" onDoubleClick={() => {
              this.setState({ ccEditText: data.label, ccEditOriginalText: data.label });
            }}>{data.label}</span>
          </Tooltip>
          <span className="ant-select-selection-item-remove" unselectable="on" aria-hidden="true" style={{ userSelect: "none" }} onClick={() => data.onClose()}>
            <span role="img" aria-label="close" className="anticon anticon-close">
              <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
            </span>
          </span>
        </span>
      </div>
    );
  }

  updateCcChipInfo(e) {
    let { ccEditText, ccEditOriginalText, chiefComplaintSelectedList, chiefComplaintSelectedListArr, patient } = this.state;
    chiefComplaintSelectedList.map((element, i) => {
      if (element == ccEditOriginalText) {
        chiefComplaintSelectedList[i] = ccEditText;
        chiefComplaintSelectedListArr[i]['name'] = ccEditText;
        chiefComplaintSelectedListArr[i]['id'] = null;
      }
    });

    LS_SERVICE.set("chief_complaint_" + patient.patient_id + "_" + patient.qms_token_id, chiefComplaintSelectedList);
    this.setState({
      chiefComplaintSelectedList: chiefComplaintSelectedList,
      chiefComplaintSelectedListArr: chiefComplaintSelectedListArr,
      ccEditText: '',
      ccEditOriginalText: ''
    });
    e.preventDefault();
  }

  beforeChange = (slick, currentSlide, nextSlide) => {
    let documentdata = this.state.patient_docments[currentSlide].src
    this.setState({
      documentURL: documentdata
    })
  }

  selectAllFiles = (e) => {
    this.setState({ downloadAllfiles: e.target.checked });
  }

  downloadFiles = (e) => {
    e.preventDefault();
    this.setState({
      download_file_loading: true
    })
    if (this.state.downloadAllfiles) {
      this.state.patient_docments.map((element, index) => {
        this.downloadDocument(element.src, index);
      });
    } else {
      this.downloadDocument(this.state.documentURL);
    }
  }

  downloadDocument = (file_url, index = '') => {
    let file_extention = file_url.split('.').pop();
    let file_name = "document_" + moment().format('D_MMM_YYYY') + index + "." + file_extention;
    Axios.post(GET_ARRAY_BUFFER_FORM_URL, { file_url }, { responseType: 'arraybuffer' }).then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      let file = document.createElement('a');
      file.href = url;
      file.download = file_name;
      file.click();
      this.setState({
        download_file_loading: false
      })
    });
  }

  render() {

    const { patient, visitDataLoaded, hpi, review_of_systems, chief_complaint, prescriptionDetailsData, patient_note_doctor, patient_docments, referralDetailsArr, ccEditText, internal_notes, internalNotesInfo } = this.state;

    return (
      <>
        {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

        <div className="content-wrapper">
          <div className="container-fluid ">
            <div className="row">
              {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?
                <SubHeader patient={patient} />
                : null}

              {visitDataLoaded && referralDetailsArr?.length > 0 || (
                patient_note_doctor?.length > 0
                || patient_docments?.length > 0
              ) ? (
                <div className="col-12 mb-3" id="patient_notes_docs">
                  <div className="card bg-grey rounded-0">
                    <div className="card-body">
                      <div className='row'>
                        {patient_note_doctor?.length > 0 || patient_docments?.length > 0 ?
                          <div className={(patient_note_doctor?.length > 0 || patient_docments?.length > 0) && referralDetailsArr?.length == 0 ? "col-12" : "col-6"}>
                            {patient_note_doctor.length > 0 ? (
                              <>
                                <h6 className="text-uppercase text-dark" id="notes_for_doctor_head">Notes For Doctor</h6>
                                <p className="text-dark" id="notes_for_doctor_note">{patient_note_doctor}</p>
                              </>) : null}
                            {
                              patient_docments?.length > 0 ? (
                                <>
                                  <h6 className="text-uppercase text-dark" id="docs_for_doctor_head">Documents uploaded by the patient</h6>
                                  <figure className="mb-0" id="docs_for_doctor_docs">
                                    {
                                      patient_docments?.map((file, index) => (
                                        <a href="#" className="show-rx-modal" key={index} onClick={e => this.handleModalPopup(e, 'viewDocumentModal', index, 'img')}>
                                          {/* <img className="mr-3" alt='Doc IImg' src={file.type === 'img' ? icJpg : icPdf} /> */}
                                          <i className={`mr-2 icon-${file.type === 'img' ? 'jpg' : 'pdf'}`}></i>
                                        </a>
                                      ))
                                    }
                                  </figure>
                                </>
                              ) : null
                            }
                          </div>
                          : null}
                        {referralDetailsArr?.length > 0 ?
                          <div className={patient_note_doctor?.length == 0 && patient_docments?.length == 0 ? "col-12" : "col-6"}>
                            <div className={patient_note_doctor?.length == 0 && patient_docments?.length == 0 ? "" : "float-right"}>
                              <h6 className="text-uppercase text-dark" id="notes_for_doctor_head">Referral <a onClick={this.handleReferralDetails} style={{ color: '#d24350', left: '1px', top: '-18px', fontSize: '0.875rem' }}> {referralDetailsArr?.length > 1 ? referralDetailsArr?.length : null}</a></h6>
                              <p className='mb-1'>Referral Date & Time : <span className="text-dark">{referralDetailsArr[0].referral_date_time}</span></p>
                              <p className='mb-1'>Referral By : <span className="text-dark">Dr {referralDetailsArr[0].referral_by}</span></p>
                              <p className='mb-1'>Referral Note : <span className="text-dark">{referralDetailsArr[0].referral_note}</span></p>
                            </div>
                          </div>
                          : null}
                      </div>
                    </div>
                  </div>
                </div>

              ) : null}

              <div className="col-12 emrfrm">
                <div className="float-left">
                  <h1 className="page-title mt-3 mb-0">Subjective</h1>
                </div>
                <div className="float-right" id="accordionPastVisit">
                  {this.state.totalVisit > 0 && this.state.allowEdit ?
                    <a className="btn btn-outline-primary text-uppercase mt-2 d-inline-flex align-items-center icon-hover-white" onClick={e => this.handleModalPopup(e, this.state.isPracticing ? 'copyPrescriptionModal' : 'isPracticingModal')}>
                      {/* <img height="20" src={copyImg} alt="Copy" />  */}
                      <i className="icon-copy"></i>
                      COPY FROM PREVIOUS PRESCRIPTION
                    </a>
                    : ""}
                </div>
                {/* <div className="float-right">
                                    <a className="btn btn-outline-primary text-uppercase mt-2 mr-3" id="subjective-main-mic" data-toggle="modal" href="#"><img height="20" src="/images/copy.png" alt="Speak" /> SPEAK</a>
                                </div> */}
              </div>

              <div className="col-12 mt-4 pr-3">
                {visitDataLoaded && patient !== null ? (
                  <form className="subjective-form"
                    onSubmit={this.handleSubjectiveFormSubmit}>
                    <div className="emrfrm">

                      {/* <MyHcSpeechRecognition /> */}

                      <div className={`form-group micwrap normal ${this.state.chiefComplaintSelectedList ? 'hasdata' : ''}`} style={{ borderTop: 'none' }}>
                        {this.state.allowEdit ? <Link
                          onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'drop_list' }) }}
                          className={(this.state.speech.identifier === 'drop_list' && this.state.speech.state) ? 'mic subjective-mic on mute submic-pos' : 'mic subjective-mic submic-pos'} ></Link> : null}
                        <label htmlFor="chief_complaint" style={{ color: '#d24350', left: '1px', top: '-18px', fontSize: '0.875rem' }}>Chief Complaints *</label>

                        {/* <Input autoFocus bordered={false} className="form-control subjective-field custom-cheif" addonAfter={<CloseCircleOutlined onClick={() => { this.setState({ccEditText: '', ccEditOriginalText: ''}) }} />} value={this.state.ccEditText} onChange={(e) => this.setState({ccEditText: e.target.value})} onPressEnter={(evt) => this.updateCcChipInfo(evt)} />
                          <p style={{fontSize: '11px'}}>Press Enter key to Save.</p> */}
                        {/* onOk={handleOk} onCancel={handleCancel} */}
                        <Modal width={600} title="Edit Chief Complaints" closable={false} footer={null} visible={(ccEditText != '') ? true : false}>
                          <div className="row">
                            <div className="col-12">
                              <textarea style={{ minHeight: "150px" }} onInput={(e) => this.autoHeight(e)} autoComplete="off" className="form-control" name="edit_cc" type="text" autoFocus value={this.state.ccEditText} onChange={(e) => this.setState({ ccEditText: e.target.value })} />
                            </div>
                            <div className="col-12 mt-4">
                              <button class="btn btn-primary px-4 pull-right" type="button" onClick={(evt) => this.updateCcChipInfo(evt)}>Update</button>
                              <button class="btn btn-outline-primary px-4 pull-right mr-2" type="button" onClick={(evt) => { this.setState({ ccEditText: '', ccEditOriginalText: '' }) }}>Cancel</button>
                            </div>
                          </div>
                        </Modal>

                        <Select showSearch
                          id="chief_complaint"
                          name="chief_complaint"
                          style={{ border: 'none' }}
                          className="form-control subjective-field custom-cheif break-word"
                          onChange={(val, props) => this.selectChiefComplaint(val, props)}
                          mode="tags"
                          tagRender={(evt) => this.ccTags(evt)}
                          value={this.state.chiefComplaintSelectedList}
                          disabled={!this.state.allowEdit}
                          // allowClear
                          ref={this.chiefComplaintInput}
                        >
                          {this.optionChiefComplaintLoad()}
                        </Select>
                      </div>


                      {this.state.subjective_fields !== null ? (
                        Object.values(this.state.subjective_fields).map((sf, idx) => (
                          <div className="form-group micwrap normal" key={idx}>
                            {sf.input_name !== "chief_complaint" ? (

                              (typeof sf.withLabel != 'undefined') ?

                                <>
                                  <div className='row mt-5'>
                                    <label htmlFor={sf.input_name} className="control-label text-dark text-bold pl-2">{sf.display_text} {sf.required && <span className="text-danger">*</span>}</label>
                                  </div>
                                  <div className='row my-1'>
                                    <div className='col-auto d-flex align-items-center'>
                                      <span className='text-danger'><Moment date={new Date()} format="D MMM, YYYY" /></span>
                                    </div>
                                    <div className='col'>
                                      <div className="form-group micwrap normal mb-0">

                                        {this.state.allowEdit ? <Link
                                          onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: sf.input_name }) }}
                                          className={(this.state.speech.identifier === sf.input_name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}

                                        <div className='d-flex'></div>

                                        <textarea placeholder={(sf.placeholder != 'undefined') ? sf.placeholder : ""} className={(sf.placeholder != 'undefined') ? "form-control subjective-field show-placeholder" : "form-control subjective-field"} id={sf.input_name} name={sf.input_name} disabled={!this.state.allowEdit}
                                          value={this.state[sf.input_name]} onChange={this.handleOnChange} onFocus={this.handleOnFocus} rows="1" onInput={(e) => this.autoHeight(e)}></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                :
                                <>
                                  {this.state.allowEdit ? <Link
                                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: sf.input_name }) }}
                                    className={(this.state.speech.identifier === sf.input_name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}

                                  <label htmlFor={sf.input_name} className="control-label">{sf.display_text} {sf.required && <span className="text-danger">*</span>}</label>

                                  <textarea placeholder={(sf.placeholder != 'undefined') ? sf.placeholder : ""} className={(sf.placeholder != 'undefined') ? "form-control subjective-field show-placeholder" : "form-control subjective-field"} id={sf.input_name} name={sf.input_name} disabled={!this.state.allowEdit}
                                    value={this.state[sf.input_name]} onChange={this.handleOnChange} onFocus={this.handleOnFocus} rows="1" onInput={(e) => this.autoHeight(e)}></textarea>
                                </>
                            ) : null}

                          </div>
                        ))
                      ) : null}

                      <div className="previous-internal-notes">
                        {internalNotesInfo.map((element, index) => <div key={index} className="text-dark mb-2">
                          <span className="font-weight-light text-muted mr-2">
                            <Moment date={element.created_at} format="D MMM, YYYY" />
                          </span>
                          {element?.description}
                        </div>)}
                      </div>


                      {/* <div className="form-group micwrap normal">
                                                {this.state.allowEdit ? <Link
                                                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'chief-complaints' }) }}
                                                    className={(this.state.speech.identifier === 'chief-complaints' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                                                <label htmlFor="chief-complaints" className="control-label">Chief Complaints <span className="text-danger">*</span></label>
                                                <textarea className="form-control subjective-field" id="chief-complaints" name="chief_complaint" disabled={!this.state.allowEdit}
                                                    value={chief_complaint} onChange={this.handleOnChange} onFocus={this.handleOnFocus} rows="1" onInput={(e) => this.autoHeight(e)}></textarea>
                                            </div>

                                            <div className="form-group micwrap normal">
                                                {this.state.allowEdit ? <Link
                                                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'hpi' }) }}
                                                    className={(this.state.speech.identifier === 'hpi' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                                                <label htmlFor="hpi" className="control-label">HPI <span className="text-danger">*</span></label>
                                                <textarea className="form-control subjective-field" id="hpi" name="hpi" value={hpi} disabled={!this.state.allowEdit}
                                                    onChange={this.handleOnChange} onFocus={this.handleOnFocus} rows="1" onInput={(e) => this.autoHeight(e)}></textarea>
                                            </div>

                                            <div className="form-group micwrap normal">
                                                {this.state.allowEdit ? <Link
                                                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'review-of-systems' }) }}
                                                    className={(this.state.speech.identifier === 'review-of-systems' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                                                <label htmlFor="review-of-systems" className="control-label">On Examination</label>
                                                <textarea className="form-control subjective-field" id="review-of-systems" name="review_of_systems" disabled={!this.state.allowEdit}
                                                    value={review_of_systems} onChange={this.handleOnChange} onFocus={this.handleOnFocus} rows="1" onInput={(e) => this.autoHeight(e)}></textarea>
                                            </div> */}

                      <div className="mt-5">&nbsp;</div>
                    </div>
                    <div className="fixed-cta">
                      <button
                        disabled={this.state.confirmBtnStatus}
                        id="subjective_confirm"
                        className="btn btn-primary px-4"
                        type="submit">
                        CONFIRM
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="w-100 align-items-center justify-content-center mt-4 d-flex">
                    {LOADER_RED}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal
          title={this.state.prescriptionTitle}
          visible={this.state.copyPrescriptionModal}
          onCancel={(e) => this.handleModalPopup(e, 'copyPrescriptionModal')}
          footer={false}
          style={{ top: '10vh' }}
          width="940px"
          className="emrfrm"
          wrapClassName={this.state.showPrescriptionDetails ? "" : "prescriptionHeight"}
        >
          {this.state.showPrescriptionDetails ?
            <div className="row">
              <div className="col-12 table-responsive">
                <table className="table table-default">
                  <thead>
                    <tr>
                      <th style={{ width: "45px" }}>&nbsp;</th>
                      <th>Date</th>
                      <th>Doctor Name</th>
                      <th>Diagnosis</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      typeof this.state.prescriptionData !== 'undefined' ?
                        this.state.prescriptionData.map((Obj, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="custom-control custom-checkbox">
                                  <input type="radio" className="custom-control-input" id={"visit_" + index} name="select_prescription"
                                    onChange={(e) => this.handleOnChangeprescription(e, Obj.reference_id)} checked={this.state.selectPrescription === Obj.reference_id} />
                                  <label className="custom-control-label" htmlFor={"visit_" + index}>&nbsp;</label>
                                </div>
                              </td>
                              <td>{moment(Obj.createdAt).format('D MMM, YYYY')}</td>
                              <td>{Obj.doctorName}</td>
                              <td>{Obj.diagnosis.map((diagnos, i) =>
                                <div className="row" key={i}>
                                  <div className="col-10">{(DIAGNOSIS_DO_NOT_PRINT.includes(diagnos?.diagnosis_id) == true) && diagnos.diagnosis_notes ? diagnos.diagnosis_notes : diagnos.description}</div>
                                  <div className="col-2">{diagnos.icd_code}</div>
                                </div>
                              )}
                              </td>
                              <td><a href="#" onClick={(e) => this.prescriptionViewDetails(e, Obj.doctorName, Obj.createdAt, Obj.reference_id)}><i className="icon_view"></i></a></td>
                            </tr>

                          )
                        }) : <tr><td colSpan={6}>No previous prescription</td></tr>}

                  </tbody>
                </table>
              </div>
              <div className="col-12 text-center mt-4">
                <button type="button" className="btn btn-primary text-uppercase confirm-copy" disabled={this.state.isConfirm === false ? "true" : ""} onClick={this.handleConfirmPrescription}>CONFIRM</button>
              </div>
            </div>
            :
            this.state.prescriptionDataLoading ?
              <div className="row"><div className="col-12 text-center">{LOADER_RED}</div></div>
              :
              <div className="row">
                {typeof prescriptionDetailsData.subjectives !== 'undefined' && prescriptionDetailsData.subjectives != null && Object.keys(prescriptionDetailsData).length > 0 ?
                  <div className="col-12">
                    <h2 className="page-title">Subjective</h2>
                    <div className="row">
                      <div className="col-12">
                        {Object.values(prescriptionDetailsData.subjectives).map((subjectivOb, index) =>
                          subjectivOb.description !== '' ?
                            <div className="form-group normal" key={index}>
                              <label for="Chief Complaints" className="control-label mb-0">{subjectivOb?.subjective_title}<span className="text-danger">{prescriptionDetailsData?.constants?.subjectives?.[index]?.required ? '*' : ''}</span></label>
                              <div>{subjectivOb.description}</div>
                            </div>
                            : null
                        )}
                      </div>
                    </div>
                  </div>
                  : ''}

                {typeof prescriptionDetailsData.assessments !== 'undefined' && prescriptionDetailsData.assessments !== null ?
                  <div className="col-12">
                    <div className="row mt-2">
                      <h2 className="page-title ml-2">Assessment</h2>
                      <div className="col-12">
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <td>DIAGNOSIS</td>
                                <td>ICD 10</td>
                                <td>STAGE</td>
                                <td>TYPE</td>
                                <td>STATUS</td>
                                <td>NOTES</td>
                                <td>IMAGE REFERENCE</td>
                              </tr>
                            </thead>
                            <tbody>
                              {prescriptionDetailsData.assessments.map((assessmentsObj, index) => {
                                return (<tr key={index}>
                                  <td>
                                    {(DIAGNOSIS_DO_NOT_PRINT.includes(assessmentsObj?.diagnosisInfo?.diagnosis_id) == true || !assessmentsObj?.diagnosisInfo?.icd_code || assessmentsObj?.diagnosisInfo?.icd_code == "" || assessmentsObj?.diagnosisInfo?.icd_code == null) && assessmentsObj.diagnosisNotes ? assessmentsObj.diagnosisNotes : assessmentsObj?.diagnosisInfo?.diagnose_description}
                                  </td>
                                  <td><span className="text-primary">{assessmentsObj?.diagnosisInfo?.icd_code}</span></td>
                                  <td>
                                    {(assessmentsObj.stage !== 'undefined' && assessmentsObj.stage !== null) ?
                                      prescriptionDetailsData.constants.assessmentStage[assessmentsObj.stage]
                                      : ''
                                    }
                                  </td>
                                  <td>{(assessmentsObj.type !== 'undefined' && assessmentsObj.type !== null) ?
                                    prescriptionDetailsData.constants.assessmentType[assessmentsObj.type]
                                    : ''
                                  }
                                  </td>
                                  <td className="ant_switch_button_sm">
                                    <BootstrapSwitchButton
                                      width={90} height={24}
                                      size="xs"
                                      checked={!assessmentsObj.status}
                                      onlabel='Active'
                                      offlabel='Cured'
                                      onstyle="switch" offstyle="success"
                                      disabled
                                    // onChange={() => { this.setState({ isUserAdmin: checked }) }}
                                    />
                                  </td>
                                  <td>
                                    {(DIAGNOSIS_DO_NOT_PRINT.includes(assessmentsObj?.diagnosisInfo?.diagnosis_id) == true || !assessmentsObj?.diagnosisInfo?.icd_code || assessmentsObj?.diagnosisInfo?.icd_code == "" || assessmentsObj?.diagnosisInfo?.icd_code == null) && assessmentsObj.diagnosisNotes ? '-' : (assessmentsObj.diagnosisNotes ? assessmentsObj.diagnosisNotes : '-')}
                                  </td>
                                  <td className="image-reference-column">
                                    {(assessmentsObj.VisitAssessmentImage.length > 0 && assessmentsObj.VisitAssessmentImage[0].imagePath !== null) ?
                                      <img src={assessmentsObj.VisitAssessmentImage[0].imagePath} className="img-fluid" alt='' />
                                      : ''}                    </td>
                                </tr>)
                              }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  : ''}

                {(typeof prescriptionDetailsData.medicines !== 'undefined' && prescriptionDetailsData.medicines !== null) ||
                  (typeof prescriptionDetailsData.tests !== 'undefined' && prescriptionDetailsData.tests.length > 0) ?
                  <div className="col-12 mt-3">
                    <h2 className="page-title">Plan</h2>


                    <div className="row">
                      {typeof prescriptionDetailsData.medicines !== 'undefined' && prescriptionDetailsData.medicines !== null ?
                        <>
                          <div className="col-12 page-title">Medicines</div>
                          <div className="col-12">
                            <MedicineTable drugsData={prescriptionDetailsData.medicines} viewType={'copy-from-prescription'}
                              showAction={false} showSelectCheck={false} constants={prescriptionDetailsData.constants} />
                            {/* <div className="table-responsive mt-0">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>MEDICINE NAME</th>
                                                                        <th>DOSAGE</th>
                                                                        <th>FREQUENCY</th>
                                                                        <th>SCHEDULE</th>
                                                                        <th>ROUTE</th>
                                                                        <th>INSTRUCTIONS</th>
                                                                        <th width="115px">NO. OF DAYS</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.MedcineListLoad()}
                                                                </tbody>
                                                            </table>
                                                        </div> */}
                          </div>
                        </>
                        : ''}

                      {typeof prescriptionDetailsData.tests !== 'undefined' && prescriptionDetailsData.tests.length > 0 ?
                        <>
                          <div className="col-12 page-title  mt-2">Tests</div>
                          <div className="col-12">
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <td>Test Name</td>
                                    <td>Comments</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {prescriptionDetailsData.tests.map((testsObj, index) =>
                                    <tr key={index}>
                                      <td>{testsObj.test_name}</td>
                                      <td>{testsObj.instructions}</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                        : ''}

                    </div>
                  </div>
                  : ''}

                {typeof prescriptionDetailsData.instructions !== 'undefined' && prescriptionDetailsData.instructions !== null ?
                  <div className="col-12 mt-3">
                    <h1 className="page-title">Instructions</h1>
                    <ul className="custom-list list-success">
                      {prescriptionDetailsData.instructions.map((instructionObj, index) =>
                        <li key={index}>{instructionObj.instruction}</li>
                      )}
                    </ul>
                  </div>
                  : ''}

                {typeof prescriptionDetailsData.supportingMaterials !== 'undefined' && prescriptionDetailsData.supportingMaterials !== null && prescriptionDetailsData.supportingMaterials.length > 0 ?
                  <div className="col-12 mt-3">
                    <h1 className="page-title">Supporting Materials</h1>
                    <ul className="custom-list list-success">
                      {prescriptionDetailsData.supportingMaterials.map((Obj, index) =>
                        <li key={index} style={{ cursor: 'default' }} >
                          <a style={{ cursor: 'default'}}>{Obj.file_name}</a>
                        </li>
                      )}
                    </ul>
                  </div>
                  : ''}
              </div>

          }
        </Modal>

        <Modal
          title={false}
          visible={this.state.confirmPrescriptionModal}
          onCancel={(e) => this.handleModalPopup(e, 'confirmPrescriptionModal')}
          footer={false}
          closable={false}
          width="400px"
        >
          <div className="row">
            <div className="col-12 text-center">
              Please ensure all information on this page is true to the patient's conditions as on today.
            </div>
            <div className="col-12 text-center mt-3">
              <button type="button" className="btn btn-outline-secondary px-4 small  mr-2" onClick={(e) => this.handleCancelProceed(e)}>CANCEL</button>
              <button type="submit" className="btn btn-primary px-4 small" onClick={(e) => this.handleProceedPrescription(e, '')}>PROCEED</button>
            </div>
          </div>
        </Modal>

        {/** Document Viewer */}
        <Modal
          title={patient_docments?.length === 1 ? "Document" : "Documents"}
          visible={this.state.viewDocumentModal}
          footer={false}
          width={850}
          onCancel={e => this.handleModalPopup(e, 'viewDocumentModal')}
          style={{ top: 20 }}
        >
          <div className='row mb-3'>
            <div className='col-12 text-right'>
              <div className="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" className="custom-control-input" id="select_all" onClick={(e) => this.selectAllFiles(e)} name="select_all"
                />
                <label className="custom-control-label" htmlFor="select_all">Select All</label>
              </div>
              <button href={this.state.documentURL} title={this.state.documentURL} disabled={this.state.download_file_loading ? true : false} className={this.state.download_file_loading ? "btn btn-primary px-2" : "btn btn-outline-primary px-2"} onClick={(e) => this.downloadFiles(e)} download>DOWNLOAD {this.state.download_file_loading ? LOADER : null}</button>
            </div>
          </div>
          <div className="pdf_slider">
            {
              patient_docments?.length > 0 ? (
                <Carousel arrows dots={false} ref={this.carouselRef} beforeChange={this.beforeChange} >
                  {
                    patient_docments?.map((file, index) => (
                      file.type === 'img' ?
                        <figure key={index}><Image className="mr-3" src={file.src} alt='uploaded Doc' /></figure>
                        :
                        <iframe key={index} className="mr-3" src={file.src + "#toolbar=0&scrollbar=0&pagemode=none"} ></iframe>

                    ))
                  }
                </Carousel>
              ) : null
            }
          </div>
        </Modal>

        {/** || isPracticing Modal */}
        <Modal
          title={false}
          closable={false}
          visible={this.state.isPracticingModal}
          footer={false}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              <h6>Do you want to Start Practice?</h6>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <div className="btn btn-outline-secondary px-5" onClick={e => this.handleModalPopup(e, 'isPracticingModal')}>No</div>
            <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
          </div>
        </Modal>

        <Modal
          title={false}
          closable={true}
          visible={this.state.isReferralModal}
          footer={false}
          onCancel={e => this.handleModalPopup(e, 'isReferralModal')}
        >
          <div className="row">
            <div className="col-12 mb-3 text-left"><h6>REFERRAL DETAILS</h6></div>
            {referralDetailsArr?.map((element, index) => (
              (index != 0) ?
                <div className="col-12 mb-3" id="patient_notes_docs">
                  <div className="card bg-grey rounded-0">
                    <div className="card-body">
                      <div className='row'>
                        <div className="col-12">
                          <div className="">
                            <h6 className="text-uppercase text-dark" id="notes_for_doctor_head">Referral {index + 1}</h6>
                            <p className='mb-1'>Referral Date & Time : <span className="text-dark">{element.referral_date_time}</span></p>
                            <p className='mb-1'>Referral By : <span className="text-dark">Dr {element.referral_by}</span></p>
                            <p className='mb-1'>Referral Note : <span className="text-dark">{element.referral_note}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : null
            ))}
            <div className="col-12 text-center mt-3">
              <button type="button" className="btn btn-outline-secondary px-4 small  mr-2" onClick={e => this.handleModalPopup(e, 'isReferralModal')}>CANCEL</button>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

export default SubjectiveComponent;
