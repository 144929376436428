import React, { Component, Fragment } from 'react';
import {
  LAB_TEST_SEARCH, APP_ENV, DRUGS_CONSTANT, LAB_TEST_FAVOURITE, LAB_TEST_CONSTANTS, REFERENCE_TYPE, DELETE_TEST,
  PLAN_TEST_ADVICED_STORE, PLAN_TEST_ADVICED_LIST, LOADER_BLACK, HANDLETEXTAREAAUTOHEIGHT, USERTYPES
} from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import 'antd/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { jQueryLabels, AntSelectLabels, ClearAntList } from '../../utils/jQueryLabels';
import { Form, Modal, Input, Button, Select, AutoComplete } from 'antd';
const { Option } = Select;
class Templatetests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test_id: '',
      selectTestDrugValue: '',
      urgency: '',
      instructions: '',
      $isTestFavCheckedVal: 0,
      test_list: [],
      DRUGSConstants: [],
      TestConstants: [],
      AddisDisabled1: true,
      TestAddListed: [],
      deleteTestId: '',
      isAlertTestMVisible: false,
      test_name: '',
      $Test_priority_list: '',
      TestAddTable: false,
      view_id: '',
      requisition_comments: '',
      isrequistion: false,
      PropFlag: 1,
      isPlanAdviced: props.isPlanAdviced,
      patient: props.isPlanAdviced ? props.patient : null,
      testAdvConstants: null,
      testAdvDetails: null,
      testAdvDuplicateOrderTests: null,
      testDuplicateModal: false,
      selectedTest: [],
      retestApproved: false,
      isTableExternal: props.isTableExternal,
      resetForm: props.resetForm,
      formIsFetching: false
    }
    this.test_name = React.createRef()
    this.flag = 1
  }
  showAlertTestModal = () => { this.setState({ isAlertTestMVisible: true }) };
  handleAlertTestOk = () => { this.setState({ isAlertTestMVisible: false }); this.TestOndelete(this.state.deleteTestId) };
  handleAlertTestCancel = () => { this.setState({ isAlertTestMVisible: false }) };
  TestOndelete = (val) => {

    const { isPlanAdviced } = this.state;

    if (isPlanAdviced) {

      axios.get(DELETE_TEST({ id: val }))
        .then(success => {
          this.getPlanTestAdcicedList();
        })
        .catch(err => console.log(err))

    } else {

      if (this.state.TestAddListed.length === 1) {
        this.setState({ TestAddListed: [], TestAddTable: false }, () => {
          this.props.tests_data(
            this.state.TestAddListed
          )
        })
      }
      else if (this.state.TestAddListed.length > 1) {

        let array = this.state.TestAddListed;
        array.splice(val, 1);
        this.setState({ TestAddListed: array }, () => {
          this.props.tests_data(
            this.state.TestAddListed
          )
        })
      }
    }
  }
  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    jQueryLabels();
    this.TemplateDrugConstant();
    this.onDrugTestssearch('');
    if (this.state.isPlanAdviced)
      this.getPlanTestAdcicedList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.TestAddListed !== this.state.TestAddListed) {
      if (!this.state.isPlanAdviced) {
        this.props.tests_data(
          this.state.TestAddListed
        )
      }
    }
    if (prevProps.resetForm !== this.state.resetForm) {
      if (this.state.resetForm) {
        this.handleFormReset();
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.TestAddListed !== undefined && prevState.TestAddListed.length === 0 && prevState.PropFlag) {
      if (nextProps.Input_data !== prevState.Input_data && nextProps.Input_data !== undefined && !nextProps.idisNaN) {
        return { TestAddListed: nextProps.Input_data, TestAddTable: true, PropFlag: 0 };
      }
    }
    if (nextProps.resetForm !== prevState.resetForm) {
      return { resetForm: nextProps.resetForm }
    }
    return null;
  }

  handleFormReset = _ => {
    this.setState({
      resetForm: false
    }, () => this.Formclear())
  }

  getPlanTestAdcicedList = async _ => {
    const { patient_id, qms_token_id } = this.state.patient;

    const planTestAdvPromise = await axios.get(PLAN_TEST_ADVICED_LIST({ reference_type: REFERENCE_TYPE, reference_id: qms_token_id, patient_id }))
    if (planTestAdvPromise.data.status === 'success') {
      const { constants, details, duplicateOrderTests } = planTestAdvPromise.data.data;
      this.setState({
        testAdvConstants: constants,
        testAdvDetails: details,
        testAdvDuplicateOrderTests: duplicateOrderTests,
        TestAddListed: details,
        TestAddTable: true
      })
      // this.setState({
      //     TestAddListed: details,
      //     TestAddTable: true
      // })

    }
  }

  Instructionchange = (e) => {
    this.setState({ instructions: e.target.value })
  }
  TestFavorite = (id, Lab, urgency, instructions) => {
    this.setState({
      $isTestFavCheckedVal: this.state.$isTestFavCheckedVal ? 0 : 1
    })

    const elem = document.querySelector('#test-name').parentElement?.nextSibling?.querySelector('.desc');
    let searchKeys = elem !== undefined ? elem.innerText : document.querySelector('#test-name').value;
    searchKeys = searchKeys.replace(/[^\w\s]/gi, ' ');

    // const searchVal = $('#test-name').val();
    // this.state.$isTestFavCheckedVal ? this.setState({ $isTestFavCheckedVal: 0 }) : this.setState({ $isTestFavCheckedVal: 1 })
    axios.post(LAB_TEST_FAVOURITE, qs.stringify({
      id: id,
      hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null,
      type: 'Service',
      urgency,
      instructions,
    })).then(success => {
      if (success.data.status) {
        this.setState({
          $isTestFavCheckedVal: this.state.$isTestFavCheckedVal
        });
        // this.onDrugTestssearchFav();
        // let star = document.querySelector('.ant-select-selection-item .icon_unique');
        // star.classList.contains('favourite') ? star.classList.remove('favourite') : star.classList.add('favourite');
        this.onDrugTestssearch(searchKeys);
      }
    }).catch(err => {
      this.setState({
        $isTestFavCheckedVal: this.state.$isTestFavCheckedVal ? 0 : 1
      })
      // this.state.$isTestFavCheckedVal ? this.setState({ $isTestFavCheckedVal: 0 }) : this.setState({ $isTestFavCheckedVal: 1 })
    })
  }
  TemplateDrugConstant = (search) => {
    axios.get(DRUGS_CONSTANT).then(success => {
      this.setState({ DRUGSConstants: success.data.data });
    }).catch(err => console.log(err));
    axios.get(LAB_TEST_CONSTANTS).then(success => {
      this.setState({ TestConstants: success.data.data });
    }).catch(err => console.log(err))
  }
  optionTestLoad() {
    if (this.state.test_list !== undefined && this.state.test_list.length > 0) {
      return this.state.test_list.map((obj, i) => {
        const isRequisition = !(obj.isRequistion === null || obj.isRequistion === false || obj.isRequistion === undefined || obj.isRequisition === 'N');
        return (<Option key={i} testid={obj.testId} value={obj.id}
          isrequistion={isRequisition}
          type={obj.type} fav={obj.fav} title={obj.name}>
          <span className="desc">
            {obj.name}
          </span>
          <span className="float-right text-primary">
            <i className={obj.fav ? 'icon_unique icon_star favourite mr-2' : 'icon_unique icon_star mr-2'}></i>
          </span>
        </Option>
        )
      })
    } else return null;
  }
  optionReqViewLoad() {
    if (!this.state.TestConstants || this.state.TestConstants.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.TestConstants.map((obj, i) => {
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
      )
    })
  }
  optionPriorityLoad() {
    if (!this.state.DRUGSConstants.drugUrgency || this.state.DRUGSConstants.drugUrgency.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.drugUrgency.map((obj, i) => {
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
      )
    })
  }
  onselectPriorityChange = (val, props) => {
    this.setState({ urgency: val, $Test_priority_list: props.title })
  }
  onselectReqViewChange = (val, props) => {
    this.setState({ view_id: val })
  }
  onreqcommentschange = (e) => {
    this.setState({ requisition_comments: e.target.value })
  }
  onselectTestChange = (val, props) => {

    this.setState({
      selectTestDrugValue: val,
      test_name: props.title,
      test_id: props.testid,
      type: props.type,
      AddisDisabled1: false,
      urgency: 3,
      $Test_priority_list: "Routine",
      instructions: '',
      $isTestFavCheckedVal: props.fav,
      isrequistion: props.isrequistion
    })

    let { testAdvDuplicateOrderTests, selectedTest } = this.state;
    if (testAdvDuplicateOrderTests !== null && testAdvDuplicateOrderTests.length > 0) {
      selectedTest = testAdvDuplicateOrderTests.filter(tad => tad.test_id == props.testid);
      selectedTest = selectedTest[selectedTest.length - 1]
      if (selectedTest !== undefined) {
        selectedTest['title'] = props.title;
        this.setState({
          selectedTest
        }, () => {
          this.handleModalPopup(null, 'testDuplicateModal');
        })
      }
    }

    AntSelectLabels();
    HANDLETEXTAREAAUTOHEIGHT('instruction_autoheight');
  }

  onDrugTestssearch = (search) => {
    // if (search !== '') {

    this.setState({
      formIsFetching: true
    })
    let params = {search: search};
    params.active = true;
    params.service_name = search;
    params.facility_id = LS_SERVICE.get('FACILITY_ID');
    params.filter_service_type = 'I,P,O';
    params.doctor_id = LS_SERVICE.get('staff_id');
    if(LS_SERVICE.get("user_type") == USERTYPES.admin){
        params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null
    } else {
        params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
    }
    if(APP_ENV == 'mhea'){
      params.active = true;
      params.service_name = search;
      params.facility_id = LS_SERVICE.get('FACILITY_ID');
      params.filter_service_type = 'I,P,O';
      params.doctor_id = LS_SERVICE.get('staff_id')
    }
    axios.post(LAB_TEST_SEARCH, qs.stringify(params)).then(success => {
      var testList = success.data.test_list;
      var finaltestlist = []
      for (var index = 0; index < testList.length; index++) {
        if(testList[index].patientClassOp == true){
          finaltestlist.push(testList[index])
        } 
      }
      this.setState({
        test_list: finaltestlist,
        formIsFetching: false
      });
    }).catch(err => console.log(err))
    // } else {
    //     ClearAntList();
    //     this.setState({ test_list: [] });
    // }
  }
  Formclear = () => {
    this.setState({
      selectTestDrugValue: '',
      urgency: '',
      instructions: '',
      AddisDisabled1: true,
      reqisDisabled: true,
      $isTestFavCheckedVal: 0,
      test_list: [],
      requisition_comments: '',
      view_id: ''
    }, () => {
      this.onDrugTestssearch('')
      this.setState({
        isrequistion: false
      })
    })
  }
  addTestlist = (val) => {
    const { isPlanAdviced, isTableExternal } = this.state;
    let obj = {
      view_id: this.state.view_id,
      requisition_comments: this.state.requisition_comments,
      type: this.state.type,
      test_id: this.state.test_id,
      urgency: this.state.urgency || 49,
      test_name: this.state.test_name,
      $Test_priority_list: this.state.$Test_priority_list,
      instructions: this.state.instructions,
    }

    if (isPlanAdviced) {
      const { patient } = this.state;
      const { qms_token_id, patient_id } = patient;

      const PARAMS = {
        tests_data: [obj],
        reference_type: REFERENCE_TYPE,
        reference_id: qms_token_id,
        patient_id
      }

      axios.post(PLAN_TEST_ADVICED_STORE, qs.stringify(PARAMS))
        .then(success => {
          if (success.data.status === "success") {
            const data = success.data.details[0]
            obj.id = data.id;

            this.setState({
              TestAddListed: [...this.state.TestAddListed, obj],
              TestAddTable: true
            }, () => {
              this.Formclear();
            })
          }
        })
        .catch(error => console.log(error.response))
    } else if (isTableExternal) {
      this.props.tests_data(
        obj
      )
    } else {
      let findex = this.state.TestAddListed.findIndex(x => x.test_name === this.state.test_name);
      if (findex === -1) {
        this.setState({ TestAddListed: [...this.state.TestAddListed, obj], TestAddTable: true }, () => {
          this.props.tests_data(
            this.state.TestAddListed
          )
        })
      }
      else {
        let arr = this.state.TestAddListed;
        arr.splice(findex, 1, obj);
        this.setState({ TestAddListed: arr, TestAddTable: true })
      }
      this.Formclear();
    }
  }
  MedcineListLoad = () => {
    if ((!this.state.TestAddListed || !this.state.DRUGSConstants) || (this.state.DRUGSConstants.length === 0 || this.state.TestAddListed.length === 0)) {
      return (null)
    }
    return this.state.TestAddListed.map((data, i) => {
      return (
        <tr key={i}><td>{data.test_name}</td>
          <td className="">{this.state.DRUGSConstants.drugUrgency.map((data1, i) => data1.id === data.urgency ? data1.description : null)}</td>
          <td>{data.instructions !== '' ? data.instructions : '-'}</td>
          <td>
            {this.state.isPlanAdviced ? (
              <Button className='nostyle-link delete-assessment p-0' onClick={() => { this.setState({ deleteTestId: data.id }); this.showAlertTestModal() }}>
                <i className='icon_delete'></i>
              </Button>
            ) : (
              <Button className='nostyle-link delete-assessment p-0' onClick={() => { this.setState({ deleteTestId: i }); this.showAlertTestModal() }}>
                <i className='icon_delete'></i>
              </Button>
            )}
          </td>
        </tr>)
    });
  }

  handleModalPopup = (e, popup) => {
    if (e !== null)
      e.preventDefault();

    this.setState({
      [popup]: !this.state[popup]
    }, () => {
      const { testDuplicateModal, retestApproved } = this.state;
      if (!testDuplicateModal && !retestApproved) {
        this.Formclear();
      }
    })
  }

  handleReTestApproval = e => {
    this.setState({
      retestApproved: true,
    }, () => { this.handleModalPopup(null, 'testDuplicateModal'); this.addTestlist(); })
  }

  render() {
    return (
      <Fragment>
        <div className="col-12">
          <div className="emrfrm">
            <div className="row">
              <h2 className="col-12 text-heading">Tests</h2>
              <div className="col-lg-12">
                <div className="row mt-2">
                  <div className="col-lg-6 medicine_star">
                    <div className="form-group">
                      <Select
                        id="test-name"
                        name="test_id"
                        style={{ width: 100 + '%' }}
                        showSearch
                        autoFocus
                        notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
                        loading={this.state.formIsFetching}
                        // placeholder="Test Name"
                        // optionFilterProp="children"
                        onSearch={this.onDrugTestssearch}
                        className="select-drugs"
                        onChange={this.onselectTestChange}
                        value={this.state.selectTestDrugValue}
                        filterOption={false}
                      // filterOption={(input, option) => {
                      //     return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                      // }}
                      >
                        {this.optionTestLoad()}
                      </Select>
                      <label htmlFor="test-name">Test Name<span className="text-danger">*</span></label>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <Select showSearch id="urgency" name="urgency" style={{ width: 100 + '%' }}
                        placeholder="Priority"
                        onChange={this.onselectPriorityChange}
                        className="drug-input Priority"
                        value={this.state.urgency}
                      >
                        {this.optionPriorityLoad()}
                      </Select>
                      <label htmlFor="urgency">Priority<span className="text-danger">*</span></label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <textarea name="instructions" data-name="instructions" onChange={this.Instructionchange} value={this.state.instructions}
                        className="form-control drug-input input-template mandatory-field instruction_autoheight" id="instructions" rows="1" placeholder="Test Instructions"></textarea>
                      <label htmlFor="instructions">Test Instructions</label>
                    </div>
                  </div>

                </div>
                <div className="row mt-2" id="requisition-form-container" style={{ display: this.state.isrequistion ? 'flex' : 'none' }}>
                  <div className="col-12 my-3">
                    <div className="card bg-grey">
                      <div className="card-body">
                        <h6>Requisition Form</h6>
                        <div className="row">
                          {/* <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <Select 
                                                                id="requisition_test_name" 
                                                                name="requisition_test_name" 
                                                                style={{ width: 100 + '%' }}
                                                                showSearch
                                                                placeholder="Test Name"
                                                                optionFilterProp="children"
                                                                onSearch={this.onDrugTestssearch}
                                                                className="select-drugs"
                                                                onChange={this.onselectTestChange}
                                                                value={this.state.selectTestDrugValue}
                                                                disabled={true}
                                                                filterOption={(input, option) => {
                                                                    return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                                }}
                                                            >
                                                                {this.optionTestLoad()}
                                                            </Select>
                                                            <label htmlFor="requisition_test_name">Test Name<span>*</span></label>
                                                        </div>
                                                    </div> */}
                          <div className="col-12 col-md-6">
                            <div className="form-group bg-white">
                              <Select
                                id="requisition_view_id"
                                name="view_id"
                                style={{ width: 100 + '%' }}
                                placeholder="View"
                                onChange={this.onselectReqViewChange}
                                className="drug-input"
                                value={this.state.view_id}
                              >
                                {this.optionReqViewLoad()}
                              </Select>
                              <label htmlFor="requisition_view_id">View</label>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group bg-white">
                              <input type="text" className="form-control test-input" onChange={this.onreqcommentschange} value={this.state.requisition_comments}
                                name="requisition_comments" id="requisition_comments" placeholder="Comments, if any" autoComplete="off" />
                              <label htmlFor="requisition_comments">Comments, if any</label>
                            </div>
                          </div>
                          {this.state.isPlanAdviced ? (
                            <>
                              <div className="col-md-4 mt-4 mt-md-0">
                                <h6 className="mb-3">Diabetic</h6>
                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                  <input type="radio" id="diabetic-yes" name="is_diabetic" className="custom-control-input" defaultValue={1} autoComplete="off" />
                                  <label className="custom-control-label" htmlFor="diabetic-yes">Yes</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                  <input type="radio" id="diabetic-no" name="is_diabetic" className="custom-control-input" defaultValue={0} defaultChecked autoComplete="off" />
                                  <label className="custom-control-label" htmlFor="diabetic-no">No</label>
                                </div>
                              </div>
                              <div className="col-md-4 mt-4 mt-md-0">
                                <h6 className="mb-3">On Metformin</h6>
                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                  <input type="radio" id="metformin-yes" name="is_metformin" className="custom-control-input" defaultValue={1} autoComplete="off" />
                                  <label className="custom-control-label" htmlFor="metformin-yes">Yes</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                  <input type="radio" id="metformin-no" name="is_metformin" className="custom-control-input" defaultValue={0} defaultChecked autoComplete="off" />
                                  <label className="custom-control-label" htmlFor="metformin-no">No</label>
                                </div>
                              </div>
                              <div className="col-md-4 mt-4 mt-md-0">
                                <h6 className="mb-3">Risk</h6>
                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                  <input type="radio" id="risk-yes" name="risk" className="custom-control-input" defaultValue={1} autoComplete="off" />
                                  <label className="custom-control-label" htmlFor="risk-yes">Yes</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                  <input type="radio" id="risk-no" name="risk" className="custom-control-input" defaultValue={0} defaultChecked autoComplete="off" />
                                  <label className="custom-control-label" htmlFor="risk-no">No</label>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 text-right my-3">
                <div className="ccustom-control custom-checkbox-favourite fav_drug custom-control-inline border-0 pl-0" id="favourite-test">
                  <input type="checkbox" className="custom-control-input" id="favourite" name="favourite" />
                  <label className={this.state.$isTestFavCheckedVal ? 'custom-control-label text-secondary text-underline cursor-pointer favorite label-star' : 'custom-control-label text-secondary text-underline cursor-pointer label-star'} htmlFor="favourite">
                    <Button style={{ width: 116 + 'px', textAlign: 'right' }} className="nostyle-link text-secondary text-underline p-0" onClick={() => this.TestFavorite(this.state.test_id, this.state.type, this.state.urgency, this.state.instructions)}>FAVOURITE</Button>
                  </label>
                </div>
                <div className="float-right">
                  <Button className="btn-outline-secondary px-4 small text-uppercase mr-3"
                    id="drug-clear" onClick={() => this.Formclear()}
                  >Clear</Button>
                  <Button
                    type="button"
                    className="btn btn-primary px-4 small text-uppercase mandatory-save"
                    id="drug-save"
                    disabled={this.state.AddisDisabled1}
                    onClick={() => this.addTestlist()}
                  >Add</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4" id="test-list-lab" style={{ display: this.state.TestAddTable && this.state.TestAddListed.length !== 0 ? 'block' : 'none' }}>
            <div className="table-responsive5">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th width={30 + '%'}>TEST NAME</th>
                    <th>PRIORITY</th>
                    <th>INSTRUCTIONS</th>
                    <th width={100}>ACTION</th>
                  </tr>
                </thead>
                <tbody>{this.MedcineListLoad()}</tbody>
              </table>
            </div>
          </div>
          <Modal id="alert_confirm_message_test"
            className="alert_confirm_message"
            title='Are you sure you want to delete test?'
            visible={this.state.isAlertTestMVisible}
            onOk={() => this.handleAlertTestOk()}
            bodyStyle={{ padding: 0 + 'px' }}
            onCancel={() => this.handleAlertTestCancel()}
            closable={false}
            footer={[
              <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={() => this.handleAlertTestCancel()}>
                CANCEL
                            </Button>,
              <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" onClick={() => this.handleAlertTestOk()}>
                DELETE
                            </Button>,
            ]}
          >
          </Modal>

          {/* || VITAL MODAL */}
          <Modal
            title=""
            visible={this.state.testDuplicateModal}
            onCancel={(e) => this.handleModalPopup(e, 'testDuplicateModal')}
            style={{ top: 40 }}
            closable={false}
            closeIcon={false}
            width={800}
            footer={[
              <div className="justify-content-center text-center">
                <div tabindex="20" className="btn btn-outline-secondary cursor-pointer"
                  onClick={e => this.handleModalPopup(e, 'testDuplicateModal')}>No</div>
                <button type="submit" className="btn btn-primary text-uppercase ml-2 mandatory-save"
                  onClick={this.handleReTestApproval}>Yes</button>
              </div>
            ]}
          >
            <div className="row">
              <div className="col-12 text-center" id="duplicate-test-text">
                This order for {this.state.selectedTest.title} has already been placed on{" "}
                {moment(this.state.selectedTest.createdAt).format('DD MMM, yyyy')}<br />Do you want to proceed?</div>

              {/*<hr />
                           
                             <div className="row mt-4 test-row" id="test_0">
                                <div className="col-4">
                                    <strong>Hemoglobin A1c Test (HbA1c)</strong>
                                </div>
                                <div className="col-8">
                                    <div className="table-responsive">
                                        <table className="w-100">
                                            <tbody>
                                                <tr><td width="8%">Ordering Physician</td><td width="35%">: <strong>Dr PROF. SATYENDRA NATH BURMAN(MEDI)</strong></td><td width="9%">Test Date</td><td width="10%">: <strong>13 Jan 2020</strong></td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="table-responsive mt-4">
                                        <table className="table table-bordered nob">
                                            <thead>
                                                <tr><td width="20%">Result</td><td width="40%">Reference Interval</td><td width="20%">Unit</td><td width="20%">Trend</td></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td><strong className="text-danger">8</strong></td><td><strong>6.3--8.2</strong></td><td>gm / dl</td><td className="testGraphWidget"><a href="#" data-toggle="modal" id="month_data" className="active"><img src="http://dev.woodlands.opdemr.myhealthcare.co/assets/images/icon-chart.png" alt="Chart" /></a></td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                         */}
            </div>


          </Modal>
        </div>
      </Fragment>
    );
  }
}

export default Templatetests;