import React, { Component, createRef } from 'react'
import Axios from 'axios';
import qs from 'qs';
import { Input, Modal, Select, Button, Radio, Form, Checkbox, DatePickerProps, DatePicker, Tooltip, AutoComplete, notification } from 'antd';
import $ from 'jquery';
import { Link } from "react-router-dom";
import AsideLeft from '../../components/aside/asideleft';
import MultiplePregnancies from "../../components/gynaecology/multiple-pregnancies";
import AncCardView from './anccardview';
import PrintPreviewAnc from './print-preview-anc';
import GPALE from "../../components/gynaecology/GPALE";
import ReactToPrint from "react-to-print";
import {
    PATIENT_DATA, MENSTRUAL_HISTORY_LIST, CONCEPTION_OPTIONS, PREGNANCY_OPTIONS, DELIVERY_OPTIONS, OUTCOME_OPTIONS,
    PLACE_OF_DELIVERY_OPTIONS, OBSTETRIC_HISTORY_LIST, GPALE_CURRENT_PREGNANCY_DATA, LIST_PHYSICAL_EXAMINATION,
    LIST_PHYSICAL_EXAMINATION_FETAL_PARAMS, TOGGLE_PRACTICE, RISK_FACTORS_LIST, OBJECTIVE_GETALL, DURATION_TYPE,
    INFORMED_BY, MEDICATION_ON, FETAL_MOVEMENT, PRESENTATION, RELATIONSHIP_TO_PP_BRIM, LAB_TEST_SEARCH, VaccinationList,
    SAVE_ANC_CARD, PREVIOUS_ANC_CARD_DETAILS, LOADER, HANDLEVALIDANTMESSAGESPATIENTAGE,
    UPDATE_CURRENT_PREGENENCY, OBSTETRIC_HISTORY_STORE,
    VaccinationItemList, VaccinationRecordList, PAEDRATICVACCINEID, ISALLOWEDITSOAP
} from "../../utils/constant";
import { lmpDataService, gpaleDataService, lactatingService, speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../../pages/speech-to-text/speech-recognition";
import LS_SERVICE from "../../utils/localStorage";
import moment from "moment";
import BasicComponent from './basic';
import { Container } from 'react-bootstrap';
import { ArgumentNullError } from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common/Error';
const { Option } = Select;

export default class AncCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            obs_history: [],
            all_get_data: {},
            inCImmunoprophylaxis_data: [],
            record: {},
            indication_csection: '',
            details: '',
            delivery_place: '',
            year_options: [],
            delivery_mode: '',
            pregnancy_year_date: '',
            delivery_outcome: '',
            poupulated_Field: false,
            abnormality: false,
            total_count_fetal: 0,
            select_Delivery_mode: '',
            select_Delivery_outcome: '',
            delivery_dateChange: '',
            Immunoprophylaxis_dates: [],
            duplicate_testdate: [],
            testselect_dates: [],
            test_result: [],
            radiology_test: [],
            radiology_testData: [],
            test_referenceRange: [],
            patientInfo: null,
            risk_factors: [],
            risk_factors_data: [],
            menstrualHistoryListStatus: false,
            menstrualHistoryList: [],
            conception_options: CONCEPTION_OPTIONS,
            pregnancy_options: PREGNANCY_OPTIONS,
            delivery_options: DELIVERY_OPTIONS,
            outcome_options: OUTCOME_OPTIONS,
            place_of_delivery_options: PLACE_OF_DELIVERY_OPTIONS,
            gpale_values: { gravidity: null, parity: null, abortion: null, live_birth: null, ectopic: null },
            phyExamListStatus: false,
            phyExamFPListStatus: false,
            previewListModal: false,
            multiplePregnanciesModal: false,
            testData: [],
            vaccineData: [],
            risk_factors_name: [],
            speech: {
                identifier: null,
                state: false
            },
            gpale_lactating: [],
            multiplePregnancies: [],
            delivery_datetime: '',
            spin_loading: false,
            prevAncCardData: {},
            preview_option: false,
            previewparams: [],
            prevIncompleteAnc: [],
            test_previous: [],
            test_populated: [],
            radio_test_previous: [],
            radio_test_populated: [],
            vaccine_previous: [],
            vaccine_populated: [],
            savetests: [],
            voiceresult: [],
            radio_previous_test_length: '',
            immuno_remarks: '',
            test_reference: '',
            test_date: '',
            test_result: '',
            previous_reference_id: 0,
            gender_options: [
                { val: '1', label: 'Male' },
                { val: '2', label: 'Female' },
                { val: '3', label: 'Transgender' }
            ],
            lab_tests_impressions: {},
            lab_tests_impressions_date: {},
            lab_tests_impressions_refrence: {},
            vaccine_impressions: {},
            lab_radio_tests_impressions: {},
            lab_radio_tests_impressions_date: {},
            lab_radio_tests_impressions_refrence: {}
        }

        this.createRef = createRef();
        this.ancFormRef = React.createRef();
    }

    static getDerivedStateFromProps(props) {
        const { match: { params } } = props;

        return {
            patient: {
                patient_id: params.patient_id,
                reference_id: params.reference_id,
                reference_type: params.reference_type,
                doctor_id: (LS_SERVICE.get('user_type') === 2) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : LS_SERVICE.get('staff_id')
            }
        };
    }

    componentDidMount = async () => {
        let cyear = new Date().getFullYear();
        let syear = cyear - 60;
        let year_options = [];

        for (let i = cyear; i >= syear; i--) {
            year_options.push(i);
        }
        this.setState({
            year_options
        });

        this.subscription = speechTextService.status().subscribe((data) => {
            if (this.state.speech.state) {
                if (this.state.speech.identifier === "risk_factors_remarks") {
                    this.ancFormRef.current.setFieldsValue({ risk_factors_remarks: data.text });

                    document.querySelector(
                        "#" + this.state.speech.identifier
                    ).style.height =
                        document.querySelector("#" + this.state.speech.identifier)
                            .scrollHeight + "px";
                }
                if (this.state.speech.identifier === "details") {
                    this.ancFormRef.current.setFieldsValue({ details: data.text });

                    document.querySelector(
                        "#" + this.state.speech.identifier
                    ).style.height =
                        document.querySelector("#" + this.state.speech.identifier)
                            .scrollHeight + "px";
                }

                if (this.state.speech.identifier === "indication_csection") {
                    this.ancFormRef.current.setFieldsValue({ indication_csection: data.text });

                    document.querySelector(
                        "#" + this.state.speech.identifier
                    ).style.height =
                        document.querySelector("#" + this.state.speech.identifier)
                            .scrollHeight + "px";
                }

                else {
                    this.state.savetests.map((obj, index) => {
                        if (this.state.speech.identifier === `result${index}` ? `result${index}` : '') {
                            let test_result = [`result${index}`];
                            //this.setState({ [test_result]: data.text });
                            this.ancFormRef.current.setFieldsValue({ [test_result]: data.text });
                        }
                    });
                }

            }
        });

        this.getPatientData();
        this.optionRiskFactorsList();
        // this.menstrualList();
        this.obstetricList();
        this.getGpaleValues();
        //this.fetchPhysicalExaminationData();
        this.fetchPhysicalExaminationFPData();
        this.handleObjectiveGetAllData();
        //this.getAncTestData();
        this.getVaccineData();
    }

    componentWillUnmount() {
        this?.subscription?.unsubscribe();
    }

    getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    async getPreviousAncData() {
        let vaccines_data = this.state.vaccineData;
        let inCImmunoprophylaxis = [];
        let params = { patient_id: this.state.patient.patient_id, doctor_id: this.state.patient.doctor_id };
        Axios.get(PREVIOUS_ANC_CARD_DETAILS(params))
            .then((response) => {
                let res_is = response.data.data.ancCardDetails;
                if (res_is?.length > 0) {
                    res_is.map((obj) => {
                        if (obj.deliveryDateTime == null) {
                            inCImmunoprophylaxis = JSON.parse(obj.Immunoprophylaxis);
                            inCImmunoprophylaxis.map(ele => {
                                vaccines_data.push(ele);
                            })
                            let newVac = this.getUniqueListBy(vaccines_data, 'name')
                            this.setState({ prevIncompleteAnc: obj, inCImmunoprophylaxis_data: newVac })
                        }
                    })
                    if (inCImmunoprophylaxis.length > 0) {
                        this.setState({ prevAncCardData: res_is })
                    } else {
                        this.setState({ prevAncCardData: res_is, inCImmunoprophylaxis_data: vaccines_data })
                    }
                    this.mergeTestData();
                } else {
                    this.setState({ prevIncompleteAnc: [], inCImmunoprophylaxis_data: vaccines_data, prevAncCardData: {} })
                    this.mergeTestData();
                }
            });
    }

    mergeTestData() {
        let savetests = this.state.prevIncompleteAnc?.tests ? JSON.parse(this.state.prevIncompleteAnc?.tests) : [];
        let radiology_test = this.state.prevIncompleteAnc?.Radiotests ? JSON.parse(this.state.prevIncompleteAnc?.Radiotests) : [];
        let radio_length = radiology_test.length;
        let editdata = this.state.testData;
        let edit_radiology_test = this.state.radiology_testData;

        radiology_test.sort(function (a, b) {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
                return 1;
            }
            if (nameA < nameB) {
                return -1;
            }
            return 0;
        });
        radiology_test?.map(element => {
            edit_radiology_test = edit_radiology_test.filter(el => el.name !== element.name);
        })
        edit_radiology_test.map(obj => {
            radiology_test.push(obj);
        })

        savetests.sort(function (a, b) {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
                return 1;
            }
            if (nameA < nameB) {
                return -1;
            }
            return 0;
        });
        savetests.map(element => {
            editdata = editdata.filter(el => el.name !== element.name);
        })
        editdata.map(obj => {
            savetests.push(obj);
        })

        this.setState({
            savetests: savetests,
            radiology_test: radiology_test,
            radio_previous_test_length: radio_length,
        })
        if (this.state?.prevIncompleteAnc?.deliveryIndication && this.state?.prevIncompleteAnc?.deliveryIndication != null) {
            this.ancFormRef.current.setFieldsValue({ indication_csection: this.state?.prevIncompleteAnc?.deliveryIndication });
        }
        if (this.state?.prevIncompleteAnc?.deliveryDetails && this.state?.prevIncompleteAnc?.deliveryDetails != null) {
            this.ancFormRef.current.setFieldsValue({ details: this.state?.prevIncompleteAnc?.deliveryDetails });
        }
        if (this.state?.prevIncompleteAnc?.ImmunoprophylaxisRemarks && this.state?.prevIncompleteAnc?.ImmunoprophylaxisRemarks != null) {
            this.ancFormRef.current.setFieldsValue({ immuno_remarks: this.state?.prevIncompleteAnc?.ImmunoprophylaxisRemarks });
        }
    }

    //Speech
    initSpeech(info) {
        let initialText = '';
        if (info.identifier === 'risk_factors_remarks') {
            initialText = this.state.risk_factors_remarks != undefined ? this.state.risk_factors_remarks : '';
        }
        if (info.identifier === 'details') {
            initialText = this.state.details != undefined ? this.state.details : '';
        }
        if (info.identifier === 'indication_csection') {
            initialText = this.state.indication_csection != undefined ? this.state.indication_csection : '';
        }

        if (info.identifier === this.state.speech.identifier) {
            this.setState({
                speech: {
                    identifier: info.identifier,
                    state: !this.state.speech.state,
                    initialText: initialText
                }
            }, () => {
                speechService.init(this.state.speech);
            });
        }
        else {
            this.setState({
                speech: {
                    identifier: this.state.speech.identifier,
                    state: false,
                    initialText: ''
                }
            }, () => {
                speechService.init(this.state.speech);
                setTimeout(() => {
                    this.setState({
                        speech: {
                            identifier: info.identifier,
                            state: true,
                            initialText: initialText
                        }
                    }, () => {
                        speechService.init(this.state.speech);
                    });

                }, 1000);
            });
        }
    }

    async handleModalPopup(e, popup, data = null) {
        if (e !== null) e.preventDefault();
        this.setState({
            [popup]: !this.state[popup],
        });
        if (popup == 'multiplePregnanciesModal') {
            if (this?.state?.multiplePregnancies?.length == 0) {
                await this.setState({
                    multiplePregnancies: [],
                    indication_reason_multiple: null,
                    pregnancy_type: null
                });
                this.calculateGPALE();
                this?.ancFormRef?.current?.setFieldsValue({
                    pregnancy_type: null
                });
            }
        }
    }

    onSelectCheckChangeListMultiple = async (e) => {
        this.setState({
            abnormality: e.target.checked
        })
    }

    onCheckVaccine = async (e, index) => {
        const { vaccine_impressions } = this.state;
        vaccine_impressions[index] = e.target.checked;
        this.setState({
            vaccine_impressions: vaccine_impressions
        });
    }

    handleModalSave = async (obj) => {
        if (obj?.multiple_pregnancies != undefined) {
            await this.setState({
                multiplePregnancies: obj.multiple_pregnancies
            });
            this.calculateGPALE();
        }
        if (obj?.indication_reason != undefined) {
            this.setState({
                indication_reason_multiple: obj.indication_reason
            });
            this.ancFormRef?.current?.setFieldsValue({ indication_csection: obj.indication_reason });
        }
        this.setState({
            multiplePregnanciesModal: false,
        });
    }

    onSelectChangeList = async (value, props, field) => {
        if (field == 'pregnancy_year') {
            this.calculateGPALE();
            await this.setState({
                [field]: moment(value).format('YYYY')
            });
        } else {
            await this.setState({
                [field]: value
            });
        }
        if (field == 'pregnancy_year') {
            this.calculateGPALE();
        }
        if (field == 'outcome') {
            this.calculateGPALE();
        }
        if (field == 'pregnancy_type' && (value == '1' || value == '3')) {
            this.calculateGPALE();
            this.setState({
                multiplePregnancies: []
            });
        }
        if (field == 'pregnancy_type' && value == '2') {
            this.setState({
                multiplePregnanciesModal: true
            });
        }
    }

    setImpressionValues(e, index, data) {
        const { lab_tests_impressions, lab_tests_impressions_date, lab_tests_impressions_refrence } = this.state;
        if (data == "result") {
            lab_tests_impressions[index] = e.target.value;
            this.setState({
                lab_tests_impressions: lab_tests_impressions
            });
        }
        if (data == "refernce") {
            lab_tests_impressions_refrence[index] = e.target.value;
            this.setState({
                lab_tests_impressions_refrence: lab_tests_impressions_refrence
            });
        }
        if (data == "date") {
            lab_tests_impressions_date[index] = e?._d ? e?._d : null;
            this.setState({
                lab_tests_impressions_date: lab_tests_impressions_date
            });
        }
    }

    setRadioImpressionValues(e, index, data) {
        const { lab_radio_tests_impressions, lab_radio_tests_impressions_date, lab_radio_tests_impressions_refrence } = this.state;
        if (data == "result") {
            lab_radio_tests_impressions[index] = e.target.value;
            this.setState({
                lab_radio_tests_impressions: lab_radio_tests_impressions
            });
        }
        if (data == "refernce") {
            lab_radio_tests_impressions_refrence[index] = e.target.value;
            this.setState({
                lab_radio_tests_impressions_refrence: lab_radio_tests_impressions_refrence
            });
        }
        if (data == "date") {
            lab_radio_tests_impressions_date[index] = e?._d ? e?._d : null;
            this.setState({
                lab_radio_tests_impressions_date: lab_radio_tests_impressions_date
            });
        }
    }



    getVaccineData() {
        let totalvaccine_data = []
        let params = { anc: 'active' };
        Axios.post(VaccinationList, params)
            .then((success) => {
                let totalvaccine = success.data.data;
                totalvaccine?.map(ele => {
                    let i;
                    for (i = 1; i <= ele.noOfDoses; i++) {
                        totalvaccine_data.push({
                            active: ele.active,
                            anc: ele.anc,
                            name: ele.name + " " + i,
                            noOfDoses: ele.noOfDoses,
                            type: ele.type,
                            vaccinationId: ele.vaccinationId,
                            vaccine_list: ele.vaccine_list,
                            // dose_number: i + 1,
                        });
                    }
                });
                this.setState({ vaccineData: totalvaccine_data });
                this.getAncTestData()
            });

    }

    getAncTestData() {
        let test_data = [];
        let radiologytest = [];
        let params = { anc_card: 'active' };
        Axios.post(LAB_TEST_SEARCH, params)
            .then((response) => {
                let tests = response.data.test_list;
                tests?.map(obj => {
                    if (obj.type == "Lab") {
                        test_data.push(obj)
                    }
                    else {
                        radiologytest.push(obj)
                    }
                })
                this.setState({ testData: test_data, radiology_testData: radiologytest });
                // setTimeout(() => {
                //     this.mergeTestData();
                // }, 6000);
                this.getPreviousAncData()
            });
    }

    async handlePreviewCancel() {
        await this.setState({
            previewListModal: false,
        })
    }

    async poupulatedField(e) {
        if (e.target.checked == true) {
            await this.setState({
                poupulated_Field: true,
            })
        }
        else {
            await this.setState({
                poupulated_Field: false,
            })
        }
    }

    async previewSave() {
        await this.setState({
            preview_option: true,
        });
    }

    async previewModel(params) {
        this.is_preview = false;
        await this.setState({
            previewparams: params,
            previewListModal: true,
        })
    }

    getPatientData = (_) => {
        const { patient } = this.state;
        const { patient_id } = patient;
        Axios.get(PATIENT_DATA({ patient_id }))
            .then((success) => {
                const patientInfo = {
                    ...success.data.data.results,
                    qms_token_id: success.data.data.results.qmsToken
                        ? success.data.data.results.qmsToken.qms_token_id
                        : null,
                };
                this.setState({
                    patientInfo,
                });
            })
            .catch((err) => console.log(err));
    }

    getPatientAge(dob) {
        var ageD = Math.floor(moment().diff(moment(dob, 'YYYY-MM-DD'), 'D') / (1000 * 3600 * 24));
        var ageM = moment().diff(moment(dob, 'YYYY-MM-DD'), 'M');
        var ageY = ageM / 12;
        var remainM = ageM % 12;
        ageY = Math.floor(ageY);
        var ageText = '';
        if (ageM == 0)
            ageText = ageD + ' Day' + (ageD == 1 ? '' : 's');
        else if (ageY < 1)
            ageText = ageM + ' Mon' + (ageM == 1 ? '' : 's');
        else if (ageY <= 5) {
            if (remainM != 0) {
                ageText = ageY + ' Yr' + (ageY == 1 ? '' : 's ') + remainM + ' Mon' + (remainM == 1 ? '' : 's');
            } else {
                ageText = ageY + ' Yr' + (ageY == 1 ? '' : 's');
            }
        }
        else
            ageText = ageY + ' Yrs';
        return ageText;
    }

    autoHeight = (e) => {
        if (e)
            e.target.style.height = e.target.scrollHeight + "px";
    }

    optionRiskFactorsList() {
        Axios.get(RISK_FACTORS_LIST).then(async success => {
            if (success?.data?.status == true) {
                this.setState({ risk_factors_options: success.data.data });
            }
        }).catch(err => console.log(err));
    }

    optionRiskFactorsLoad() {
        if (!this.state.risk_factors_options || this.state.risk_factors_options.length === 0) {
            return (<Option value={0}><span>No Result Found</span></Option>)
        }
        return this.state.risk_factors_options.map((obj, i) => {
            return (<Option key={i} value={parseInt(obj.id)} title={obj.description}>{obj.description}</Option>)
        }, this)
    }

    selectRiskFactorsChange = (value) => {
        this.setState({
            risk_factors: value,
        });

    }


    //MENSTRUAL_HISTORY_LIST
    menstrualList = async () => {
        const { patient_id, doctor_id, hospital_id, reference_id } = this.state.patient;
        const PARAMS = {
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
            hospital_id: hospital_id,
            reference_id: reference_id,
            reference_type: 0,
        }

        const result = await Axios.get(MENSTRUAL_HISTORY_LIST(PARAMS));
        if (result?.data?.data) {
            this.data = result?.data?.data;
            this.list = result?.data?.data.menstrualHistoryList;
            this.patientDetails = result?.data?.data.patientDetails;

            if (this.list.length > 0) {

                let data = this.list.filter(ele => { return moment(ele?.created_at).format("YYYY-MM-DD") >= moment(this.state.current_pregnancy_data?.createdAt).format("YYYY-MM-DD") })
                this.setState({ menstrualHistoryList: data });
            }
            if (this.list.length > 0 || this.patientDetails) {
                this.setState({ disabled_no_menstrual_hx: true });
            }
            this.setState({
                menstrualHistoryLmp: this.data,
                patientDetails: this.patientDetails,
                menstrualHistoryListStatus: true,
                is_confidential: this.patientDetails.is_confidential
            })
            lmpDataService.init({
                menstrualHistoryLmp: this.data,
            });
        }
    }

    //OBSTETRIC_HISTORY_LIST
    obstetricList = async () => {
        const { patient_id, doctor_id, hospital_id, reference_id } = this.state.patient;

        const PARAMS = {
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id
        }

        await Axios.post(OBSTETRIC_HISTORY_LIST, PARAMS)
            .then(success => {
                if (success?.data?.status == true) {
                    this.setState({ obsHistoryListStatus: true });

                    if (success.data.data.length > 0) {
                        let obs_history_list = [];

                        success?.data?.data?.forEach(ele => {
                            let obj = { id: ele.id, pregnancy_year: ele.year, mode_of_conception: ele.mode, mode_text: this.state.conception_options.filter(x => x.val == ele.mode)[0].label, pregnancy_type: ele?.pregnancyType, previous_births: ele?.previous_births, indication: ele.indication, remarks: ele.remarks };
                            if (ele?.pregnancyType == '2') {
                                obj.pregnancy_type_text = this.state.pregnancy_options.filter(x => x.val == ele.pregnancyType)[0].label + '(' + ele.previous_births.length + ')';
                                let prev_del_types = [];
                                let prev_outcomes = [];
                                ele.previous_births.forEach(el => {
                                    if (el.deliveryType) {
                                        let ob_del = { delivery_type: el.deliveryType, count: 1 };
                                        let index_del = prev_del_types.findIndex(m => m.delivery_type === el.deliveryType);
                                        if (index_del === -1) {
                                            prev_del_types.push(ob_del)
                                        } else {
                                            prev_del_types[index_del].count = prev_del_types[index_del].count + 1;
                                        }
                                    }

                                    let ob_outcome = { outcome: el.outcome, count: 1 };
                                    let index_outcome = prev_outcomes.findIndex(m => m.outcome === el.outcome);
                                    if (index_outcome === -1) {
                                        prev_outcomes.push(ob_outcome)
                                    } else {
                                        prev_outcomes[index_outcome].count = prev_outcomes[index_outcome].count + 1;
                                    }
                                });
                                let delivery_type_text_arr = [];
                                prev_del_types.forEach(e => {
                                    delivery_type_text_arr.push(this.state.delivery_options.filter(x => x.val == e.delivery_type)[0].label + '(' + e.count + ')');
                                });
                                let outcome_text_arr = [];
                                prev_outcomes.forEach(e => {
                                    outcome_text_arr.push(this.state.outcome_options.filter(x => x.val == e.outcome)[0].label + '(' + e.count + ')');
                                });

                                obj.delivery_type_text = delivery_type_text_arr.join(',');
                                if (obj.delivery_type_text == '') {
                                    obj.delivery_type_text = '-';
                                }
                                obj.outcome_text = outcome_text_arr.join(',');
                            } else {
                                obj.pregnancy_type_text = this.state.pregnancy_options?.filter(x => x.val == ele.pregnancyType)[0].label;
                                if (ele.previous_births[0]?.deliveryType) {
                                    obj.delivery_type_text = this.state.delivery_options?.filter(x => x.val == ele.previous_births[0].deliveryType)[0].label;
                                } else {
                                    obj.delivery_type_text = '-';
                                }
                                obj.outcome_text = this.state.outcome_options?.filter(x => x.val == ele.previous_births[0]?.outcome)[0]?.label;
                            }
                            obs_history_list.push(obj);
                        });
                        this.setState({ obsHistoryList: obs_history_list });
                    } else {
                        let { gpale_values_initial } = this.state;
                        if (gpale_values_initial?.parity === null && gpale_values_initial?.gravidity === null && gpale_values_initial?.abortion === null && gpale_values_initial?.live_birth === null && gpale_values_initial?.ectopic === null) {
                            this.setState({
                                disabled_no_obs_hx: false
                            })
                        }
                    }
                }
            }).catch(err => console.log(err));
    }


    getGpaleValues = () => {
        const { patient_id, doctor_id } = this.state.patient;
        const PARAMS = {
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
        }

        Axios.post(GPALE_CURRENT_PREGNANCY_DATA, PARAMS)
            .then(async success => {
                if (success?.data?.status == true) {
                    let gpale_data = success.data.data.gpaleData;
                    if (gpale_data) {
                        let obj = { gravidity: gpale_data?.gravidity, parity: gpale_data.parity, abortion: gpale_data.abortion, live_birth: gpale_data.liveBirth, ectopic: gpale_data.ectopic };
                        this.setState({
                            gpale_values: obj,
                            all_get_data: success.data.data,
                        })
                    }
                    let current_pregnancy_data = success.data.data.currentPregnancyData;
                    let fetus_data = success.data.data.phyExamFetusData;

                    if (current_pregnancy_data) {
                        let obj = { pregnant: current_pregnancy_data.pregnant, testsDone: current_pregnancy_data.testDone ? current_pregnancy_data.testDone.split(',') : [], lmpWeeks: current_pregnancy_data.gestationalAgeLmpWeeks, lmpDays: current_pregnancy_data.gestationalAgeLmpDays, usgWeeks: current_pregnancy_data.gestationalAgeUsgWeeks, usgDays: current_pregnancy_data.gestationalAgeUsgDays, usgDate: current_pregnancy_data.usgDoneOn, eddDate: current_pregnancy_data.eddDate, correctedEddDate: current_pregnancy_data.correctedEddDate, lactating: current_pregnancy_data.lactating == '1' ? true : false, remarksForCurrentPregnancy: current_pregnancy_data.remarksForCurrentPregnancy, riskFactors: current_pregnancy_data.riskFactors ? current_pregnancy_data.riskFactors.split(',') : [], riskFactorsRemarks: current_pregnancy_data.riskFactorsRemarks, createdAt: current_pregnancy_data.createdAt };
                        if (fetus_data?.noOfFetus) {
                            obj.no_of_fetus = fetus_data?.noOfFetus
                        }
                        this.menstrualList();
                        this.fetchPhysicalExaminationData();
                        this.setState({
                            previous_reference_id: current_pregnancy_data?.referenceId,
                            current_pregnancy_data: obj,
                            risk_factors: obj?.riskFactors?.map((id, i) => parseInt(id))
                        })
                        this.ancFormRef?.current?.setFieldsValue({ risk_factors_remarks: obj?.riskFactorsRemarks });
                    } else {
                        if (fetus_data) {
                            this.setState(prevState => {
                                const prevItems = prevState.current_pregnancy_data;
                                if (prevItems) {
                                    prevItems.no_of_fetus = fetus_data?.noOfFetus;
                                    return { current_pregnancy_data: prevItems };
                                }
                            });
                        }
                    }
                    let no_obs_history_data = success.data.data.noObsHistory;
                    if (no_obs_history_data) {
                        this.setState({
                            no_obs_history: no_obs_history_data.isChecked == '1' ? true : false
                        })
                    }
                    await this.setState({ gpale_loaded: true });
                } else {
                    await this.setState({ gpale_loaded: true });
                }
                lmpDataService.init({
                    menstrualHistoryLmp: this.state.menstrualHistoryLmp
                });
            }).catch(err => console.log(err))
    }

    //Fetch from api
    async fetchPhysicalExaminationData() {
        const { patient_id, doctor_id } = this.state.patient;
        const param = {
            patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
        };
        const result = await Axios.get(LIST_PHYSICAL_EXAMINATION(param));
        if (result?.data?.status == true) {
            this.setState({ phyExamListStatus: true });

            if (result?.data?.data && result?.data?.data.length > 0) {
                let data = result?.data?.data.filter(ele => { return moment(ele?.createdAt).format("YYYY-MM-DD") >= moment(this.state.current_pregnancy_data?.createdAt).format("YYYY-MM-DD") })
                this.setState({ physicalExaminationData: data });
            }
        }
    }

    //Fetch from api
    async fetchPhysicalExaminationFPData() {
        let fetal_data = []
        const { patient_id, doctor_id } = this.state.patient;
        const param = {
            patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
        };

        const result = await Axios.get(LIST_PHYSICAL_EXAMINATION_FETAL_PARAMS(param));
        if (result?.data?.status == true) {
            this.setState({ phyExamFPListStatus: true });
            if (result?.data?.data && result?.data?.data.length > 0) {
                let total_count_fetal = 0;
                let data_is = result?.data?.data;
                data_is?.map(ele => {
                    if (ele?.fetal_params?.length > 0) {
                        total_count_fetal = total_count_fetal + 1;
                        fetal_data.push(ele);
                    }
                })
                this.setState({ physicalExaminationFPData: fetal_data, total_count_fetal: total_count_fetal });
            }
        }
    }

    handleObjectiveGetAllData = async () => {
        const { patient_id } = this.state.patient;
        const objectiveGetAllPromise = await Axios.get(OBJECTIVE_GETALL({ patient_id }));

        if (objectiveGetAllPromise.data.status) {
            const { data } = objectiveGetAllPromise.data;
            const { details, constants } = data;
            const { allergies, conditions, surgical_histories } = details;

            await this.setState({
                // details,
                // visitVitals: Object.values(details.visitVitals),
                // visitVitalsKeyFindings: Object.values(details.visitVitalsKeyFindings),
                allergies,
                conditions,
                surgical_histories,
                // constants,
                // objective_data_loaded: true,
                // noallergies: details.no_known_allergy,
                // noExistingCondition: details.no_known_condition,
                // noSurgicalHx: details.no_known_surgical_hsitory,
            });
            // this.makeVitalsData();
            // await this.handleKeyFindingFilterData();
            // this.makeKeyFindingsData();
        }
    };

    disabledPastDate(current) {
        return current && (current > moment());
    }

    getListValue(selectedValue, OptionObj = null) {
        if (selectedValue == 0 || selectedValue === null) {
            return "-";
        } else if (OptionObj) {
            return OptionObj[selectedValue];
        } else {
            return selectedValue;
        }
    }

    showPhysicalExamData() {
        const __physicalExamData = this.state.physicalExaminationData;
        if (__physicalExamData && __physicalExamData.length > 0) {
            return __physicalExamData.map((data, i) => (
                <tr key={data.id}>
                    <td style={(i + 1) == __physicalExamData.length ? { paddingBottom: '20px' } : {}}>
                        {moment(data.createdAt).format("D MMM, YYYY")}
                    </td>
                    <td>{data.edema || '-'}</td>
                    <td>{data.pallor || '-'}</td>
                    <td>{data.fundalHeight || '-'}</td>
                    <td>{data.remarks || '-'}</td>
                </tr>
            ));
        } else {
            return (
                <tr>
                    <td colSpan="6">No data available</td>
                </tr>
            );
        }
    }

    showPhysicalExamFPData() {
        const __physicalExamFPData = this.state.physicalExaminationFPData;
        if (this.state.total_count_fetal == 0) {
            return (
                <tr>
                    <td colSpan="6">No data available</td>
                </tr>
            );
        }
        if (__physicalExamFPData?.length > 0) {
            return __physicalExamFPData.map((data, i) => (
                data.fetal_params.map((d, j) => (
                    <tr key={d.id} className={j != 0 ? 'highlight_bg' : ''}>
                        {j == 0 ?
                            <td rowSpan={data.fetal_params.length}> {moment(data.createdAt).format("D MMM, YYYY")}
                            </td>
                            : null}
                        <td className="text-center" style={(i + 1) == __physicalExamFPData.length && (j + 1) == data.fetal_params.length ? { paddingBottom: '20px' } : {}}>{j + 1}</td>
                        <td className="text-center">{this?.getListValue(d.fetalMovement, FETAL_MOVEMENT)}</td>
                        <td className="text-center">{d.fetalHeartRate || '-'}</td>
                        <td className="text-center">{this?.getListValue(d.presentation, PRESENTATION)}</td>
                        <td className="text-center">{this?.getListValue(d.relPPtoBrim, RELATIONSHIP_TO_PP_BRIM)}</td>
                        <td className="text-center">{d.headCc || '-'}</td>
                        <td className="text-center">{d.abdominalCc || '-'}</td>
                        <td className="text-center">{d.femurLength || '-'}</td>
                        <td style={{ wordBreak: "break-all" }} className="text-center">{data.remarks || '-'}</td>
                    </tr>
                ))
            ));
        } else {
            return (
                <tr>
                    <td colSpan="6">No data available</td>
                </tr>
            );
        }
    }

    onFinish = async (values) => {
        let test_new = [];
        let test_populated = []
        let vaccine_new = [];
        let vaccine = []
        let vaccine_populated = [];

        let radio_test_new = [];
        let radio_test_populated = [];
        let outcome_is = [];
        let preg_year = "";
        if (values?.pregnancy_year_date != null && values?.pregnancy_year_date != undefined){
            preg_year = values?.pregnancy_year_date.toString();
        }

        if (values.date != null || values.outcome != null || values.birth_weight != null || values.delivery_type != null || values.place_of_delivery != null) {
            outcome_is.push({
                outcome: values?.outcome ? values?.outcome : "",
                delivery_type: values?.delivery_type ? values?.delivery_type : "",
                birth_weight: values?.birth_weight ? values?.birth_weight : "",
                gender: values?.gender ? values?.gender : "",
                place_of_delivery: values?.place ? values?.place : "",
                abnormality: values.abnormality || false,
                abnormality_remark: values?.abnormality == true ? values?.abnormality_remark : "" || "",
                birth_date_time: values?.date ? moment(values?.date).format('YYYY-MM-DD HH:mm:ss') : ""
            });
        }


        if (values?.radio_newresult?.length > 0) {
            for (var i = 0; i < values.radio_newresult.length; i++) {
                radio_test_new.push({
                    result: values.radio_newresult[i] ? values.radio_newresult[i] : '',
                    refernce: values.radio_newrefernce[i] ? values.radio_newrefernce[i] : '',
                    name: this.state.radiology_test[i].name,
                    date: values.radio_test_newdate[i] ? values.radio_test_newdate[i].format("D MMM, YYYY") : null,
                })
            }
            radio_test_populated = radio_test_new.filter(obj => {
                return (obj.result != null && obj.result != undefined && obj.result != '') || (obj.refernce != null && obj.refernce != undefined && obj.refernce != '') || (obj.date != null && obj.date != undefined && obj.date != '');
            })
        }


        if (values?.newresult?.length > 0) {
            for (var i = 0; i < values.newresult.length; i++) {
                test_new.push({
                    result: values.newresult[i] ? values.newresult[i] : '',
                    refernce: values.newrefernce[i] ? values.newrefernce[i] : '',
                    name: this.state.savetests[i].name,
                    date: values.test_newdate[i] ? values.test_newdate[i].format("D MMM, YYYY") : null,
                })
            }


            test_populated = test_new.filter(obj => {
                return (obj.result != null && obj.result != undefined && obj.result != '') || (obj.refernce != null && obj.refernce != undefined && obj.refernce != '') || (obj.date != null && obj.date != undefined && obj.date != '');
            })
        }


        if (values?.vaccine_date && values?.vaccine_date.length > 0) {
            const inCImmunoprophylaxis = this.state.inCImmunoprophylaxis_data
            for (var i = 0; i < values.vaccine_date.length; i++) {
                vaccine.push({
                    date: values.vaccine_date[i] ? values.vaccine_date[i].format("D MMM, YYYY") : null,
                    newvaccine_status: values.vaccine_status[i] ? values.vaccine_status[i] : null,
                    name: inCImmunoprophylaxis[i].name ? inCImmunoprophylaxis[i].name : null,
                })
            }
            vaccine.map(obj => {
                vaccine_new.push(obj)
            })
        }

        if (vaccine_new?.length > 0) {
            vaccine_populated = vaccine_new.filter(obj => {
                return (obj.check != null && obj.check != undefined && obj.check != '' && obj.check != false) || (obj.date != null && obj.date != undefined && obj.date != '');
            })
        }

        this.setState({
            test_previous: test_new,
            test_populated: test_populated,
            radio_test_previous: radio_test_new,
            radio_test_populated: radio_test_populated,
            vaccine_previous: vaccine_new,
            vaccine_populated: vaccine_populated
        })

        const { patient } = this.state;

        let params = {
            doctor_id: patient.doctor_id,
            patient_id: patient.patient_id,
            reference_id: patient.reference_id,
            reference_type: patient.reference_type,
            hospital_id: LS_SERVICE.get('slot_hospital_id'),
            risk_factors: this.state.risk_factors.length ? this.state.risk_factors.join(',') : null,
            risk_factors_management: values.risk_factors_remarks ? values.risk_factors_remarks : null,
            immuno_remarks: values.immuno_remarks ? values.immuno_remarks : null,
            tests: test_populated ? JSON.stringify(test_populated) : null,
            radio_tests: radio_test_populated ? JSON.stringify(radio_test_populated) : null,
            immunoprophylaxis: vaccine_populated ? JSON.stringify(vaccine_populated) : null,
            existing_condition: this.state.conditions ? JSON.stringify(this.state.conditions) : null,
            allergies_data: this.state.allergies ? JSON.stringify(this.state.allergies) : null,
            menstrual_history: this.state.menstrualHistoryList ? JSON.stringify(this.state.menstrualHistoryList) : null,
            obs_history: this.state.obsHistoryList ? JSON.stringify(this.state.obsHistoryList) : null,
            physical_examinationData: this.state.physicalExaminationData ? JSON.stringify(this.state.physicalExaminationData) : null,
            physical_examination_fpdata: this.state.physicalExaminationFPData ? JSON.stringify(this.state.physicalExaminationFPData) : null,
            gpale_lactating: this.state.gpale_values ? JSON.stringify(this.state?.gpale_values) : null,
            //delivery_dateChange: this.state.multiplePregnancies.length > 0 ? moment(this.state.multiplePregnancies[0].birth_date_time).format('YYYY-MM-DD HH:mm:ss') : (values.date ? moment(values.date).format('YYYY-MM-DD HH:mm:ss') : null),
            delivery_dateChange: values.pregnancy_year_date ? preg_year : null,
            delivery_place: values.place ? values.place : null,
            delivery_mode: values.mode ? values.mode : null,
            delivery_outcome: values.outcome ? values.outcome : null,
            pregnancy_type: values.pregnancy_type ? values.pregnancy_type : null,
            indication_csection: values.indication_csection ? values.indication_csection : null,
            details: values.details ? values.details : null,
            current_pregnancy_id: this.state?.all_get_data?.currentPregnancyData?.id ? this.state?.all_get_data?.currentPregnancyData?.id : null,
            edd_by_lmp: this.state.current_pregnancy_data?.eddDate ? moment(this.state.current_pregnancy_data?.eddDate).format("D MMM, YYYY") : "",
            lmp: this.state.patientDetails.lmp_date ? this.state.patientDetails.lmp_date : "",
            edd_by_usg: this.state.current_pregnancy_data?.usgDate ? moment(this.state.current_pregnancy_data?.usgDate).format("D MMM, YYYY") : "",
            outcome_group: values.pregnancy_type == "2" ? this.state?.multiplePregnancies : outcome_is,
            outcome_group_is: values.pregnancy_type == "2" ? JSON.stringify(this.state?.multiplePregnancies) : JSON.stringify(outcome_is),
        }

        if (this.is_preview == true) {
            this.previewModel(params);
        }

        else {
            this.setState({
                spin_loading: true,
            })

            if (this.state.previous_reference_id && this.state.previous_reference_id != 0) {
                let paramsdata = {
                    reference_id: this.state.previous_reference_id,
                    risk_factors: params?.risk_factors ? params?.risk_factors : "",
                    risk_factor_remark: params?.risk_factors_management ? params?.risk_factors_management : "",
                }
                await Axios.post(UPDATE_CURRENT_PREGENENCY, paramsdata)
            }


            if (params.delivery_dateChange != null) {
                let previous_pregnancies = {};
                previous_pregnancies.pregnancy_year = preg_year;
                previous_pregnancies.mode_of_conception = values.mode;
                previous_pregnancies.pregnancy_type = values.pregnancy_type;
                if (values.pregnancy_type != '2') {
                    previous_pregnancies.births = [
                        {
                            delivery_type: values?.delivery_type ? values.delivery_type : "",
                            outcome: values?.outcome ? values.outcome : "",
                            birth_weight: values?.birth_weight ? values.birth_weight : "",
                            place_of_delivery: values?.place ? values.place : "",
                            gender: values?.gender ? values.gender : "",
                            birth_date_time: moment(values.date).format('YYYY-MM-DD HH:mm:ss'),
                            abnormality: values?.abnormality || false,
                            abnormality_remark: values?.abnormality_remark || null
                        }
                    ];
                    if (this.state.delivery_type == '2') {
                        previous_pregnancies.indication_reason = values.indication_csection;
                    }
                } else {
                    previous_pregnancies.births = this.state.multiplePregnancies;
                    if (this.state.indication_reason_multiple != null) {
                        previous_pregnancies.indication_reason = this.state.indication_reason_multiple;
                    }
                }
                previous_pregnancies.remarks = values.details;

                let current_pregnancy = {
                    pregnant: this.state.current_pregnancy_data.pregnant,
                    // pregnant: "1",
                    tests_done: this.state.current_pregnancy_data.testsDone,
                    lmp_weeks: this.state.current_pregnancy_data.lmpWeeks,
                    lmp_days: this.state.current_pregnancy_data.lmpDays,
                    usg_weeks: this.state.current_pregnancy_data.usgWeeks,
                    usg_days: this.state.current_pregnancy_data.usgDays,
                    usg_done_on: this.state.current_pregnancy_data.usgDate,
                    edd_date: this.state.current_pregnancy_data.eddDate,
                    corrected_edd_date: this.state.current_pregnancy_data.correctedEddDate,
                    lactating: this.state.current_pregnancy_data.lactating,
                    remarks_for_current_pregnancy: values.details,
                    risk_factors: this.state.risk_factors,
                    risk_factors_remarks: values.risk_factors_remarks
                };
                let PARAMS_IS = {
                    patient_id: LS_SERVICE.get('call_patient_detail').patient_id,
                    doctor_id: (LS_SERVICE.get('user_type') === 2) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : LS_SERVICE.get('call_patient_detail').doctor_id,
                    hospital_id: LS_SERVICE.get('call_patient_detail').hospital_id,
                    reference_id: this.state.patient.reference_id,
                    reference_type: 0,
                    previous_pregnancies: previous_pregnancies,
                    gpale: this.state?.gpale_values,
                    current_pregnancy: current_pregnancy,
                    no_obs_hx: this.state?.all_get_data?.noObsHistory?.isChecked == "0" ? false : true,
                    no_of_fetus: this.state?.all_get_data?.phyExamFetusData?.noOfFetus
                }

                let current_pregnancy_data = {
                    pregnant: "2",
                    tests_done: [],
                    lmp_weeks: null,
                    lmp_days: null,
                    usg_weeks: null,
                    usg_days: null,
                    usg_done_on: null,
                    edd_date: null,
                    corrected_edd_date: null,
                    lactating: false,
                    remarks_for_current_pregnancy: null,
                    risk_factors: [],
                    risk_factors_remarks: ""
                };
                let PARAMS_DATA = {
                    patient_id: LS_SERVICE.get('call_patient_detail').patient_id,
                    doctor_id: (LS_SERVICE.get('user_type') === 2) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : LS_SERVICE.get('call_patient_detail').doctor_id,
                    hospital_id: LS_SERVICE.get('call_patient_detail').hospital_id,
                    reference_id: this.state.patient.reference_id,
                    reference_type: 0,
                    previous_pregnancies: {},
                    gpale: this.state?.gpale_values,
                    current_pregnancy: current_pregnancy_data,
                    no_obs_hx: false,
                    no_of_fetus: null
                }
                Axios.post(OBSTETRIC_HISTORY_STORE, PARAMS_IS).then(res => {
                    if (res.data.status == true) {
                        Axios.post(OBSTETRIC_HISTORY_STORE, PARAMS_DATA).then(res => {
                            if (res.data.status == true) {
                                this.getGpaleValues();
                            }
                        })
                    }
                })
            }
            await Axios.post(SAVE_ANC_CARD, params)
                .then((success) => {
                    notification.success({
                        message: "Anc card saved successfully",
                        placement: "topRight",
                    });
                    this.setState({
                        spin_loading: false,
                        inCImmunoprophylaxis_data: [],
                        test_previous: [],
                        vaccineData: [],
                        radio_test_previous: [],
                        radio_test_populated: [],
                        test_populated: [],
                        vaccine_previous: [],
                        vaccine_populated: [],
                        savetests: [],
                        radiology_test: [],
                        prevAncCardData: {}

                    });
                    this.ancFormRef.current.resetFields();
                    this.ancFormRef.current.setFieldsValue({ test_newdate: [], newresult: [], newrefernce: [] });
                    this.ancFormRef.current.setFieldsValue({ radio_test_newdate: [], radio_newresult: [], radio_newrefernce: [] });
                   // this.componentDidMount();//
                    this.getVaccineData();
                })
                .catch((error) => {
                    notification.error({
                        message: "Not able to save!",
                        placement: "topRight",
                    });
                    this.setState({
                        spin_loading: false,
                    });
                });
        }

    };


    submitForm = () => {
        this.is_preview = true;
    }

    insert = (arr, index, newItem) => [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted item
        newItem,
        // part of the array after the specified index
        ...arr.slice(index)
    ]

    async removeNewTest(index) {
        let newData = [];
        let test = this.state.savetests;

        const { test_newdate, newresult, newrefernce } = this.ancFormRef.current.getFieldValue();
        test_newdate.splice(index, 1);
        newrefernce.splice(index, 1);
        newresult.splice(index, 1);
        for (let i = 0; i <= test_newdate.length; i++) {
            {
                if (newrefernce[i] == null) {
                    newrefernce[i] = "";
                }
                if (newresult[i] == null) {
                    newresult[i] = "";
                }
            }
        }

        newData = test.filter((item, i) => i != index);
        this.ancFormRef.current.setFieldsValue({ test_newdate: test_newdate, newresult: newresult, newrefernce: newrefernce });
        await this.setState({ savetests: newData });
    }

    async removeRadioNewTest(index) {
        let newData = [];
        let test = this.state.radiology_test;

        const { radio_test_newdate, radio_newresult, radio_newrefernce } = this.ancFormRef.current.getFieldValue();
        radio_test_newdate.splice(index, 1);
        radio_newresult.splice(index, 1);
        radio_newrefernce.splice(index, 1);
        for (let i = 0; i <= radio_test_newdate.length; i++) {
            if (radio_newresult[i] == null) {
                radio_newresult[i] = ""
            }
            if (radio_newrefernce[i] == null) {
                radio_newrefernce[i] = ""
            }
        }

        newData = test.filter((item, i) => i != index);
        this.ancFormRef.current.setFieldsValue({ radio_test_newdate: radio_test_newdate, radio_newresult: radio_newresult, radio_newrefernce: radio_newrefernce });
        await this.setState({ radiology_test: newData });
    }

    async addNewRadioTest(index) {
        let element = {}
        let newData = []
        let insert = ""
        let ind = index + 1;

        const { radio_test_newdate, radio_newresult, radio_newrefernce } = this.ancFormRef.current.getFieldValue();
        radio_test_newdate.splice(ind, 0, insert);
        radio_newresult.splice(ind, 0, insert);
        radio_newrefernce.splice(ind, 0, insert);

        element = {
            result: '',
            refernce: '',
            name: this.state.radiology_test[index].name,
            date: '',
            remove: 1
        }

        newData = this.insert(this.state.radiology_test, ind, element);
        this.ancFormRef.current.setFieldsValue({ radio_test_newdate: radio_test_newdate, radio_newresult: radio_newresult, radio_newrefernce: radio_newrefernce });
        await this.setState({ radiology_test: newData });
    }

    async addNewTest(index) {
        let element = {}
        let newData = []
        let insert = ""
        let ind = index + 1;

        const { test_newdate, newresult, newrefernce } = this.ancFormRef.current.getFieldValue();
        test_newdate.splice(ind, 0, insert);
        newresult.splice(ind, 0, insert);
        newrefernce.splice(ind, 0, insert);


        element = {
            result: '',
            refernce: '',
            name: this.state.savetests[index].name,
            date: '',
            remove: 1
        }
        newData = this.insert(this.state.savetests, ind, element);
        this.ancFormRef.current.setFieldsValue({ test_newdate: test_newdate, newresult: newresult, newrefernce: newrefernce });
        await this.setState({ savetests: newData });
    }

    calculateGPALE = async () => {
        let g = 0, p = 0, a = 0, l = 0, e = 0;

        if (this.state.obsHistoryList?.length > 0) {
            this.state.obsHistoryList.forEach(ele => {
                if (!this.state?.edit_pregnancy_id || (this.state?.edit_pregnancy_id && ele?.id != this.state?.edit_pregnancy_id)) {
                    g++; p++;
                    ele.previous_births.forEach(el => {
                        if (el.outcome == '5' || el.outcome == '6') {
                            a++;
                        }
                        if (el.outcome == '1' || el.outcome == '2') {
                            l++;
                        }
                    });
                    if (ele.pregnancy_type == '3') {
                        e++;
                    }
                }
            });
        }

        if (this.state?.pregnancy_year) {
            g++; p++;
            if (this.state?.pregnancy_type == '2') {
                this.state.multiplePregnancies.forEach(el => {
                    if (el.outcome == '5' || el.outcome == '6') {
                        a++;
                    }
                    if (el.outcome == '1' || el.outcome == '2') {
                        l++;
                    }
                })
            } else {
                if (this.state.outcome == '5' || this.state.outcome == '6') {
                    a++;
                }
                if (this.state.outcome == '1' || this.state.outcome == '2') {
                    l++;
                }
                if (this.state.pregnancy_type == '3') {
                    e++;
                }
            }
        }

        if (this.state?.all_get_data?.currentPregnancyData?.pregnant == '1') {
            g++;
            if (this.state?.all_get_data?.currentPregnancyData?.gestationalAgeLmpWeeks || this.state?.all_get_data?.currentPregnancyData?.gestationalAgeUsgWeeks) {
                if (this.state?.all_get_data?.currentPregnancyData?.gestationalAgeLmpWeeks >= 20 || this.state?.all_get_data?.currentPregnancyData?.gestationalAgeUsgWeeks >= 20) {
                    p++;
                }
            }
        }

        await this.setState({
            gravidity: g,
            parity: p,
            abortion: a,
            live_birth: l,
            ectopic: e
        });

        this.setState({
            gpale_values: {
                gravidity: g,
                parity: p,
                abortion: a,
                live_birth: l,
                ectopic: e
            }
        })
    }

    getPageMargins = () => {
        return `
            @media print {
                @page { size: A4; margin: 15mm 5mm 10mm 5mm !important; }
            }
        `;
    };

    render() {
        const { lab_tests_impressions, lab_tests_impressions_date, lab_tests_impressions_refrence, patient, patientInfo, patientDetails, prevAncCardData, previewparams, prevIncompleteAnc, savetests, all_get_data, inCImmunoprophylaxis_data,
            lab_radio_tests_impressions, lab_radio_tests_impressions_refrence, lab_radio_tests_impressions_date, abnormality, vaccine_impressions } = this.state;
        return (
            <>
                <AsideLeft />
                <MyHcSpeechRecognition />
                <div className="content-wrapper">
                    <div className="container-fluid emrfrm anc-container mb-4 ">
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <div className="float-left">
                                    <h1 className="page-title mb-0">ANC Card</h1>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card anc-cardgrey'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <span className='heading'>Patient Name :&nbsp;</span>
                                            {(patientInfo) ?
                                                <span className='patient_name'>{patientInfo?.first_name !== undefined ? patientInfo?.first_name : null}&nbsp;{patientInfo?.middle_name !== undefined ? patientInfo?.middle_name : null}&nbsp;{patientInfo?.last_name !== undefined ? patientInfo?.last_name : null}, {patientInfo?.dob && patientInfo?.age !== null ? this.getPatientAge(patientInfo?.dob) : null}, {patientInfo?.gender !== undefined && patientInfo?.gender !== null ? (patientInfo?.gender == 'Male' ? ' (M)' : ' (F)') : null}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <span className='heading'>Address :&nbsp;</span>
                                            {(patientInfo) ?
                                                <span className='patient_name'>{patientInfo?.address_1 !== undefined ? patientInfo?.address_1 : null}, {patientInfo?.city !== undefined ? patientInfo?.city : null},   {patientInfo?.state !== undefined ? patientInfo?.state : null}</span>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mohit Previeus ANC Card */}
                        {prevAncCardData.length > 0 ?
                            prevAncCardData.map((obj, index) => {
                                return (
                                    obj.deliveryDateTime != null ?
                                        <AncCardView
                                            ref={this.createRef}
                                            patientInfo={patientInfo}
                                            patient={this.state.patient}
                                            prevAncCardData={obj}
                                            index={index}
                                            riskFactorsOptions={this.state.risk_factors_options}
                                        />
                                        :
                                        null
                                );
                            })
                            : null}

                        {this.state?.current_pregnancy_data?.pregnant == "1" ?
                            <>
                                <div className='row mt-3'>
                                    <div className='col-12'>
                                        <div className='card anc-carddarkgrey'>
                                            <span className='newancdet'>New ANC Card Details</span>
                                        </div>
                                    </div>
                                </div>

                                <Form
                                    layout="vertical"
                                    className="emrfrm"
                                    ref={this.ancFormRef}
                                    onFinish={this.onFinish}
                                    id="saveform"
                                >
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <div className={`form-label-group input-group ${this.state.risk_factors ? "hasdata" : ""}`}>
                                                <Select showSearch id="risk_factors" name="risk_factors" style={{ width: 100 + '%' }}
                                                    onChange={(e) => this.selectRiskFactorsChange(e)}
                                                    mode="multiple"
                                                    value={this.state.risk_factors}
                                                    allowClear
                                                    filterOption={(input, option) => {
                                                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                    }}
                                                >
                                                    {this.optionRiskFactorsLoad()}
                                                </Select>
                                                <label htmlFor="schedules">Risk Factors</label>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="form-group">
                                                <div className={`form-group antInputItem micwrap hasdata`}>
                                                    <Link
                                                        onClick={(e) => {
                                                            e.preventDefault(); this.initSpeech({ identifier: "risk_factors_remarks" });
                                                        }}
                                                        className={
                                                            this.state.speech.identifier === "risk_factors_remarks" &&
                                                                this.state.speech.state
                                                                ? "mic subjective-mic on mute"
                                                                : "mic subjective-mic"
                                                        }
                                                    ></Link>
                                                    <Form.Item name="risk_factors_remarks" label="Risk Factors Remarks" id="risk_factors_remarks"
                                                        onInput={(e) => this.autoHeight(e)}
                                                    >
                                                        <  textarea className="form-control diagnosis_notes_class speech-field"
                                                        // defaultValue={this.state.risk_factors_remarks}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-12'>
                                            <span className='title'> Allergies</span>
                                        </div>
                                        <table className="table table-bordered mt-3" >
                                            <thead>
                                                <tr>
                                                    <td width="28%">ALLERGY NAME</td>
                                                    <td width="22%">REACTION</td>
                                                    <td width="15%">DURATION</td>
                                                    <td width="15%">INTENSITY</td>
                                                    <td width="15%">INFORMED BY</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.allergies != undefined && this.state.allergies.length > 0 ?
                                                    this.state.allergies.map((obj) => (
                                                        <tr>
                                                            <td>{obj.allergy_name}</td>
                                                            <td>{obj.reaction ? obj.reaction : '-'}</td>
                                                            <td>{obj.duration ? obj.duration + ' ' + DURATION_TYPE[obj.durationType] : '-'}</td>
                                                            <td>{obj.intensity === 0 ? "Low" : obj.intensity === 1 ? "Medium" : obj.intensity === 2 ? "High" : '-'}</td>
                                                            <td>{obj?.informedBy == 0 || obj?.informedBy == 1 ? INFORMED_BY[obj?.informedBy] : "-"}</td>
                                                        </tr>
                                                    ))
                                                    : <tr><td colspan="5">No data available</td></tr>}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='row mt-4'>
                                        <div className='col-12'>
                                            <span className='title'>Existing Condition</span>
                                        </div>
                                        <table className="table table-bordered mt-3" >
                                            <thead>
                                                <tr>
                                                    <td width="30%">CONDITIONS</td>
                                                    <td width="20%">DURATION</td>
                                                    <td width="20%">ON MEDICATION</td>
                                                    <td width="30%">MEDICINE NAME</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.conditions != undefined && this.state.conditions.length > 0 ?
                                                    this.state.conditions.map((c, i) => (
                                                        <tr>
                                                            <td>{c.condition_description}</td>
                                                            <td>{(c.duration) ? `${c.duration} ${DURATION_TYPE[c.durationType]}` : '-'}</td>
                                                            <td>{MEDICATION_ON[c.onMedication] ? MEDICATION_ON[c.onMedication] : '-'}</td>
                                                            <td>{c.medicineName || "NA"}</td>
                                                        </tr>
                                                    ))
                                                    : <tr><td colspan="4">No data available</td></tr>}
                                            </tbody>
                                        </table>
                                    </div>

                                    {this.state.menstrualHistoryListStatus ?
                                        <>
                                            <div className='row mt-4'>
                                                <div className='col-3'>
                                                    <span className='title'>Menstrual Hx</span>
                                                </div>
                                                <div className='col-4 text-center'>
                                                    {patientDetails && patientDetails.lmp_date ?
                                                        <>
                                                            <span>LMP:</span>  <span className='title'>{patientDetails.lmp_date}</span>
                                                        </>
                                                        : null}
                                                </div>
                                                <div className='col-5 text-right'>
                                                    {this.state?.menstrualHistoryList.length > 0 ?
                                                        <>
                                                            <span className='title'>Last Updated
                                                                ({moment(this.state?.menstrualHistoryList[0]?.created_at).format("D MMM, YYYY")} | {
                                                                    this.state?.menstrualHistoryList[0]?.created_at_time}
                                                                )</span>
                                                        </>
                                                        : null}
                                                </div>
                                                <table className="table table-bordered mt-3" >
                                                    <thead>
                                                        <tr>
                                                            <td width="10%">DATE</td>
                                                            <td width="20%">CYCLE LENGTH</td>
                                                            <td width="20%">DURATION</td>
                                                            <td width="10%">FLOW</td>
                                                            <td width="10%">REGULARITY</td>
                                                            <td width="10%">PAIN</td>
                                                            <td width="20%">CHANGES IN CYCLE</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.menstrualHistoryList !== undefined && this.state.menstrualHistoryList && this.state.menstrualHistoryList.length > 0 ?
                                                            this.state.menstrualHistoryList.map((m, i) => (
                                                                <tr>
                                                                    <td>{m.created_at}</td>
                                                                    <td>{(m.cycle_length ? m.cycle_length : '-')}</td>
                                                                    <td>{(m.duration_of_period) ? m.duration_of_period : '-'} Days</td>
                                                                    <td>{(m.flow) ? m.flow : '-'}</td>
                                                                    <td>{(m.regularity) ? m.regularity : '-'}</td>
                                                                    <td>{(m.pain_score) ? m.pain_score : '-'}</td>
                                                                    <td>{(m.changes_in_cycle) ? m.changes_in_cycle : '-'}</td>
                                                                </tr>
                                                            ))
                                                            : <tr><td colspan="6">No data available</td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                        : null}

                                    {this.state.obsHistoryListStatus ?
                                        <>
                                            <div className='row mt-4 d-flex align-items-center'>
                                                <div className='col-2'>
                                                    <span className='title'>Obstetrics Hx</span>
                                                </div>
                                                {this.state.current_pregnancy_data?.eddDate ?
                                                    <div className='col-3'>
                                                        <span>EDD by LMP:</span><span className='title'> {this.state.current_pregnancy_data?.eddDate ? moment(this.state.current_pregnancy_data?.eddDate).format("D MMM, YYYY") : null}</span>
                                                    </div>
                                                    : null}
                                                {this.state.current_pregnancy_data?.usgDate != null && this.state.current_pregnancy_data?.usgDate != "" && this.state.current_pregnancy_data?.usgDate != undefined ?
                                                    <div className='col-auto'>
                                                        <div className='card obs_card'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <span className='title_red'>EDD by USG:</span><span >{moment(this.state.current_pregnancy_data?.usgDate).format("D MMM, YYYY")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                                {this.state?.current_pregnancy_data?.lactating ?
                                                    <div className='col-2 text-right'>
                                                        <span className='title'>Lactating Mother</span>
                                                    </div>
                                                    : null
                                                }
                                                {this.state?.gpale_values ?
                                                    <div className='col-2 text-right'>
                                                        <span className='title'><GPALE values={this.state?.gpale_values} /></span>
                                                    </div>
                                                    : null
                                                }
                                                <table className="table table-bordered mt-3" >
                                                    <thead>
                                                        <tr>
                                                            <td width="20%">YEAR OF OUTCOME</td>
                                                            <td width="20%">MODE OF CONCEPTION</td>
                                                            <td width="15%">PREGNANCY TYPE</td>
                                                            <td width="15%">DELIVERY TYPE</td>
                                                            <td width="30%">OUTCOME</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.obsHistoryList !== undefined && this.state.obsHistoryList && this.state.obsHistoryList.length > 0 ?
                                                            this.state.obsHistoryList.map((data, i) => (
                                                                <tr>
                                                                    <td>{data.pregnancy_year}</td>
                                                                    <td>{data.mode_text}</td>
                                                                    <td>{data.pregnancy_type_text}</td>
                                                                    <td>{data.delivery_type_text}</td>
                                                                    <td>{data.outcome_text}</td>
                                                                </tr>
                                                            ))
                                                            : <tr><td colspan="6">No data available</td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                        : null}

                                    {this.state.phyExamListStatus || this.state.phyExamFPListStatus ?
                                        <>
                                            <div className='row mt-4'>
                                                <div className='col-12'>
                                                    <span className='title'> Physical Examination</span>
                                                </div>
                                            </div>
                                            {this.state.phyExamListStatus || this.state.phyExamFPListStatus ?
                                                <>
                                                    <div className='row mt-3 '>
                                                        <div className='col-4'>
                                                            <span className='title'>Mother's Parameters</span>
                                                        </div>
                                                        <div className='col-8 text-right'>
                                                            {this.state?.physicalExaminationData?.length > 0 ?
                                                                <span className='title'>Last Updated ({moment(this.state?.physicalExaminationData[0].createdAt).format("D MMM, YYYY")} | {moment(this.state?.physicalExaminationData[0].createdAt).format("h:mm A")})</span>
                                                                : null}
                                                        </div>
                                                        <table className="table table-bordered mt-3" >
                                                            <thead>
                                                                <tr>
                                                                    <td width="20%">PARAMETERS</td>
                                                                    <td width="30%">EDEMA</td>
                                                                    <td width="15%">PALLOR</td>
                                                                    <td width="18%">FUNDAL HEIGHT</td>
                                                                    <td width="17%">REMARKS</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.showPhysicalExamData()}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                                : null}
                                            <div className='row mt-4 '>
                                                <div className='col-4'>
                                                    <span className='title'>Fetal Parameters</span>
                                                </div>
                                                {this.state?.physicalExaminationFPData?.length > 0 ?
                                                    <div className='col-8 text-right'>
                                                        <span className='title'>Last Updated
                                                            ({this.state?.physicalExaminationFPData?.map((data, index) => (index == 0 ? moment(data.createdAt).format("D MMM, YYYY") : ''))} | {this.state?.physicalExaminationFPData?.map((data, index) => (index == 0 ? moment(data.createdAt).format("h:mm A") : ''))})
                                                        </span>
                                                    </div>
                                                    : null
                                                }
                                                <table className="table table-bordered mt-3" >
                                                    <thead>
                                                        <tr>
                                                            <td width="10%">PARAMETERS</td>
                                                            <td width="10%">FETUS NO.</td>
                                                            <td width="10%">FETAL MOVEMENT</td>
                                                            <td width="10%">FHR(PER MINUTE)</td>
                                                            <td width="10%">PRESENTATION</td>
                                                            <td width="10%">RELATION OF PP TO BRIM</td>
                                                            <td width="10%">HEAD CC (CM)</td>
                                                            <td width="10%">ABDOMINAL CC (CM)</td>
                                                            <td width="10%">FEMUR LENGTH(CM)</td>
                                                            <td width="10%">REMARKS</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.showPhysicalExamFPData()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                        : null}

                                    {this.state.vaccineData && this.state.vaccineData.length > 0 ?
                                        <>
                                            <div className='row mt-4 '>
                                                <div className='col-12'>
                                                    <span className='title'>Immunoprophylaxis</span>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                {
                                                    inCImmunoprophylaxis_data?.map((obj, index) => {
                                                        return (
                                                            <div className='col-2 mb-4'>
                                                                <div className='row'>
                                                                    <div className='col-9'>
                                                                        <span>{obj.name}</span>
                                                                    </div>
                                                                    <Form.Item
                                                                        name={['vaccine_status', index]}
                                                                        className='float-right mr-0'
                                                                    >
                                                                        <Checkbox
                                                                            defaultChecked={obj?.date ? true : false}
                                                                            disabled={obj?.date ? true : false}
                                                                            onChange={(e) => this.onCheckVaccine(e, index)}
                                                                        />
                                                                    </Form.Item>
                                                                    <div className="col-11 mt-2">
                                                                        <Form.Item
                                                                            name={['vaccine_date', index]}
                                                                            className='mb-0'
                                                                            id={obj.id}
                                                                            initialValue={obj?.date ? moment(obj.date, "DD MMM, YYYY") : null}
                                                                            rules={[
                                                                                {
                                                                                    required: (vaccine_impressions[index] == 'true' || vaccine_impressions[index] == true) ? true : false,
                                                                                    message: "Date is Required",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <DatePicker
                                                                                format="DD MMM, YYYY"
                                                                                disabled={obj?.date ? true : false}
                                                                                disabledDate={this.disabledPastDate}
                                                                                className='mb-0'
                                                                                style={{
                                                                                    width: 100 + "%",
                                                                                    height: 30 + "px",

                                                                                }}
                                                                            />
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className='col-6 mt-10'>
                                                    <div className="form-group">
                                                        <div className={` antInputItem micwrap hasdata`}>
                                                            <Form.Item name="immuno_remarks" label="Immunoprophylaxis Remarks" id="immuno_remarks"
                                                                onInput={(e) => this.autoHeight(e)}
                                                                rules={[
                                                                    {
                                                                        max: 1000,
                                                                        message: 'Immunoprophylaxis Remarks cannot be longer than 1000 characters',
                                                                    },
                                                                ]}
                                                            >
                                                                <textarea className="form-control diagnosis_notes_class 
                                                        speech-field"
                                                                //defaultValue={this.state.immuno_remarks} 
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : null}

                                    {savetests != undefined && savetests?.length > 0 ?
                                        <div className='row mt-4 '>
                                            <div className='col-12'>
                                                <span className='title'>Lab Tests</span>
                                            </div>
                                            <table className="table table-bordered" >
                                                <thead>
                                                    <tr>
                                                        <td width="15%">DATE</td>
                                                        <td width="19%">TEST NAME</td>
                                                        <td width="41%">RESULT/IMPRESSION</td>
                                                        <td width="15%">REFERENCE RANGE</td>
                                                        <td width="10%">ACTION</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        savetests?.map((obj, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <Form.Item
                                                                            name={['test_newdate', index]}
                                                                            className='mb-0'
                                                                            id={['test_newdate', index]}
                                                                            initialValue={obj.date ? moment(obj.date, "DD MMM, YYYY") : null}
                                                                            rules={[
                                                                                {
                                                                                    required: (typeof lab_tests_impressions[index] != 'undefined' && lab_tests_impressions[index] != '') ? true : false ||
                                                                                        (typeof lab_tests_impressions_refrence[index] != 'undefined' && lab_tests_impressions_refrence[index] != '') ? true : false,
                                                                                    message: "Date is Required",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <DatePicker
                                                                                format="DD MMM, YYYY"
                                                                                disabledDate={this.disabledPastDate}
                                                                                className='mb-0'
                                                                                disabled={(obj.result || obj.refernce || obj.date)}
                                                                                // onChange={(val, props) => this.onSelectChangeList(val, props, ['test_newdate', index])}
                                                                                onChange={(e) => this.setImpressionValues(e, index, "date")}
                                                                            />
                                                                        </Form.Item>

                                                                    </td>
                                                                    <td>
                                                                        {obj?.name ? obj?.name : ""}
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb-0 micwrap ant-mic-wrap ">
                                                                            {/* <Link
                                                                        onClick={(e) => {
                                                                            e.preventDefault(); this.initSpeech({ identifier: `result${index}` });
                                                                        }}
                                                                        className={
                                                                            this.state.speech.identifier === `result${index}` &&
                                                                                this.state.speech.state
                                                                                ? 'mic r-0 on mute' : 'mic r-0'
                                                                        }
                                                                    ></Link> */}
                                                                            <Form.Item name={['newresult', index]} id={['newresult', index]}
                                                                                initialValue={obj?.result ? obj.result : ""}
                                                                                rules={[
                                                                                    {
                                                                                        required: (typeof lab_tests_impressions_refrence[index] != 'undefined' && lab_tests_impressions_refrence[index] != '') ? true : false ||
                                                                                            (typeof lab_tests_impressions_date[index] != 'undefined' && lab_tests_impressions_date[index] != '') ? true : false,
                                                                                        message: "Result is Required",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <textarea className="form-control diagnosis_notes_class speech-field"
                                                                                    onInput={(e) => this.autoHeight(e)}
                                                                                    rows="1"
                                                                                    disabled={(obj.result || obj.refernce || obj.date)}
                                                                                    onChange={(e) => this.setImpressionValues(e, index, "result")}
                                                                                />
                                                                            </Form.Item>
                                                                        </div>

                                                                    </td>
                                                                    <td>
                                                                        <Form.Item name={['newrefernce', index]} id={['newrefernce', index]}
                                                                            initialValue={obj?.refernce ? obj.refernce : null}
                                                                        // rules={[
                                                                        //     {
                                                                        //         required: (typeof lab_tests_impressions[index] != 'undefined' && lab_tests_impressions[index] != '') ? true : false ||
                                                                        //             (typeof lab_tests_impressions_date[index] != 'undefined' && lab_tests_impressions_date[index] != '') ? true : false,
                                                                        //         message: "Refernce is Required",
                                                                        //     },
                                                                        // ]}
                                                                        >
                                                                            <input type="text" className="table-form-control remark" maxLength="20" style={{ height: '41px' }}
                                                                                disabled={(obj.result || obj.refernce || obj.date)}
                                                                                onChange={(e) => this.setImpressionValues(e, index, "refernce")}
                                                                            />
                                                                        </Form.Item>
                                                                    </td>
                                                                    {(obj?.result || obj?.refernce || obj?.date) ?
                                                                        <td>
                                                                            {savetests[index + 1]?.name != obj?.name ?
                                                                                <a onClick={() => this.addNewTest(index)}>  <span class="icon-plus"></span></a>
                                                                                : null}
                                                                        </td>
                                                                        :
                                                                        <td>{obj?.remove == 1 ?
                                                                            <a onClick={() => this.removeNewTest(index)}>  <span class="icon-minus"></span></a>
                                                                            : null}
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        : null}

                                    {this.state?.radiology_test != undefined && this.state.radiology_test?.length > 0 ?
                                        <div className='row mt-4 '>
                                            <div className='col-12'>
                                                <span className='title'>Radiology Tests</span>
                                            </div>
                                            <table className="table table-bordered" >
                                                <thead>
                                                    <tr>
                                                        <td width="15%">DATE</td>
                                                        <td width="19%">TEST NAME</td>
                                                        <td width="41%">RESULT/IMPRESSION</td>
                                                        <td width="15%">REFERENCE RANGE</td>
                                                        <td width="10%">ACTION</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.radiology_test?.map((obj, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <Form.Item
                                                                            name={['radio_test_newdate', index]}
                                                                            className='mb-0'
                                                                            id={['radio_test_newdate', index]}
                                                                            initialValue={obj.date ? moment(obj.date, "DD MMM, YYYY") : null}
                                                                            onChange={(e) => this.setRadioImpressionValues(e, index, "date")}
                                                                            rules={[
                                                                                {
                                                                                    required: (typeof lab_radio_tests_impressions[index] != 'undefined' && lab_radio_tests_impressions[index] != '') ? true : false ||
                                                                                        (typeof lab_radio_tests_impressions_refrence[index] != 'undefined' && lab_radio_tests_impressions_refrence[index] != '') ? true : false,
                                                                                    message: "Date is Required",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <DatePicker
                                                                                format="DD MMM, YYYY"
                                                                                disabledDate={this.disabledPastDate}
                                                                                className='mb-0'
                                                                                disabled={obj.date}
                                                                                onChange={(e) => this.setRadioImpressionValues(e, index, "date")}
                                                                            />
                                                                        </Form.Item>

                                                                    </td>
                                                                    <td>
                                                                        {obj.name}
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb-0 micwrap ant-mic-wrap ">
                                                                            <Form.Item name={['radio_newresult', index]} id={['radio_newresult', index]}
                                                                                initialValue={obj.result ? obj.result : null}
                                                                                rules={[
                                                                                    {
                                                                                        required: (typeof lab_radio_tests_impressions_refrence[index] != 'undefined' && lab_radio_tests_impressions_refrence[index] != '') ? true : false ||
                                                                                            (typeof lab_radio_tests_impressions_date[index] != 'undefined' && lab_radio_tests_impressions_date[index] != '') ? true : false,
                                                                                        message: "Result is Required",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <textarea className="form-control diagnosis_notes_class speech-field"
                                                                                    disabled={obj.date}
                                                                                    onInput={(e) => this.autoHeight(e)}
                                                                                    onChange={(e) => this.setRadioImpressionValues(e, index, "result")}
                                                                                    rows="1" />
                                                                            </Form.Item>
                                                                        </div>

                                                                    </td>
                                                                    <td>
                                                                        <Form.Item name={['radio_newrefernce', index]} id={['radio_newrefernce', index]}
                                                                            initialValue={obj.refernce ? obj.refernce : null}
                                                                        // rules={[
                                                                        //     {
                                                                        //         required: (typeof lab_radio_tests_impressions[index] != 'undefined' && lab_radio_tests_impressions[index] != '') ? true : false ||
                                                                        //             (typeof lab_radio_tests_impressions_date[index] != 'undefined' && lab_radio_tests_impressions_date[index] != '') ? true : false,
                                                                        //         message: "Refernce is Required",
                                                                        //     },
                                                                        // ]}
                                                                        >
                                                                            <input type="text" className="table-form-control remark" maxLength="20" style={{ height: '41px' }}
                                                                                disabled={obj.date} onChange={(e) => this.setRadioImpressionValues(e, index, "refernce")}
                                                                            />
                                                                        </Form.Item>
                                                                    </td>
                                                                    {(obj.result || obj.refernce || obj.date) ?
                                                                        <td>
                                                                            {this.state.radiology_test[index + 1]?.name != obj?.name ?
                                                                                <a onClick={() => this.addNewRadioTest(index)}>  <span class="icon-plus"></span></a>
                                                                                : null}
                                                                        </td>
                                                                        :
                                                                        <td>
                                                                            {obj?.remove == 1 ?
                                                                                <a onClick={() => this.removeRadioNewTest(index)}>  <span class="icon-minus"></span></a>
                                                                                : null}
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        : null}

                                    <div className='row mt-4 '>
                                        <div className='col-12'>
                                            <span className='title'>Delivery Details</span>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-2'>
                                            <div className="antSelectItem hasdata">
                                                <Form.Item
                                                    label="Year"
                                                    name="pregnancy_year_date"
                                                    className="margin-prev"
                                                    rules={[
                                                        {
                                                            required: this.state.pregnancy_year || this.state.pregnancy_type || this.state.birth_weight || this.state.place || this.state.outcome || this.state.delivery_type || this.state.gender || this.state.mode,
                                                            message: "Select Pregnancy Year",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        // style={{ width: 100 + '%', marginBottom: "0px !important" }}
                                                        showSearch
                                                        name="pregnancy_year_date"
                                                        id="pregnancy_year_date"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => {
                                                            return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                        }}

                                                        onChange={(val, props) => this.onSelectChangeList(val, props, 'pregnancy_year_date')}
                                                    >
                                                        {this.state.year_options !== undefined && this.state.year_options.length > 0 ? this.state.year_options.map((d, i) => (
                                                            <Option key={i} value={d} label={d}>{d}</Option>
                                                        )) : null}

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className='col-2'>
                                            <div className="antSelectItem hasdata">
                                                <Form.Item
                                                    label="Mode"
                                                    name="mode"
                                                    rules={[
                                                        {
                                                            required: this.state.pregnancy_year || this.state.pregnancy_type || this.state.birth_weight || this.state.place || this.state.outcome || this.state.delivery_type || this.state.gender || this.state.pregnancy_year_date,
                                                            message: "Select Mode",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        name="mode"
                                                        onChange={(val, props) => this.onSelectChangeList(val, props, 'mode')}
                                                    >
                                                        <Option value="1">
                                                            Natural
                                                        </Option>
                                                        <Option value="2">
                                                            IVF
                                                        </Option>
                                                        <Option value="3">
                                                            IUI
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className='col-2'>
                                            <div className="antSelectItem hasdata">
                                                <Form.Item
                                                    label="Pregnancy Type"
                                                    name="pregnancy_type"
                                                    className="margin-prev"
                                                    rules={[
                                                        {
                                                            required: this.state.pregnancy_year || this.state.place || this.state.mode || this.state.birth_weight || this.state.outcome || this.state.delivery_type || this.state.gender || this.state.pregnancy_year_date,
                                                            message: "Select Pregnancy Type",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        style={{ width: 100 + '%' }}
                                                        showSearch
                                                        name="pregnancy_type"
                                                        id="pregnancy_type"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => {
                                                            return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                        }}
                                                        onChange={(val, props) => this.onSelectChangeList(val, props, 'pregnancy_type')}
                                                    >
                                                        {this.state.pregnancy_options !== undefined && this.state.pregnancy_options.length > 0 ? this.state.pregnancy_options.map((d, i) => (
                                                            <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                                                        )) : null}

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <label class="text-secondary text-underline cursor-pointer" style={{ display: this.state?.multiplePregnancies?.length ? 'block' : 'none' }}>
                                                <button type="button" class="ant-btn nostyle-link text-secondary text-underline float-right pt-1 pr-1" onClick={() => this.setState({ multiplePregnanciesModal: true })}>
                                                    <span>Multiple({this.state?.multiplePregnancies?.length})</span>
                                                </button>
                                            </label>
                                        </div>
                                        {this.state.pregnancy_type != '2' ?
                                            <>
                                                <div className="col-2 ">
                                                    <div className="antSelectItem hasdata">
                                                        <Form.Item
                                                            label="Delivery"
                                                            name="delivery_type"
                                                            rules={[
                                                                {
                                                                    required: this.state.outcome == "1" || this.state.outcome == "2",
                                                                    message: "Select Delivery Type",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                style={{ width: 100 + '%' }}
                                                                showSearch
                                                                name="delivery_type"
                                                                id="delivery_type"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => {
                                                                    return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                                }}
                                                                onChange={(val, props) => this.onSelectChangeList(val, props, 'delivery_type')}
                                                            >
                                                                {this.state.delivery_options !== undefined && this.state.delivery_options.length > 0 ? this.state.delivery_options.map((d, i) => (
                                                                    <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                                                                )) : null}

                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className='col-2'>
                                                    <div className="antSelectItem hasdata">
                                                        <Form.Item
                                                            label="Place"
                                                            name="place"
                                                            rules={[
                                                                {
                                                                    required: this.state.outcome == "1" || this.state.outcome == "2",
                                                                    message: "Select Place",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                style={{ width: '100%' }}
                                                                name="place"
                                                                onChange={(val, props) => this.onSelectChangeList(val, props, 'place')}
                                                            >
                                                                <Option value="1">
                                                                    Hospital
                                                                </Option>
                                                                <Option value="2">
                                                                    Home
                                                                </Option>
                                                                <Option value="3">
                                                                    PHC
                                                                </Option>
                                                                <Option value="4">
                                                                    Others
                                                                </Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className='col-2'>
                                                    <div className="antSelectItem hasdata">
                                                        <Form.Item
                                                            label="Outcome"
                                                            name="outcome"
                                                            rules={[
                                                                {
                                                                    required: this.state.pregnancy_year || this.state.pregnancy_type || this.state.mode || this.state.birth_weight || this.state.place || this.state.delivery_type || this.state.gender || this.state.pregnancy_year_date,
                                                                    message: "Select Outcome",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                style={{ width: '100%' }}
                                                                name="outcome"
                                                                onChange={(val, props) => this.onSelectChangeList(val, props, 'outcome')}
                                                            >
                                                                <Option value="1">
                                                                    Live Term
                                                                </Option>
                                                                <Option value="2">
                                                                    Live Pre-Term
                                                                </Option>
                                                                <Option value="3">
                                                                    Still born Term
                                                                </Option>
                                                                <Option value="4">
                                                                    Still born Pre-Term
                                                                </Option>
                                                                <Option value="5">
                                                                    Spontaneous Abortion
                                                                </Option>
                                                                <Option value="6">
                                                                    MTP
                                                                </Option>

                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                            </>
                                            : null}
                                    </div>

                                    {this.state.pregnancy_type != '2' ?
                                        <>
                                            <div className='row mt-3'>

                                                <div className='col-2'>
                                                    <div className="antSelectItem hasdata">
                                                        <Form.Item
                                                            name="date"
                                                            className='mb-0'
                                                            label="Birth Date & Time"
                                                            rules={[
                                                                {
                                                                    required: this.state.outcome == "1" || this.state.outcome == "2",
                                                                    message: "Birth Date & Time is Required",
                                                                },
                                                            ]}
                                                        >
                                                            <DatePicker showTime
                                                                name="date"
                                                                format="DD MMM, YYYY HH:mm"
                                                                disabledDate={this.disabledPastDate}
                                                                placeholder=''
                                                                className='mb-0'
                                                                style={{
                                                                    width: 100 + "%",
                                                                    height: 41 + "px",
                                                                }}
                                                                onChange={(val, props) => this.onSelectChangeList(val, props, 'pregnancy_year')}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>


                                                <div className="col-2 ">
                                                    <div className="antSelectItem hasdata">
                                                        <Form.Item
                                                            label="Gender"
                                                            name="gender"
                                                            rules={[
                                                                {
                                                                    required: this.state.outcome == "1" || this.state.outcome == "2",
                                                                    message: "Select Gender",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                style={{ width: 100 + '%' }}
                                                                showSearch
                                                                name="gender"
                                                                id="gender"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => {
                                                                    return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                                }}
                                                                onChange={(val, props) => this.onSelectChangeList(val, props, 'gender')}
                                                            >
                                                                {this.state.gender_options !== undefined && this.state.gender_options.length > 0 ? this.state.gender_options.map((d, i) => (
                                                                    <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                                                                )) : null}

                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <div className="antSelectItem hasdata">
                                                            <Form.Item name="birth_weight" label="Birth Weight (kg)" id="birth_weight"
                                                                // onChange={(e) => this.handleChange(e, 'birth_weight')}
                                                                rules={[
                                                                    {
                                                                        required: this.state.outcome == "1" || this.state.outcome == "2",
                                                                        message: "Birth Weight is Required",
                                                                    },
                                                                    { pattern: /^\d{1,2}(\.\d{1,2})?$/, message: 'Not allowed!' },
                                                                ]}
                                                            >
                                                                <Input className=""
                                                                    onChange={(val, props) => this.onSelectChangeList(val, props, 'birth_weight')}
                                                                // disabled={this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.birth_weight} 
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <div className="antSelectItem hasdata">
                                                            <Form.Item
                                                                name="abnormality"
                                                                valuePropName="checked"
                                                                onChange={(e) => this.onSelectCheckChangeListMultiple(e)}
                                                            >
                                                                <Checkbox>
                                                                    Congenital Abnormality
                                                                </Checkbox>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                                {abnormality == true ?
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <div className='antInputItem hasdata' >
                                                                <Form.Item
                                                                    name="abnormality_remark" fieldKey="abnormality_remark" label="Congenital Abnormality Remark"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Congenital Abnormality Remark Required",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <textarea
                                                                        className="form-control diagnosis_notes_class speech-field"
                                                                    //initialvalues={data.abnormality_remark} 
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                        </>
                                        : null}

                                    <div className='row mt-3'>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div className={`form-group antInputItem micwrap hasdata`}>
                                                    <Link
                                                        onClick={(e) => {
                                                            e.preventDefault(); this.initSpeech({ identifier: "indication_csection" });
                                                        }}
                                                        className={
                                                            this.state.speech.identifier === "indication_csection" &&
                                                                this.state.speech.state
                                                                ? 'mic r-0 on mute' : 'mic r-0'
                                                        }
                                                    ></Link>
                                                    <Form.Item name="indication_csection" label="Indication for C-Section" id="indication_csection"
                                                        onInput={(e) => this.autoHeight(e)}
                                                    >
                                                        <textarea className="form-control diagnosis_notes_class speech-field"
                                                        //  defaultValue={this.state.indication_csection}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-6'>
                                            <div className="form-group">
                                                <div className={`form-group antInputItem micwrap hasdata`}>
                                                    <a
                                                        onClick={(e) => {
                                                            e.preventDefault(); this.initSpeech({ identifier: "details" });
                                                        }}
                                                        className={
                                                            this.state.speech.identifier === "details" &&
                                                                this.state.speech.state
                                                                ? 'mic r-0 on mute' : 'mic r-0'
                                                        }
                                                    ></a>
                                                    <Form.Item name="details" label="Details" id="details"
                                                        onInput={(e) => this.autoHeight(e)}
                                                    >
                                                        <textarea className="form-control diagnosis_notes_class speech-field"
                                                        //  defaultValue={this.state.details}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-4 mb-5'>
                                        <div className='col-4'>
                                            <Checkbox
                                                name="confidential"
                                                className='pr-4'
                                                onChange={(e) => this.poupulatedField(e)}
                                            >
                                                Print only Populated fields
                                            </Checkbox>
                                        </div>
                                        <div className='col-8 text-left'>

                                            <Form.Item>
                                                <button onClick={() => this.submitForm("preview")} htmlType="submit" className="btn btn-outline-primary px-5 mr-3">PREVIEW </button>
                                                <button htmlType="submit" className="btn btn-primary px-5 ml-3" disabled={this.state.spin_loading}>SAVE {this.state.spin_loading ? LOADER : null}</button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </> : null}
                    </div>
                </div >

                <Modal
                    visible={this.state.previewListModal}
                    onCancel={() => this.handlePreviewCancel()}
                    footer={false}
                    width="1080px"
                    className='previewListModal'
                >
                    <div className="container-fluid emrfrm anc-container mb-4 ">
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <div className="float-left">
                                    <h1 className="page-title mb-0">ANC Card</h1>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card anc-cardgrey'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <span className='heading'>Patient Name :&nbsp;</span>
                                            {(patientInfo) ?
                                                <span className='patient_name'>{patientInfo?.first_name !== undefined ? patientInfo?.first_name : null}&nbsp;{patientInfo?.middle_name !== undefined ? patientInfo?.middle_name : null}&nbsp;{patientInfo?.last_name !== undefined ? patientInfo?.last_name : null}, {patientInfo?.dob && patientInfo?.age !== null ? this.getPatientAge(patientInfo?.dob) : null}, {patientInfo?.gender !== undefined && patientInfo?.gender !== null ? (patientInfo?.gender == 'Male' ? ' (M)' : ' (F)') : null}</span>
                                                : null}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <span className='heading'>Address :&nbsp;</span>
                                            {(patientInfo) ?
                                                <span className='patient_name'>{patientInfo?.address_1 !== undefined ? patientInfo?.address_1 : null}, {patientInfo?.city !== undefined ? patientInfo?.city : null},   {patientInfo?.state !== undefined ? patientInfo?.state : null}</span>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row mt-4'>
                            {(this.state.poupulated_Field == true && this.state.risk_factors.length == 0) ?
                                null
                                :
                                <div className='col-6' >
                                    <span style={{ color: "#FF0000" }}>Risk Factors</span>
                                </div>
                            }

                            {(this.state.poupulated_Field == true && previewparams?.risk_factors_management == null) ?
                                null
                                :
                                <div className='col-6'>
                                    <span style={{ color: "#FF0000" }}>Risk Factors Management</span>
                                </div>
                            }
                        </div>
                        <div className='row '>
                            {(this.state.poupulated_Field == true && this.state.risk_factors.length == 0) ?
                                null
                                :
                                <div className='col-6'>
                                    <table>
                                        {
                                            this.state.risk_factors_options?.map(obj => {
                                                return this.state.risk_factors?.map(
                                                    item => {
                                                        if (obj.id == item) {
                                                            return (
                                                                <tr>{obj.description}</tr>
                                                            )
                                                        }
                                                    }
                                                )
                                            })
                                        }
                                    </table>
                                </div>
                            }
                            <div className='col-6'>
                                <span>{previewparams?.risk_factors_management}</span>
                            </div>
                        </div>

                        {this.state.allergies && this.state.poupulated_Field != true || (this.state?.allergies?.length > 0 && this.state.poupulated_Field == true) ?
                            <>
                                <div className='row mt-4'>
                                    <div className='col-12'>
                                        <span className='title'> Allergies</span>
                                    </div>
                                </div>
                                <table className="table table-bordered mt-3" >
                                    <thead>
                                        <tr>
                                            <td width="28%">ALLERGY NAME</td>
                                            <td width="22%">REACTION</td>
                                            <td width="15%">DURATION</td>
                                            <td width="15%">INTENSITY</td>
                                            <td width="15%">INFORMED BY</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.allergies.length > 0 ?
                                            this.state.allergies.map((obj) => (
                                                <tr>
                                                    <td>{obj.allergy_name}</td>
                                                    <td>{obj.reaction ? obj.reaction : '-'}</td>
                                                    <td>{obj.duration ? obj.duration + ' ' + DURATION_TYPE[obj.durationType] : '-'}</td>
                                                    <td>{obj.intensity === 0 ? "Low" : obj.intensity === 1 ? "Medium" : obj.intensity === 2 ? "High" : ''}</td>
                                                    <td>{obj?.informedBy == 0 || obj?.informedBy == 1 ? INFORMED_BY[obj?.informedBy] : "-"}</td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan="11">No data available</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </>
                            : null}


                        {this.state?.conditions && this.state?.poupulated_Field != true || (this.state?.conditions?.length > 0 && this.state?.poupulated_Field == true) ?
                            <>
                                <div className='row mt-4'>
                                    <div className='col-12'>
                                        <span className='title'>Existing Condition</span>
                                    </div>
                                </div>

                                <table className="table table-bordered mt-3" >
                                    <thead>
                                        <tr>
                                            <td width="30%">CONDITIONS</td>
                                            <td width="20%">DURATION</td>
                                            <td width="20%">ON MEDICATION</td>
                                            <td width="30%">MEDICINE NAME</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state?.conditions?.length > 0 ?
                                            this.state.conditions.map((c, i) => (
                                                <tr>
                                                    <td>{c.condition_description}</td>
                                                    <td>{(c.duration) ? `${c.duration} ${DURATION_TYPE[c.durationType]}` : '-'}</td>
                                                    <td>{MEDICATION_ON[c.onMedication] ? MEDICATION_ON[c.onMedication] : '-'}</td>
                                                    <td>{c.medicineName || "NA"}</td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan="11">No data available</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </>
                            : null}

                        {this.state?.menstrualHistoryList && this.state?.poupulated_Field != true || (this.state?.menstrualHistoryList?.length > 0 && this.state?.poupulated_Field == true) ?
                            <>
                                <div className='row mt-4'>
                                    <div className='col-3'>
                                        <span className='title'>Menstrual Hx</span>
                                    </div>
                                    {(patientDetails && patientDetails.lmp_date) ?
                                        <div className='col-4 text-center'>
                                            <span>LMP:</span>  <span className='title'>{patientDetails.lmp_date}</span>
                                        </div>
                                        : null}
                                    {this.state?.menstrualHistoryList[0]?.created_at ?
                                        <div className='col-5 text-right'>
                                            <span className='title'>Last Updated
                                                ({moment(this.state?.menstrualHistoryList[0]?.created_at).format("D MMM, YYYY")} | {
                                                    this.state?.menstrualHistoryList[0]?.created_at_time}
                                                )</span>
                                        </div>
                                        : null}
                                </div>

                                <table className="table table-bordered mt-3" >
                                    <thead>
                                        <tr>
                                            <td width="10%">DATE</td>
                                            <td width="20%">CYCLE LENGTH</td>
                                            <td width="20%">DURATION</td>
                                            <td width="10%">FLOW</td>
                                            <td width="10%">REGULARITY</td>
                                            <td width="10%">PAIN</td>
                                            <td width="20%">CHANGES IN CYCLE</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.menstrualHistoryList !== undefined && this.state.menstrualHistoryList && this.state.menstrualHistoryList.length > 0 ?
                                            this.state.menstrualHistoryList.map((m, i) => (
                                                <tr>
                                                    <td>{m.created_at}</td>
                                                    <td>{(m.cycle_length ? m.cycle_length : '-')}</td>
                                                    <td>{(m.duration_of_period) ? m.duration_of_period : '-'} Days</td>
                                                    <td>{(m.flow) ? m.flow : '-'}</td>
                                                    <td>{(m.regularity) ? m.regularity : '-'}</td>
                                                    <td>{(m.pain_score) ? m.pain_score : '-'}</td>
                                                    <td>{(m.changes_in_cycle) ? m.changes_in_cycle : '-'}</td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan="11">No data available</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </>
                            : null}

                        {this.state?.obsHistoryList && this.state?.poupulated_Field != true || (this.state?.obsHistoryList?.length > 0 && this.state?.poupulated_Field == true) ?
                            <>
                                <div className='row mt-4 d-flex align-items-center'>
                                    <div className='col-2'>
                                        <span className='title'>Obstetrics Hx</span>
                                    </div>
                                    {this.state.current_pregnancy_data?.eddDate ?
                                        <div className='col-3'>
                                            <span>EDD by LMP:</span><span className='title'> {this.state.current_pregnancy_data?.eddDate ? moment(this.state.current_pregnancy_data?.eddDate).format("D MMM, YYYY") : null}</span>
                                        </div>
                                        : null}
                                    {this.state.current_pregnancy_data?.usgDate != null && this.state.current_pregnancy_data?.usgDate != "" && this.state.current_pregnancy_data?.usgDate != undefined ?
                                        <div className='col-auto'>
                                            <div className='card obs_card'>
                                                <div className='row'>
                                                    {this.state.current_pregnancy_data?.usgDate ? <>
                                                        <span className='title_red'>EDD by USG:</span><span >{moment(this.state.current_pregnancy_data?.usgDate).format("D MMM, YYYY")}</span>
                                                    </>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    <div className='col-3 text-right'>
                                        {this.state?.current_pregnancy_data?.lactating ?
                                            <span className='title'>Lactating Mother</span>
                                            : null}
                                    </div>
                                    <div className='col text-right'>
                                        <span className='title'><GPALE values={this.state?.gpale_values} /></span>
                                    </div>
                                </div>

                                <table className="table table-bordered mt-3" >
                                    <thead>
                                        <tr>
                                            <td width="20%">YEAR OF OUTCOME</td>
                                            <td width="20%">MODE OF CONCEPTION</td>
                                            <td width="15%">PREGNANCY TYPE</td>
                                            <td width="15%">DELIVERY TYPE</td>
                                            <td width="30%">OUTCOME</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.obsHistoryList !== undefined && this.state.obsHistoryList && this.state.obsHistoryList.length > 0 ?
                                            this.state.obsHistoryList.map((data, i) => (
                                                <tr>
                                                    <td>{data.pregnancy_year}</td>
                                                    <td>{data.mode_text}</td>
                                                    <td>{data.pregnancy_type_text}</td>
                                                    <td>{data.delivery_type_text}</td>
                                                    <td>{data.outcome_text}</td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan="11">No data available</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </>
                            : null}

                        {this.state?.poupulated_Field != true || (this.state?.physicalExaminationData?.length > 0 && this.state?.poupulated_Field == true) ?
                            <>
                                <div className='row mt-4'>
                                    <div className='col-12'>
                                        <span className='title'> Physical Examination</span>
                                    </div>
                                </div>

                                {this.state.phyExamListStatus || this.state.phyExamFPListStatus || this.state.physicalExaminationData ?
                                    <>
                                        <div className='row mt-3 '>
                                            <div className='col-4'>
                                                <span className='title'>Mother's Parameters</span>
                                            </div>
                                            <div className='col-8 text-right'>
                                                {this.state?.physicalExaminationData?.map((data, index) => (index == 0)) ?
                                                    <span className='title'>Last Updated ({this.state?.physicalExaminationData?.map((data, index) => (index == 0 ? moment(data.createdAt).format("D MMM, YYYY") : ''))} | {this.state?.physicalExaminationData?.map((data, index) => (index == 0 ? moment(data.createdAt).format("h:mm A") : ''))})</span>
                                                    : null}
                                            </div>
                                        </div>
                                        <table className="table table-bordered mt-3" >
                                            <thead>
                                                <tr>
                                                    <td width="20%">PARAMETERS</td>
                                                    <td width="30%">EDEMA</td>
                                                    <td width="15%">PALLOR</td>
                                                    <td width="18%">FUNDAL HEIGHT</td>
                                                    <td width="17%">REMARKS</td>
                                                </tr>
                                            </thead>
                                            {this.showPhysicalExamData()}
                                        </table>
                                    </>
                                    : null}
                            </>
                            : null}

                        {this.state?.poupulated_Field != true || (this.state?.total_count_fetal != 0 && this.state?.poupulated_Field == true) ?
                            <>

                                <div className='row mt-4 '>
                                    <div className='col-4'>
                                        <span className='title'>Fetal Parameters</span>
                                    </div>
                                    <div className='col-8 text-right'>
                                        {this.state?.physicalExaminationFPData?.map((data, index) => (index == 0)) ?
                                            <span className='title'>Last Updated({this.state?.physicalExaminationFPData?.map((data, index) => (index == 0 ? moment(data.updatedAt).format("D MMM, YYYY") : ''))} | {this.state?.physicalExaminationFPData?.map((data, index) => (index == 0 ? moment(data.updatedAt).format("h:mm A") : ''))})</span>
                                            : null}
                                    </div>
                                </div>
                                <table className="table table-bordered mt-3" >
                                    <thead>
                                        <tr>
                                            <td width="10%">PARAMETERS</td>
                                            <td width="10%">FETUS NO.</td>
                                            <td width="10%">FETAL MOVEMENT</td>
                                            <td width="10%">FHR(PER MINUTE)</td>
                                            <td width="10%">PRESENTATION</td>
                                            <td width="10%">RELATION OF PP TO BRIM</td>
                                            <td width="10%">HEAD CC (CM)</td>
                                            <td width="10%">ABDOMINAL CC (CM)</td>
                                            <td width="10%">FEMUR LENGTH(CM)</td>
                                            <td width="10%">REMARKS</td>
                                        </tr>
                                    </thead>
                                    {this.showPhysicalExamFPData()}
                                </table>
                            </> : null}


                        {/* {(this.state?.vaccine_populated || this.state?.vaccine_previous) && this.state?.poupulated_Field != true || this.state?.vaccine_populated.length > 0 && this.state?.poupulated_Field == true ?
                            <>
                                <div className='row mt-4 '>
                                    <div className='col-12'>
                                        <span className='title'>Immunoprophylaxis</span>
                                    </div>
                                </div>

                                <div className='row mt-3'>
                                    {this.state.poupulated_Field ?
                                        this.state?.vaccine_populated.map(obj => {
                                            return (
                                                <div className='col-3'>
                                                    <span>{obj.name}</span>
                                                    <b>: {obj.date ? obj.date : "  -"}</b>
                                                </div>
                                            )
                                        }) :
                                        this.state?.vaccine_previous.map(obj => {
                                            return (
                                                <div className='col-3'>
                                                    <span>{obj.name}</span>
                                                    <b>: {obj.date ? obj.date : "  -"}</b>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </> : null}

                        {(this.state.poupulated_Field == true && previewparams?.immuno_remarks == null) ?
                            null
                            :
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <label htmlFor="immuno_remarks">Immunoprophylaxis Remarks: </label>
                                    <b> {previewparams?.immuno_remarks}</b>
                                </div>
                            </div>
                        } */}

                        {/* {
                            (this.state?.poupulated_Field || this.state?.test_previous) && this.state?.poupulated_Field != true || (this.state?.test_populated.length > 0 && this.state?.poupulated_Field == true) ?
                                <>
                                    <div className='row mt-4 '>
                                        <div className='col-12'>
                                            <span className='title'>Lab Tests</span>
                                        </div>
                                    </div>

                                    <table className="table table-bordered mt-3" >

                                        <thead>
                                            <tr>
                                                <td width="17%">DATE</td>
                                                <td width="20%">TEST NAME</td>
                                                <td width="46%">RESULT/IMPRESSION</td>
                                                <td width="17%">REFERENCE RANGE</td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                this.state.poupulated_Field == true ?
                                                    this.state?.test_populated?.map(obj => {
                                                        return (
                                                            <tr>
                                                                <td> <span> {obj.date ? obj.date : "-"}</span>  </td>
                                                                <td> <span> {obj.name ? obj.name : "-"}</span>  </td>
                                                                <td> <span> {obj.result ? obj.result : "-"}</span>  </td>
                                                                <td>  <span style={{ height: '41px' }}> {obj.refernce ? obj.refernce : "-"}</span> </td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    this.state?.test_previous.map(obj => {
                                                        return (
                                                            <tr>
                                                                <td> <span> {obj.date ? obj.date : "-"}</span>  </td>
                                                                <td> <span> {obj.name ? obj.name : "-"}</span> </td>
                                                                <td> <span> {obj.result ? obj.result : "-"}</span> </td>
                                                                <td> <span style={{ height: '41px' }}> {obj.refernce ? obj.refernce : "-"}</span> </td>
                                                            </tr>
                                                        )
                                                    })
                                            }
                                        </tbody>
                                    </table>
                                </>
                                : null
                        }

                        {
                            (this.state?.poupulated_Field || this.state?.radio_test_previous) && this.state?.poupulated_Field != true || (this.state?.radio_test_populated.length > 0 && this.state?.poupulated_Field == true) ?
                                <>
                                    <div className='row mt-4 '>
                                        <div className='col-12'>
                                            <span className='title'>Radiology Tests</span>
                                        </div>
                                    </div>

                                    <table className="table table-bordered mt-3" >
                                        <thead>
                                            <tr>
                                                <td width="17%">DATE</td>
                                                <td width="20%">TEST NAME</td>
                                                <td width="46%">RESULT/IMPRESSION</td>
                                                <td width="17%">REFERENCE RANGE</td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                this.state.poupulated_Field == true ?
                                                    this.state?.radio_test_populated?.map(obj => {
                                                        return (
                                                            <tr className="mt-0">
                                                                <td> <span> {obj.date ? obj.date : "-"}</span>  </td>
                                                                <td> <span> {obj.name ? obj.name : "-"}</span>  </td>
                                                                <td> <span> {obj.result ? obj.result : "-"}</span>  </td>
                                                                <td>  <span style={{ height: '41px' }}> {obj.refernce ? obj.refernce : "-"}</span> </td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    this.state?.radio_test_previous?.map(obj => {
                                                        return (
                                                            <tr>
                                                                <td> <span> {obj.date ? obj.date : "-"}</span>  </td>
                                                                <td> <span> {obj.name ? obj.name : "-"}</span> </td>
                                                                <td> <span> {obj.result ? obj.result : "-"}</span> </td>
                                                                <td> <span style={{ height: '41px' }}> {obj.refernce ? obj.refernce : "-"}</span> </td>
                                                            </tr>
                                                        )
                                                    })
                                            }
                                        </tbody>
                                    </table>
                                </>
                                : null
                        } */}
                        {
                            (this.state.poupulated_Field == true && previewparams?.delivery_dateChange == null && previewparams?.delivery_place == null && previewparams?.delivery_mode == null && previewparams?.indication_csection == null && previewparams?.details == null && previewparams?.delivery_outcome == null) ?
                                null
                                :
                                <div className='row mt-4 '>
                                    <div className='col-12'>
                                        <span className='title'>Delivery Details</span>
                                    </div>
                                </div>
                        }
                        <div className='row mt-3'>
                            {/* {(this.state.poupulated_Field == true && previewparams?.delivery_dateChange == null) ?
                                null
                                :
                                <div className='col-4'>
                                    <span>Date & Time:</span>
                                    <b> {previewparams?.delivery_dateChange ? moment(previewparams.delivery_dateChange).format("DD MMM, YYYY HH:mm") : null}</b>
                                </div>
                            } */}
                            {(this.state.poupulated_Field == true && previewparams?.delivery_mode == null) ?
                                null
                                :
                                <div className='col-4'>
                                    <span>Mode:</span>
                                    <b> {previewparams?.delivery_mode === "1" ? "Natural" :
                                        previewparams?.delivery_mode === "2" ? "IVF" :
                                            previewparams?.delivery_mode === "3" ? "IUI" : ''}</b>
                                </div>
                            }

                            {(this.state.poupulated_Field == true && previewparams?.pregnancy_type == null) ?
                                null
                                :
                                <div className='col-4'>
                                    <span>Pregnancy Type:</span>
                                    <b> {previewparams?.pregnancy_type == "1" ? "Single" :
                                        previewparams?.pregnancy_type == "2" ? "Multiple" :
                                            previewparams?.pregnancy_type == "3" ? "Ectopic" : ''}</b>
                                </div>
                            }

                        </div>
                        {previewparams?.outcome_group?.length > 0 ?
                            <div className='row mt-3'>
                                <div className='col-12'>
                                    <span>Outcome:</span>
                                    {previewparams?.outcome_group?.map(obj => {
                                        return (
                                            <div className='row mt-2  ml-2'>
                                                <b> <span> {obj.outcome == "1" ? "Live Term" :
                                                    obj.outcome == "2" ? "Live Pre-Term" :
                                                        obj.outcome == "3" ? "Still born Term" :
                                                            obj.outcome == "4" ? "Still born Pre-Term" :
                                                                obj.outcome == "5" ? "Spontaneous Abortion" :
                                                                    obj.outcome == "6" ? "MTP" : '-'} ,</span> </b> &nbsp;
                                                {obj?.delivery_type ? <> <b><span>{obj.delivery_type == "1" ? "Normal" :
                                                    obj.delivery_type == "2" ? "LSCS" :
                                                        obj.delivery_type == "3" ? "Forceps" :
                                                            obj.delivery_type == "4" ? "Water Birthing" : '-'} ,</span> </b> &nbsp;  </> : null}
                                                {obj?.birth_date_time ? <b><span>Birth Date & Time:- </span> {moment(obj?.birth_date_time).format("DD MMM, YYYY HH:mm")} , </b> : null}
                                                {obj?.gender ? <> <b> <span> {obj.gender == "1" ? "Male" :
                                                    obj.gender == "2" ? "Female" :
                                                        obj.gender == "3" ? "Transgender" : '-'} ,</span> </b> &nbsp;  </> : null}
                                                {obj?.birth_weight ? <> <span>Brith wt<b> {obj.birth_weight ? obj.birth_weight : "-"}</b> kg</span> , &nbsp;  </> : null}
                                                {obj?.place_of_delivery ? <> <b><span>{obj.place_of_delivery === "1" ? "Hospital" :
                                                    obj.place_of_delivery === "2" ? "Home" :
                                                        obj.place_of_delivery === "3" ? "PHC" :
                                                            obj.place_of_delivery === "4" ? "Others" : '-'} ,</span> </b> &nbsp;  </> : null}
                                                <b><span>Congenital Abnormality:- </span> {obj?.abnormality == true ? "Yes" : "No"} , </b> &nbsp;
                                                {obj?.abnormality == true ?
                                                    <b><span>Congenital Abnormality Remark:- </span> {obj?.abnormality_remark} </b>
                                                    : null}
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            : null}

                        {previewparams?.outcome_group?.length > 0 ?
                            <div className='row mt-3'>
                                <div className='col-6'>

                                </div>
                            </div>
                            : null}

                        <div className='row mt-3'>
                            {(this.state.poupulated_Field == true && previewparams?.indication_csection == null) ?
                                null
                                :
                                <div className='col-6'>
                                    <span>Indication for C-Section:</span>
                                    <b> {previewparams?.indication_csection}</b>
                                </div>
                            }

                            {(this.state.poupulated_Field == true && previewparams?.details == null) ?
                                null
                                :
                                <div className='col-6'>
                                    <span>Details:</span>
                                    <b> {previewparams?.details}</b>
                                </div>
                            }
                        </div>

                        <div className='row mt-4'>
                            <div className='col-12 text-center'>
                                <ReactToPrint
                                    pageStyle={this.getPageMargins()}
                                    trigger={() => (
                                        <a className="btn btn-primary px-3 small text-uppercase ml-2" href="#">
                                            PRINT
                                        </a>
                                    )}
                                    content={() => this.componentRef}
                                />
                                <div style={{ overflow: 'hidden', height: '0' }}>
                                    < PrintPreviewAnc
                                        ref={(el) => (this.componentRef = el)}
                                        previewparams={previewparams}
                                        poupulatedFieldallow={this.state.poupulated_Field}
                                        patientInfo={patientInfo}
                                        risk_factors={this.state.risk_factors}
                                        risk_factors_options={this.state.risk_factors_options}
                                        allergies={this.state.allergies}
                                        conditions={this.state.conditions}
                                        menstrualHistoryList={this.state.menstrualHistoryList}
                                        patientDetails={patientDetails}
                                        physicalExaminationData={this.state.physicalExaminationData}
                                        menstrualHistoryListStatus={this.state.menstrualHistoryListStatus}
                                        obsHistoryListStatus={this.state.obsHistoryListStatus}
                                        gpale_values={this.state.gpale_values}
                                        obsHistoryList={this.state.obsHistoryList}
                                        phyExamListStatus={this.state.phyExamListStatus}
                                        phyExamFPListStatus={this.state.phyExamFPListStatus}
                                        physicalExaminationFPData={this.state.physicalExaminationFPData}
                                        test_previous={this.state?.test_previous}
                                        test_populated={this.state?.test_populated}
                                        radio_test_populated={this.state?.radio_test_populated}
                                        radio_test_previous={this.state?.radio_test_previous}
                                        vaccine_previous={this.state?.vaccine_previous}
                                        vaccine_populated={this.state?.vaccine_populated}
                                        current_pregnancy_data={this.state.current_pregnancy_data}
                                        total_count_fetal={this.state.total_count_fetal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal >

                {/* Modal For Multiple Pregnancies */}
                <Modal
                    // title={"Previous Births - Pregnancy Type (Multiple" + (this.state.pregnancy_year ? ", " + this.state.pregnancy_year : "") + ")"}
                    destroyOnClose={true}
                    visible={this.state.multiplePregnanciesModal}
                    onCancel={(e) =>
                        this.handleModalPopup(e, "multiplePregnanciesModal")
                    }
                    width="1800px"
                    bodyStyle={{ paddingBottom: "15px" }}
                    className="emrfrm"
                    footer={false}
                >
                    {this.state.multiplePregnanciesModal ?
                        <MultiplePregnancies
                            pregnancies={this.state.multiplePregnancies}
                            indication_reason={this.state.indication_reason_multiple}
                            handleModalSave={this.handleModalSave}
                        />
                        : null}
                </Modal>

            </>
        )
    }
}

