import React, { Component } from 'react';
import Axios from 'axios';

import {
  LOADER_RED, 
  VERIFY_TOKEN,
  SYNC_TOKEN,
  IPD_SLOT_DETAILS,
  USERTYPES
} from '../../utils/constant';
import qs from 'qs';
import { notification } from 'antd';

import LOGO from '../../assets/images/logo-myhealthcare-doctor.svg';
import LOGIN_BACKGROUND from '../../assets/images/ic-loginimage.png';
import LS_SERVICE from '../../utils/localStorage';
import { headerService$, vcOpdEnabledSubjectService } from '../../utils/rxjs-sharing';

class AutoLoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      syncTokenProcessing: false,
      staff_id: null,
      staff_name: '',
      status: null,
      token: '',
    }
  }

  componentDidMount() { 
    const { match: { params } } = this.props;
    const { auth_token, store_id, facility_id, enterprise_id, enterprise_name, status_id, timezone_offset_minutes, facility_name, his_auth_token, user_id } = params;
    Axios.get(VERIFY_TOKEN, {
      headers: {
        'token': auth_token
      }
    }).then(success => {
      let { staff_id,
        staff_name,
        status,
        token,
        user_type } = success.data;
    if (status) {
        this.setState({
            staff_id,
            facility_id,
            staff_name,
            token
        })
        var enterprise_name_atob = enterprise_name ? window.atob(enterprise_name) : '';
        var facility_name_atob = facility_name ? window.atob(facility_name) : '';
        var his_auth_token_value = his_auth_token ? his_auth_token : '';
        var user_id_value = user_id ? user_id : '0';
        LS_SERVICE.set('token', token);
        LS_SERVICE.set('staff_name', staff_name);
        LS_SERVICE.set('staff_id', staff_id);
        LS_SERVICE.set('is_authenticated', true);
        LS_SERVICE.set('user_type', user_type);
        LS_SERVICE.set('STORE_ID', +store_id);
        LS_SERVICE.set('FACILITY_ID', +facility_id);
        LS_SERVICE.set('FACILITY_NAME', facility_name_atob);
        LS_SERVICE.set('ENTERPRISE_ID', +enterprise_id);
        LS_SERVICE.set('ENTERPRISE_NAME', enterprise_name_atob);
        LS_SERVICE.set('STATUS_ID', JSON.parse(status_id));
        LS_SERVICE.set('TIMEZONE_OFFSET_MINUTES', timezone_offset_minutes);
        LS_SERVICE.set('HIS_TOKEN', his_auth_token_value);
        LS_SERVICE.set('hisuserid', user_id_value);
        this.handleSyncToken(staff_id);
    }
    }).catch(err => {
      let error = err.response;
      if (error?.data?.message) {
        notification.error({
          message: error.data.message,
          placement: "topRight"
        })
      }
    })

  }

  handleSyncToken = () => {
    const { staff_id, facility_id } = this.state;

    this.setState({
        syncTokenProcessing: true
    })

    Axios.post(SYNC_TOKEN, qs.stringify({ staff_id }))
        .then(success => {
            const result = success.data;
            if (result.status) {
                LS_SERVICE.set('staff_image', result.staff.profile_picture);
                LS_SERVICE.set('is_authenticated', true);
                // debugger;
                if (result.staff.role_id == USERTYPES.doctor) {
                    LS_SERVICE.set('doctor', {
                        name: ('Dr ' + result.staff.first_name + (result.staff.last_name != null ? result.staff.last_name : '')),
                        code: result.staff.code
                    });
                    if (result.slots && result.slots.length > 0) {
                        LS_SERVICE.set(
                            'slot_hospital_id',
                            facility_id
                        );
                        LS_SERVICE.set(
                            'slot_hospital_master',
                            result.slots[0]['own_master']
                        );
                        LS_SERVICE.set('slot_hospitals', result.slots);
                    } else {
                        LS_SERVICE.delete('slot_hospital_id');
                    }
                    if (result.pending_patients && result.pending_patients.length > 0) {
                        LS_SERVICE.set(
                            'pending_patients',
                            result.pending_patients
                        );
                    } else {
                        LS_SERVICE.delete('pending_patients');
                    }
                    if (result.staff.doctor_speciality != undefined && result.staff.doctor_speciality.length > 0) {
                        LS_SERVICE.set('speciality_id', result.staff.doctor_speciality[0]);
                    } else {
                        LS_SERVICE.delete('speciality_id');
                    }
                    if (result.slots && result.slots.length == 1) {
                        this.props.history.replace('/patient-queue/' + facility_id);
                        //this.props.history.push('/patient-queue/' + result.slots[0].id);
                    } else {
                      this.props.history.replace('/patient-queue/' + facility_id);
                        // this.props.history.replace('/dashboard');
                    }
                }

                if (result.staff.role_id == USERTYPES.nurse) {
                    // set selected nurse doctor ids
                    //let nurse_doctors = [];
                    // result.doctor.map((doctor_id) => {
                    //   nurse_doctors.push(doctor_id);
                    // })
                    if (result.nurse_doctors?.length > 0) {
                      LS_SERVICE.set('nurse_doctor_id', result.nurse_doctors);
                    }

                    //Check if nurse logged in and set doctor speciality
                    if (result.doctor && result.doctor.length > 0) {
                      let allDoctors = result.doctor;
                      var doctorSpecialties = [];
                      allDoctors.forEach((v, k) => {
                        var obj = {
                          "id": v.id,
                          "specialtiesIds": Object.keys(v.specialityDetail)
                        };
                        doctorSpecialties.push(obj);
                      });
                      LS_SERVICE.set('doctor_speciality_id', doctorSpecialties);
                    }

                    if (result.hospital && result.hospital.length > 0) {
                        LS_SERVICE.set('slot_hospitals', result.hospital);
                        LS_SERVICE.set(
                            'slot_hospital_id',
                            result.hospital[0]['hospital_id']
                        );
                        LS_SERVICE.set(
                            'slot_hospital_master',
                            result.hospital[0]['own_master']
                        );
                    } else {
                        LS_SERVICE.delete('slot_hospital_id');
                    }
                    if (result.doctor && result.doctor.length > 0) {
                        LS_SERVICE.set('nurse_doctors', result.doctor);
                    } else {
                      LS_SERVICE.set('nurse_doctors', []);
                      LS_SERVICE.set('nurse_homecare', true)
                    }
                    LS_SERVICE.set('nurse_doctors', result.doctor);
                    const isVcopdEnabled = result.is_vc_opd_enabled ? result.is_vc_opd_enabled : 0;
                    LS_SERVICE.set('nurse_is_vc_opd_enabled', isVcopdEnabled);
                    vcOpdEnabledSubjectService.init({
                        isVcOpdEnabled: isVcopdEnabled
                    });
                    this.props.history.replace('/patient-queue/' + result.hospital[0]['hospital_id']);
                    //this.props.history.push('/patient-queue/' + result.hospital[0]['hospital_id']);
                }

                headerService$.showHeader({ showHeader: 'true' });
            }
        })
        .catch(err => {
            console.log(err);
        })

    Axios.post(IPD_SLOT_DETAILS,{
      doctorId: parseInt(LS_SERVICE.get('staff_id')),
      timeZoneMinute: parseInt(LS_SERVICE.get('TIMEZONE_OFFSET_MINUTES')),
      statusId: LS_SERVICE.get('STATUS_ID')
    })
    .then(success => {
        const result = success.data;
        LS_SERVICE.set('ipd_slots', result?.data?.result?.slots || []);
    })
    .catch(err => {
        console.log(err);
    })
}

  render() {

    const { syncTokenProcessing } = this.state;

    return (
      <div className="container-fluid">
        <div className="full-height">
          <div className="row">
            <div className="col-md-12 align-self-center">
            <div className="row justify-content-center">
                <div className="col-12 col-md-8 login-form-container">
                  <div className="center w-100 d-flex justify-content-center">
                    <div style={{ width: '400px', height: '67vh', }} className="text-center">
                      <div style={{ 'margin-top': '300px' }}><img src="https://myhealthcare-his.s3.ap-south-1.amazonaws.com/Test/Assets/images/loader.gif" alt="" width="100" /></div>
                      <div>Loading...</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AutoLoginComponent;