import React, { Component, Fragment } from 'react';
import {
    LAB_TEST_SEARCH, DRUGS_CONSTANT, LAB_TEST_FAVOURITE, LAB_TEST_CONSTANTS, REFERENCE_TYPE, DELETE_TEST,
    PLAN_TEST_ADVICED_STORE, PLAN_TEST_ADVICED_LIST, LOADER_BLACK,LOADER
} from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import 'antd/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { jQueryLabels, AntSelectLabels, ClearAntList } from '../../utils/jQueryLabels';
import { Form, Modal, Input, Button, Select, AutoComplete ,Tooltip } from 'antd';
const { Option } = Select;
class TestTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testData: [],
            viewType: 'full', //template, full
            constants: this.props.constants ?? [],
            commentValue:null,
            protocol_start_date: moment(new Date()).format('D MMM, YYYY'),
            deleteAllTestAtOnce: this.props.deleteAllTestAtOnce ? this.props.deleteAllTestAtOnce : false,
        }
        this.test_name = React.createRef()
        this.flag = 1
        this.deleteAllTest = this.deleteAllTest.bind(this);
        this.handleTestModalPopup = this.handleTestModalPopup.bind(this);
        this.handleDeletePopSubmit = this.handleDeletePopSubmit.bind(this);
  }

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    jQueryLabels();
  }

  componentDidUpdate(prevProps, prevState) {

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      testData: nextProps.testData,
      viewType: nextProps.viewType,
      constants: nextProps.constants
    }
  }

  optionReqViewLoad() {
    if (!this.state.TestConstants || this.state.TestConstants.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.TestConstants.map((obj, i) => {
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
      )
    })
  }
  optionPriorityLoad() {
    if (!this.state.DRUGSConstants.drugUrgency || this.state.DRUGSConstants.drugUrgency.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.drugUrgency.map((obj, i) => {
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
      )
    })
  }


  handleModalPopup = (e, popup) => {
    if (e !== null)
      e.preventDefault();

    this.setState({
      [popup]: !this.state[popup]
    }, () => {
      const { testDuplicateModal, retestApproved } = this.state;
      if (!testDuplicateModal && !retestApproved) {
        this.Formclear();
      }
    })
  }

  deleteAllTest() {
    this.setState({
      deleteTestModal : true
    })
  }
  
  handleTestModalPopup(){
    this.setState({
      deleteTestModal : false
    })
  }

  handleDeletePopSubmit(){
    let all_test_id = []
    this.state.testData.map(obj => {
      all_test_id.push(obj.id)
    })
    this.props.handleDeleteAllTest(all_test_id);
    this.setState({
      deleteTestModal : false
    })
  }

  updateStartDate = (start_data, this_test_id) => {
    var test_data = []
    start_data = moment(start_data).format('YYYY-MM-DD');
    for(var test of this.state.testData){
      if(test.test_id == this_test_id){
        test_data.push(test)
      }
      else{
        test_data.push(test)
      }
    }
    this.props.updateData(test_data, start_data, this_test_id)
  }

  handleEditDelete = (e, modal, data, action, idx) => {

    if (this.props.isPracticing === false) {
      this.props.practiceModal('isPracticingModal');
      return;
    }
    this.props.handlePopUp(e, modal, data, action, idx);
  }

  showTestModalInteraction = (val) => {
    let showInteractionModal = true;
    this.setState({ commentValue: val,isTestModalInteractionVisible: showInteractionModal});
  };
  handleTestInteractionCancel = () => { this.setState({ isTestModalInteractionVisible: false ,commentValue: null})};

  render() {
      const {commentValue} = this.state;
      const textareaSizeDefault = { minRows: 8, maxRows: 24 };
    
    return (
      <Fragment>
          {this.state.testData && this.state.testData.length > 0 ?
              <>
                  <div className="table-responsive">
                      <table className="table table-bordered custom-plan-header mt-1 mb-0">
                          <thead>
                              <tr>
                                  <th>S.NO</th>
                                  <th width={30 + '%'}>TEST NAME/PROCEDURE</th>
                                  <th>PRIORITY</th>
                                  <th>INSTRUCTIONS</th>
                                  <th>Requisition comments</th>
                                  {this.props.eventBased == true ? <th width="180px">DUE FROM</th> : null}
                                  {this.props.showAction ? <th width={100} className='text-center'><div className='d-flex align-items-center'><span className='pr-2'>ACTION</span>
                                  { this.state.deleteAllTestAtOnce == true ?
                                  <span><Tooltip placement="topLeft" title="Delete All"><i className=' icon_delete icon_delete_all cursor-pointer  mt-1' onClick={this.deleteAllTest}></i></Tooltip></span>
                                  :null}
                                  </div> 
                                  </th> : null}
                              </tr>
                          </thead>
                          <tbody>
                              {this.state.testData.map((data, i) => {

                                
                                  var deleteIndex = this.state.viewType == 'visit-full' ? data.id : i;
                                  var protocol_start_date = moment(data.start_date).format('DD MMM, YYYY');
                                  var custom_bgcolor;
                                  var show_original_due = false;
                                  if(this.props.alreadyPrescribedTests?.includes(data.test_id)){
                                    custom_bgcolor = '#dddbdb'
                                  }
                                  else if(moment(data.start_date_protocol).diff(moment(), 'days') < 0){
                                    custom_bgcolor = '#f7eaea'
                                    show_original_due = true;
                                  }
                                  else if(moment(data.start_date).diff(moment(), 'days') == 0){
                                    custom_bgcolor = '#dcedf4'
                                  }
                                  return (
                                      <tr key={i} style={{backgroundColor: this.state.viewType == 'copy-to-visit' && this.props.eventBased == true && this.props.pateintInfo.gender == 'Female' && LS_SERVICE.get('isObsGynaeDoctor') == 1 && LS_SERVICE.get('isObsGynaePatient') == true && data?.event > 0 && custom_bgcolor ? custom_bgcolor : null}}>
                                          <td><span className="text-dark bold-500">{i+1}</span></td>
                                          <td><span className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-dark bold-500 text-danger' : 'text-dark bold-500'}>{data.test_name}</span></td>
                                          <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{this.state.constants.drugUrgency.map((data1, i) => data1.id === data.urgency ? data1.description : null)}</td>
                                          <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.instructions !== '' && data.instructions !== null ? data.instructions : '-'}</td>
                                          <td>{data.requisition_comments != null && data.requisition_comments != '' ?  <a className='text-primary' onClick={() => this.showTestModalInteraction(data.requisition_comments) }><u>View Requisition Comments</u></a>: <span>No</span>}</td>
                                          {(this.state.viewType == 'template' || this.state.viewType == 'list-visit') && this.props.eventBased == true ? 
                                          <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.event !== null ? this.state.constants.drugEvent.map((data1, i) => data1.id === data.event ? data1.description : null) : ''} {data.event == 1 || data.event == 2 ? data.lmp_event_duration : null} {data.event == 1 || data.event == 2 ? data.lmp_event_duration_type : null}</td>
                                          : null}
                                          {this.state.viewType === 'copy-to-visit' && this.props.eventBased == true ? 
                                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                              {
                                                data?.event == 4
                                              ?
                                                <>
                                                  <div className="form-group hasdata">
                                                  <DatePicker
                                                    id="protocol_start_date"
                                                    name="protocol_start_date"
                                                    defaultPickerValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                    defaultValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                    value={moment(protocol_start_date).format('D MMM, YYYY')}
                                                    minDate={moment().toDate()}
                                                    className="form-control datetimepicker"
                                                    onChange={(e) => { this.updateStartDate(e, data.test_id) }}
                                                  />
                                                  <label htmlFor="key_finding_date_time">Custom Date</label>
                                                  </div>
                                                </>
                                              :
                                                <>
                                                  {
                                                    show_original_due == true
                                                  ?
                                                    <>
                                                      <br/>
                                                      (Due from {moment(data.start_date_protocol).format('D MMM, YYYY')})
                                                    </>
                                                  :
                                                    null
                                                  }
                                                </>
                                              }
                                            </td>
                                          : null}
                                          {this.state.viewType === 'soap-view' && this.props.eventBased == true ? 
                                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                              {
                                                moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                              ?
                                                '-'
                                              : 
                                                moment(data.start_date).format('D MMM, YYYY')
                                              }
                                            </td>
                                          : null}
                                          {this.props.showAction ?
                                              <td className='text-center'>
                                                  <a className="nostyle-link mr-2" onClick={(e) => this.handleEditDelete(e, 'deleteConfirmModal', data, 'deleteTest', deleteIndex)}><i className="icon_delete"></i></a>
                                                  {this.props.showSelectCheck ?
                                                      <span className="select-diagnosis select-check">
                                                          <i data-checkSelect={i} data-type={'test'} onClick={(e) => this.props.updateSelection(i, 'testData')}
                                                              className={data.selected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                                                      </span>
                                                      : null}

                                              </td>
                                              : null}
                                      </tr>)
                              })}
                          </tbody>
                      </table>
                  </div>
              </>
              : null}
              <Modal
                visible={this.state.deleteTestModal}
                onCancel={this.handleTestModalPopup}
                style={{ top: 40 }}
                title="Are you sure you want to delete all tests?"
                closable={false}
                footer={[
                  <div className="text-center">
                    <button
                      className="btn btn-outline-secondary px-5 cancelOrderSet"
                      onClick={this.handleTestModalPopup}
                    >
                      CANCEL
                    </button>
                    <button
                      className="btn btn-primary px-5 deleteOrderSet ml-1"
                      disabled={this.state.spin_loading}
                      id='deleteButton'
                      onClick={this.handleDeletePopSubmit}
                    >
                      DELETE{this.state.spin_loading ? LOADER : ""}
                    </button>
                  </div>,
                ]}
              ></Modal>
              <Modal
                  className="myTestDrugsInteraction_modal"
                  id="myTestDrugsInteraction"
                  title="Requisition Comments"
                  visible={this.state.isTestModalInteractionVisible}
                  onOk={() => this.handleTestInteractionCancel()}
                  onCancel={() => this.handleTestInteractionCancel()}
                  footer={false}
                >

                  <div className="col-12">
                    <div className={"antInputItem form-group hasdata"}>
                      <table>
                        <th>
                          <td>
                            <div className="col-12">
                                  <textarea readOnly name="noi-comments" id="noi-comments" data-name="noi-comments"  value={commentValue} className="form-control drug-input input-template mandatory-field" placeholder="Comments" rows="4" cols="50"></textarea>
                            </div>         
                          </td>
                        </th>
                      </table>
                      
                    </div>
                  </div>
                  
                  <div className="modal-footer justify-content-center emrfrm pb-0">
                    <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={() => this.handleTestInteractionCancel()}>CLOSE</button>
                  </div>
              </Modal>

      </Fragment>

        
    );


      
  }
}

export default TestTable;