import React, { Component, Fragment, createRef } from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';
import {
  LOADER_BLACK,
  REFERENCE_TYPE,
  SERVICE_TEST_SEARCH,
  SURGERY_MASTER_DETAIL,
  SURGERY_TYPE,
  INCISION_TYPE,
  EQUIPMENT_MASTER,
  IMPLANTS_MASTER,
  FAVOURITE_SURGERY_LIST,
  ANATOMIC_SITE_MASTER,
  OPD_SPECIALITY,
  OPD_DOCTOR,
  ANESTHESIA_TYPE_LIST,
  GET_OPERATION_LIST,
  ASSESSMENT_API,
  GET_AVAILABLE_OT_SLOTS,
  LOADER,
  HANDLEVALIDANTMESSAGES,
  MARK_FAVOURITE_SURGERY,
  UNMARK_FAVOURITE_SURGERY,
  SURGERY_REQUESTS_STORE,
  GET_EXISTING_SURGERIES,
  IS_PAC_CLEARANCE_REQUIRED,
} from '../../utils/constant';
import { notification, Form, Select, DatePicker, Button, Input, Tooltip, Modal, Checkbox  } from "antd";
import LS_SERVICE from '../../utils/localStorage';
import axios from 'axios';
import $ from 'jquery';
import qs from 'qs';
import { jQueryLabels } from '../../utils/jQueryLabels';

const { Option } = Select;
const config = {
  rules: [
    {
      type: 'object',
      required: true,
      message: 'Please fill the required field.',
    },
  ],
};

class NewSurgeryForm extends Component {
	constructor(props) {
  	super(props);
    this.state = {
    	patient: this.props.patient,
    	allowEdit: this.props.allowEdit ?? true,
    	DRUGSConstants: this.props.constants ?? [],
    	deleteModalTitle: '',
      isDeleteAlertVisible: false,
      viewMode: this.props.admissionAdvisedDisabled,
      formSubmitted: false,
      showView: false,
      bloodRequestModal: false,
      primaryPriority: null,
      bloodRequestComponent: false,
      fetching_surgery: false,
      date_picker_disabled: true,
      time_picker_disabled: true,
      selectSurgeryValue: null,
      surgeryTypeList: [],
      surgeryTypeChecked: [],
      incisionTypeList: [],
      favSurgeryList: [],
      anaesthesiaTypeList: [],
      anaesthesiaDoctorList: [],
      activeDiagnosisList: [],
      incisionType: null,
      diagnosisChecked: [],
      surgeryList: [],
      SurgeryRequestsAddTable: false,
      SurgeryRequestsAddListed: [],
      siteList: [],
      specialityList: [],
      docList: [],
      estTimeType: [
        { id: 1, description: 'Mins' },
        { id: 2, description: 'Hours' }
      ],
      surgery_date: null,
      ot_time: null,
      calander_open: false,
      infectedStatus: 0,
      pacRequired: IS_PAC_CLEARANCE_REQUIRED.includes(LS_SERVICE.get("slot_hospital_id")) ? 1 : 0,
      bloodRequirement: 0,
      bloodRequirementDetail: {},
      equipmentsList: [],
      implantsList: [],
      selectEquipmentValue: null,
      selectImplantValue: null,
      EquipmentAddListed: [],
      ImplantsAddListed: [],
      remarks: null,
      submitButtonText: 'SAVE',
      edit_surgery_id: null,
      addAssessmentModal: false,
      AddAssessmentLoader: false,
      actionText: '',
      priority: 49,
      priority_desc: 'Routine',
      anesthesia_type: '',
      ot_type: '',
      anesthesia_type_desc: '',
      anesthesia_doctor: '',
      anesthesia_doctor_name: '',
      operation_id: 0,
      operation_name: '',
      allOTList: [],
      allAvailableSlots: [],
      existingSurgeriesList: [],
      servicePriorityData: [],
      ViewDetailsModal: false,
      equipmentsData : [],
      implantsData : [],
      surgeryOtDataRequired : true,
    }
    this.deleteIndex = null;
    this.deleteType = null;
    this.defaultDocList = [];
    this.handleSurgeryCancel = this.handleSurgeryCancel.bind(this);
    this.onSurgerySearch = debounce(this.onSurgerySearch, 800);
    this.conditionFormRef = React.createRef();
    this.surgery_site_selected = React.createRef();
	}

	async componentDidMount() {
		axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    jQueryLabels();
  	await this.getExisitingSurgeries();

    let arr = [];
    arr.push(this.getSurgeryTypeList());
    arr.push(this.getIncisionTypeList());
    arr.push(this.getEquipmentList());
    arr.push(this.getImplantsList());
    arr.push(this.getFavouriteSurgeryList());
    arr.push(this.getAnatomicSiteList());
    arr.push(this.getSpecialityList());
    arr.push(this.handleAssessmentData());
    arr.push(this.getAnesthesiaList());
    arr.push(this.getAnesthesiaDoctorList());
    arr.push(this.getOTList());
    arr.push(this.getOTList());
    let allStatus = [];
    await Promise.allSettled(arr).then((response) =>
      response.map(res => {
        allStatus.push(res.status);
      }
      )).catch((err) => console.log(err));

    if (allStatus.length == arr.length && !allStatus.includes('rejected')) {
      this.setState({ showView: true })
    }
    this.getDoctorList(LS_SERVICE.get('speciality_id'), true);
  }

  async getExisitingSurgeries() {
		const { patient } = this.state;
		const { qms_token_id } = patient;
		const surgery_list_data = await axios.get(GET_EXISTING_SURGERIES({ reference_type: REFERENCE_TYPE, reference_id: qms_token_id }));
		if (typeof surgery_list_data.data != 'undefined' && typeof surgery_list_data.data.data != 'undefined') {
			await this.setState({
				existingSurgeriesList: surgery_list_data.data.data,
				viewMode : true,
			});
		}
  }

  handleResetForm = async() => {
  	await this.setState({
  		deleteModalTitle: '',
      isDeleteAlertVisible: false,
      formSubmitted: false,
      showView: false,
      bloodRequestModal: false,
      primaryPriority: null,
      bloodRequestComponent: false,
      fetching_surgery: false,
      date_picker_disabled: true,
      time_picker_disabled: true,
      selectSurgeryValue: null,
      surgeryTypeChecked: [],
      incisionType: null,
      diagnosisChecked: [],
      surgeryList: [],
      SurgeryRequestsAddTable: false,
      SurgeryRequestsAddListed: [],
      surgery_date: null,
      ot_time: null,
      calander_open: false,
      infectedStatus: 0,
      pacRequired: IS_PAC_CLEARANCE_REQUIRED.includes(LS_SERVICE.get("slot_hospital_id")) ? 1 : 0,
      bloodRequirement: 0,
      bloodRequirementDetail: {},
      selectEquipmentValue: null,
      selectImplantValue: null,
      EquipmentAddListed: [],
      ImplantsAddListed: [],
      remarks: null,
      submitButtonText: 'SAVE',
      edit_surgery_id: null,
      addAssessmentModal: false,
      AddAssessmentLoader: false,
      actionText: '',
      priority: 49,
      priority_desc: 'Routine',
      anesthesia_type: '',
      ot_type: '',
      anesthesia_type_desc: '',
      anesthesia_doctor: '',
      anesthesia_doctor_name: '',
      operation_id: '',
      operation_name: '',
      allAvailableSlots: [],
      servicePriorityData: [],
      viewMode: false,
      ViewDetailsModal: false,
  	})
  	await this.conditionFormRef.current.resetFields();
  }

  getSurgeryTypeList = () => {
    const PARAMS = {
      active: 1,
    }
    return new Promise((resolve, reject) => {
      axios.post(SURGERY_TYPE, PARAMS).then(success => {
        if (success.status == 200 && success?.data?.status == true) {
          this.setState({ surgeryTypeList: success.data.data });
          resolve('done')
        } else {
          reject(success.status)
        }
      }).catch(err => console.log(err))
    })
  }

  getIncisionTypeList = () => {
    const PARAMS = {
      active: 1,
    }
    return new Promise((resolve, reject) => {
      axios.post(INCISION_TYPE, PARAMS).then(success => {
        if (success.status == 200 && success?.data?.status == true) {
          this.setState({ incisionTypeList: success.data.data });
          resolve('done')
        } else {
          reject(success.status)
        }
      }).catch(err => console.log(err))
    })
  }

  getEquipmentList = () => {
    const PARAMS = {
      active: 1,
    }
    return new Promise((resolve, reject) => {
      axios.post(EQUIPMENT_MASTER, PARAMS).then(success => {
        if (success.status == 200 && success?.data?.status == true) {
          this.setState({ equipmentsList: success.data.data });
          resolve('done')
        } else {
          reject(success.status)
        }
      }).catch(err => console.log(err))
    })
  }
  getImplantsList = () => {
    const PARAMS = {
      enterpriseId: LS_SERVICE.get('ENTERPRISE_ID'),
      AttributeCode: "IMPT",
      facilityId: LS_SERVICE.get('FACILITY_ID'),
      storeId: parseInt(LS_SERVICE.get('STORE_ID'))
    }
    return new Promise((resolve, reject) => {
      axios.post(IMPLANTS_MASTER, PARAMS).then(success => {
        if (success.status == 200 && success?.data?.status == true) {
          this.setState({ implantsList: success.data.data });
          resolve('done')
        } else {
          reject(success.status)
        }
      }).catch(err => console.log(err))
    })
  }
  getFavouriteSurgeryList = () => {
    const PARAMS = {
      facility_id: parseInt(LS_SERVICE.get('FACILITY_ID')),
      doctor_id: LS_SERVICE.get("staff_id"),
      service_type: "S",
      active: 'Y'
    }
    return new Promise((resolve, reject) => {
      axios.post(FAVOURITE_SURGERY_LIST, PARAMS).then(success => {
        if (success.status == 200 && success?.data?.status == true) {
          this.setState({ favSurgeryList: success.data.data });
          resolve('done')
        } else {
          reject(success.status)
        }
      }).catch(err => console.log(err))
    })
  }
  getAnatomicSiteList = () => {
    const PARAMS = {
      active: 1
    }
    return new Promise((resolve, reject) => {
      axios.post(ANATOMIC_SITE_MASTER, PARAMS).then(success => {
        if (success.status == 200 && success?.data?.status == true) {
          this.setState({ siteList: success.data.data });
          resolve('done')
        } else {
          reject(success.status)
        }
      }).catch(err => console.log(err))
    })
  }
  getSpecialityList = () => {
    const PARAMS = {
      active: 1,
      facilityId: parseInt(LS_SERVICE.get('FACILITY_ID')),
      enterpriseid:LS_SERVICE.get('ENTERPRISE_ID')
    }
    return new Promise((resolve, reject) => {
      axios.post(OPD_SPECIALITY, PARAMS).then(success => {
        if (success.status == 200 && success?.data?.status == true) {
          this.setState({ specialityList: success.data.data });
          let specialityName = this.state.specialityList.find(x => x.id === LS_SERVICE.get('speciality_id'))?.description;
          this.setState({ specialityName: specialityName });
          resolve('done')
        } else {
          reject(success.status)
        }
      }).catch(err => console.log(err))
    })
  }
  getDoctorList = async (speciality_id, def = false, index) => {
    const PARAMS = {
      active: 1,
      code: 'DR',
      facilityId: parseInt(LS_SERVICE.get('FACILITY_ID')),
      PrimarySpecialityId: speciality_id
    }
    await axios.post(OPD_DOCTOR, PARAMS)
      .then(success => {
        if (def) {
          this.defaultDocList = success.data.data;
        } else {
          this.setState(prevState => {
            const prevItems = [...prevState.docList];
            prevItems[index] = success.data.data;
            return { docList: prevItems };
          });
        }
      }).catch(err => console.log(err))
  }

  getAnesthesiaList = () => {
    const PARAMS = {
      active: 1,
      enterpriseId: LS_SERVICE.get('ENTERPRISE_ID'),
      facilityId: parseInt(LS_SERVICE.get('FACILITY_ID'))
    }
    return new Promise((resolve, reject) => {
      axios.post(ANESTHESIA_TYPE_LIST, PARAMS).then(success => {
        if (success.status == 200 && success?.data?.status == true) {
          this.setState({ anaesthesiaTypeList: success.data.data });
          resolve('done')
        } else {
          reject(success.status)
        }
      }).catch(err => console.log(err))
    })
  }
  getAnesthesiaDoctorList = () => {
    const PARAMS = {
      active: 1,
      code: 'DR',
      facilityId: parseInt(LS_SERVICE.get('FACILITY_ID'))
    }
    return new Promise((resolve, reject) => {
      axios.post(OPD_DOCTOR, PARAMS).then(success => {
        if (success.status == 200 && success?.data?.status == true) {
          if (success?.data?.data?.length > 0) {
            var anaesthesiaDoctorList = [];
            for (var doctor of success?.data?.data) {
              if (doctor.userSubGroupCode == 'AN') {
                anaesthesiaDoctorList.push(doctor)
              }
            }
            this.setState({ anaesthesiaDoctorList: anaesthesiaDoctorList });
          }
          resolve('done')
        } else {
          reject(success.status)
        }
      }).catch(err => console.log(err))
    })
  }

  getOTList = async () => {
    await axios.post(GET_OPERATION_LIST, { "enterpriseId": LS_SERVICE.get('ENTERPRISE_ID'), "facilityId": LS_SERVICE.get('FACILITY_ID') })
      .then(success => {
        if (success?.data?.status == true) {
          if (Object.keys(success.data.data).length) {
            this.setState({ allOTList: success.data.data });
          }
        }
      }).catch(err => console.log(err))
  }

  viewOperativeNotes = async(result) => {
  	await this.setState({
  		equipmentsData : result.equipments != "" && result.equipments != null ? JSON.parse(result.equipments) : [],
  		implantsData : result.implant_items != "" && result.implant_items != null ? JSON.parse(result.implant_items) : [],
  		ViewDetailsModal: true,
  	});
  }

  handleAssessmentData = async () => {

  	const { patient } = this.state;
  	const { qms_token_id } = patient;

  	const assessDataPromise = await axios.get(ASSESSMENT_API({ reference_type: REFERENCE_TYPE, reference_id: qms_token_id }))
    if (assessDataPromise.data)
    {
      let { data } = assessDataPromise.data;
      let assessmentTempArray = [];
      var active_diagnosis = [];
      if (data.details || data.details.length > 0) {
        data.details.map((data, i) => {
          var tempAssessmentObj = {
            description: data?.diagnosisInfo?.diagnose_description,
            diagnosis_id: data?.diagnosisId,
            icd_code: data?.diagnosisInfo?.icd_code,
            id: data.id,
            image_comment: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].comment : null,
            image_uploaded: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].imagePath : null,
            stage: data.stage,
            type: data.type,
            status: data.status,
            diagnosis_notes: data.diagnosisNotes,
          }
          active_diagnosis.push(tempAssessmentObj);
        });
      }
      if (active_diagnosis && active_diagnosis.length == 1) {
        this.handleDiagnosisChange(active_diagnosis[0]);
      }
      await this.setState({
        activeDiagnosisList: [...active_diagnosis],
      });
    }
  }

  handleSurgeryCancel(e) {
  	this.props.handleSurgeryCancel();
  }

  markFavSurgery = async (data) => {
    let findex = this.state.favSurgeryList.findIndex(x => x.test_id === data.surgery_id);
    let toBeFav = findex === -1;
    if (toBeFav) {
      const PARAMS = {
        test_id: data.surgery_id,
        doctor_id: LS_SERVICE.get("staff_id"),
        service_type: "S",
        description: data.surgery_name,
        facility_id: parseInt(LS_SERVICE.get('FACILITY_ID'))
      }
      await axios.post(MARK_FAVOURITE_SURGERY, PARAMS)
        .then(success => {
          if (success?.data?.status == true) {
            notification.success({
              message: 'Marked as Favourite Successfully.',
              placement: 'topRight'
            });
            const obj = {
              test_id: data.surgery_id,
              service_Code: data.surgery_code,
              descriptionWithCode: data.surgery_name
            }
            this.setState(prevState => {
              const newItems = [...prevState.favSurgeryList, obj];
              return { favSurgeryList: newItems };
            });
          } else {
            notification.error({
              message: success?.data?.message,
              placement: 'topRight'
            });
          }
        }).catch(err => console.log(err))
    } else {
      const PARAMS = {
        test_id: data.surgery_id,
        doctor_id: LS_SERVICE.get("staff_id"),
        facility_id: parseInt(LS_SERVICE.get('FACILITY_ID'))
      }
      await axios.post(UNMARK_FAVOURITE_SURGERY, PARAMS)
        .then(success => {
          if (success?.data?.status == true) {
            notification.success({
              message: 'Removed from Favourite Successfully.',
              placement: 'topRight'
            });
            this.setState(prevState => ({
              favSurgeryList: prevState.favSurgeryList.filter((data, i) => i !== findex)
            }));
          } else {
            notification.error({
              message: success?.data?.message,
              placement: 'topRight'
            });
          }
        }).catch(err => console.log(err))
    }
  }

	onSurgerySearch = (search) => {
    this.setState({ fetching_surgery: true });
    axios.post(SERVICE_TEST_SEARCH, qs.stringify({
      active: 1,
      service_name: search,
      facility_id: parseInt(LS_SERVICE.get("slot_hospital_id")),
      filter_service_type: "S,IPP",
      doctor_id: LS_SERVICE.get("staff_id")
    })).then(success => {
      this.setState({
        surgeryList: success.data.data,
        fetching_surgery: false
      });
    }).catch(err => console.log(err))
  }

  addSurgery = async (data) => {
    let findex = await this.state.SurgeryRequestsAddListed.findIndex(x => x.primary === true);
    let find_stat_surgery = await this.state.SurgeryRequestsAddListed.findIndex(x => x.priority == 51);
    let priority_obj = {
      priority: data.priority || 49,
      priority_desc: data.priority_desc || 'Routine'
    }
    if(find_stat_surgery != -1)
    {
      priority_obj.priority = 51;
      priority_obj.priority_desc = 'Stat';
    }
    if (this.state.surgeryTypeChecked.length > 0) {
      this.state.surgeryTypeChecked.forEach((v, i) => {
        if (v.surgery_type_name == 'Emergency') {
          priority_obj.priority = 50;
          priority_obj.priority_desc = 'Urgent';
        }
      })
    }
    const obj = {
      surgery_id: data.serviceid,
      surgery_code: data.surgery_code,
      surgery_name: data.title,
      primary: findex === -1 ? true : false, // this.state.SurgeryRequestsAddListed.length > 0 ? false : true,
      surgery_site: null,
      surgery_site_name: null,
      surgery_speciality: LS_SERVICE.get('speciality_id'),
      surgery_speciality_name: this.state.specialityName, //'Aneasthetist',
      surgery_surgeon: LS_SERVICE.get('staff_id'), //defaultSurgeon,
      surgery_surgeon_name: LS_SERVICE.get('staff_name'),// defaultSurgeonName,
      surgery_est_timetype: 1,
      surgery_time: null,
      ...priority_obj
    };
    await this.setState(prevState => {
      const newItems = [...prevState.docList, [...this.defaultDocList]];
      return { docList: newItems }
    });
    await this.setState(prevState => {
      const newItems = [...prevState.SurgeryRequestsAddListed, obj];
      return { SurgeryRequestsAddListed: newItems };
    });
  }

  onSelectSurgeryChange = async (val, props) => {
    if (props.serviceid) {
      let findex = this.state.SurgeryRequestsAddListed.findIndex(x => x.surgery_id === props.serviceid);
      if (findex === -1) {
        axios.post(SURGERY_MASTER_DETAIL, {
          active: 1,
          serviceId: props.serviceid,
          enterpriseId: LS_SERVICE.get('ENTERPRISE_ID'),
          facilityId: parseInt(LS_SERVICE.get('FACILITY_ID')),
        }).then(async success => {
          if (success.data.status) {
            await this.addSurgery(props);
            this.setState({ SurgeryRequestsAddTable: true, selectSurgeryValue: val });
            if (success.data.data.length) {
              this.mapItemsSurgeryDetailsMaster(success.data.data[0]);
            }
          }
        }).catch(err => console.log(err))
      }
    }
  }

  mapItemsSurgeryDetailsMaster = async (master_data) => {
    if (master_data.anatomicalRegionId) {
      await this.setState(prevState => {
        const prevItems = [...prevState.SurgeryRequestsAddListed];
        prevItems[prevItems.length - 1]['surgery_site'] = master_data.anatomicalRegionId;
        prevItems[prevItems.length - 1]['surgery_site_name'] = this.state.siteList[this.state.siteList.findIndex(x => x.id === master_data.anatomicalRegionId)].description;
        prevItems[prevItems.length - 1]['surgery_site'] = master_data.anatomicalRegionId;
        return { SurgeryRequestsAddListed: prevItems };
      });
      let prevValues = this.conditionFormRef.current.getFieldsValue();
      prevValues['surgeries'][prevValues['surgeries'].length - 1].surgery_site = master_data.anatomicalRegionId;
      this.conditionFormRef.current.setFieldsValue({
        ...prevValues
      })
    }
    if (master_data.anesthesiaTypeId) {
      this.conditionFormRef.current.setFieldsValue({ anesthesia_type: parseInt(master_data.anesthesiaTypeId) });
    }
    if (master_data.equipmentData.length) {
      master_data.equipmentData.forEach(async ele => {
        let findex = this.state.EquipmentAddListed.findIndex(x => x.equipment_id === ele.equipmentId);
        if (findex === -1) {
          let findex2 = this.state.equipmentsList.findIndex(x => x.id === ele.equipmentId);
          if (findex2 !== -1) {
            const obj = {
              equipment_id: ele.equipmentId,
              equipment_code: this.state.equipmentsList[findex2].code,
              equipment_name: this.state.equipmentsList[findex2].description,
              equipment_unit: String(ele.equipmentQty),
              equipment_qoh: this.state.equipmentsList[findex2].qoh,
            };

            await this.setState(prevState => {
              const newItems = [...prevState.EquipmentAddListed, obj];
              return { EquipmentAddListed: newItems };
            });
          }
        } else {
          let findex2 = this.state.equipmentsList.findIndex(x => x.id === ele.equipmentId);
          if (findex2 !== -1) {
            await this.setState(prevState => {
              const prevItems = [...prevState.EquipmentAddListed];
              prevItems[findex]['equipment_unit'] = String(Math.max(parseInt(prevItems[findex].equipment_unit) || 0, ele.equipmentQty));
              return { EquipmentAddListed: prevItems };
            });
            let prevValues = this.conditionFormRef.current.getFieldsValue();
            prevValues['equipments'][findex].equipment_unit = String(Math.max(parseInt(prevValues['equipments'][findex].equipment_unit) || 0, ele.equipmentQty));
            this.conditionFormRef.current.setFieldsValue({
              ...prevValues
            })
          }
        }
      });
    }
    if (master_data.implantData.length) {
      master_data.implantData.forEach(async ele => {
        let findex = this.state.ImplantsAddListed.findIndex(x => x.implant_id === ele.implantId);
        if (findex === -1) {
          let findex2 = this.state.implantsList.findIndex(x => x.id === ele.implantId);
          if (findex2 !== -1) {
            const obj = {
              implant_id: ele.implantId,
              implant_code: this.state.implantsList[findex2].code,
              implant_name: this.state.implantsList[findex2].label,
              implant_unit: String(ele.implantQty),
              implant_qoh: this.state.implantsList[findex2].qoh,
            };
            await this.setState(prevState => {
              const newItems = [...prevState.ImplantsAddListed, obj];
              return { ImplantsAddListed: newItems };
            });
          }
        } else {
          let findex2 = this.state.implantsList.findIndex(x => x.id === ele.implantId);
          if (findex2 !== -1) {
            await this.setState(prevState => {
              const prevItems = [...prevState.ImplantsAddListed];
              prevItems[findex]['implant_unit'] = String((parseInt(prevItems[findex].implant_unit) || 0) + ele.implantQty);
              return { ImplantsAddListed: prevItems };
            });
            let prevValues = this.conditionFormRef.current.getFieldsValue();
            prevValues['implants'][findex].implant_unit = String((parseInt(prevValues['implants'][findex].implant_unit) || 0) + ele.implantQty);
            this.conditionFormRef.current.setFieldsValue({
              ...prevValues
            })
          }
        }
      });
    }
  }
  handleSurgeryTypeChange = async (e, data) => {
    let findex = this.state.surgeryTypeChecked.findIndex(x => x.surgery_type_id === data.id);
    let find_stat_surgery = await this.state.SurgeryRequestsAddListed.findIndex(x => x.priority == 51);
    if (findex === -1) {
      if (data.description == "Emergency" && find_stat_surgery == -1) {
        if (this.state.SurgeryRequestsAddListed.length > 0) {
          this.state.SurgeryRequestsAddListed.forEach(async (val, index) => {
            await this.setState(prevState => {
              const prevItems = [...prevState.SurgeryRequestsAddListed];
              prevItems[index]['priority'] = 50;
              prevItems[index]['priority_desc'] = 'Urgent';
              return { SurgeryRequestsAddListed: prevItems };
            });
          });
        }
      }
      await this.setState(prevState => {
        let obj = {
          surgery_type_id: data.id,
          surgery_type_name: data.description
        };
        const newItems = [...prevState.surgeryTypeChecked, obj];
        return { surgeryTypeChecked: newItems }
      });
    } else {
      if (data.description == "Emergency" && find_stat_surgery == -1) {
        if (this.state.SurgeryRequestsAddListed.length > 0) {
          this.state.SurgeryRequestsAddListed.forEach(async (val, index) => {
            await this.setState(prevState => {
              const prevItems = [...prevState.SurgeryRequestsAddListed];
              prevItems[index]['priority'] = 49;
              prevItems[index]['priority_desc'] = 'Routine';
              return { SurgeryRequestsAddListed: prevItems };
            });
          });
        }
      }
      await this.setState(prevState => ({
        surgeryTypeChecked: prevState.surgeryTypeChecked.filter((d, i) => i !== findex)
      }));
    }
  }
  handleInputChange = async (event, data) => {
    let alreadyExists = false;
    let findex = this.state.SurgeryRequestsAddListed.findIndex(x => x.surgery_id === data.test_id);
    if (findex === -1) {
      let obj = {
        serviceid: data.test_id,
        surgery_code: data.service_Code,
        title: data.descriptionWithCode
      }
      if (alreadyExists) {
        notification.error({
          message: "Surgery request already exists!",
          placement: 'topRight'
        });

        return true;
      }

      axios.post(SURGERY_MASTER_DETAIL, {
        active: 1,
        serviceId: data.test_id
      }).then(async success => {
        if (success.data.status) {
          await this.addSurgery(obj);
          this.setState({ SurgeryRequestsAddTable: true });
          if (success.data.data.length) {
            this.mapItemsSurgeryDetailsMaster(success.data.data[0]);
          }
        }
      }).catch(err => console.log(err))
    } else {
      this.callDelete(findex, 'surgery');
    }
  }

  handleDiagnosisChange = async (data) => {
    if (data.diagnosis_id && data.diagnosis_id > 0) {
      let findex = this.state.diagnosisChecked.findIndex(x => x.diagnosis_desc === data.description);
      if (findex === -1) {
        const obj = {
          diagnosis_id: data.id,
          diagnosis_desc: data?.description,
          icd_code: data?.icd_code
        };
        await this.setState(prevState => {
          const newItems = [...prevState.diagnosisChecked, obj];
          return { diagnosisChecked: newItems };
        });
      } else {
        await this.setState(prevState => ({
          diagnosisChecked: prevState.diagnosisChecked.filter((d, i) => i !== findex)
        }));
      }
    }
    else {
      let findex = this.state.diagnosisChecked.findIndex(x => x.diagnosis_desc === data.diagnosis_notes);
      if (findex === -1) {
        const obj = {
          diagnosis_id: data.id,
          diagnosis_desc: data?.diagnosis_notes,
          icd_code: ''
        };
        await this.setState(prevState => {
          const newItems = [...prevState.diagnosisChecked, obj];
          return { diagnosisChecked: newItems };
        });
      } else {
        await this.setState(prevState => ({
          diagnosisChecked: prevState.diagnosisChecked.filter((d, i) => i !== findex)
        }));
      }
    }
  }
  handlePrimaryChange = async (e, data, field, index) => {
    e.persist();
    await this.setState(prevState => {
      const prevItems = [...prevState.SurgeryRequestsAddListed];
      prevItems[index][field] = e.target.checked;
      return { SurgeryRequestsAddListed: prevItems };
    });
  }
  //common function for select box under surgery list
  onSelectChangeList = async (value, props, field, index) => {
    if (field == 'incision_type') {
      await this.setState({
        incisionType: value
      });
    } else {
      await this.setState(prevState => {
        const prevItems = [...prevState.SurgeryRequestsAddListed];
        if (field == 'surgery_site') {
          prevItems[index]['surgery_site_name'] = props.label;
        }
        if (field == 'surgery_speciality') {
          prevItems[index]['surgery_speciality_name'] = props.label;
          prevItems[index]['surgery_surgeon'] = LS_SERVICE.get('staff_id');
          prevItems[index]['surgery_surgeon_name'] = LS_SERVICE.get('staff_name');
          let prevValues = this.conditionFormRef.current.getFieldsValue();
          prevValues['surgeries'][index].surgery_surgeon = LS_SERVICE.get('staff_id');
          this.conditionFormRef.current.setFieldsValue({
            ...prevValues
          })
        }
        if (field == 'priority') {
          if(value == 51)
          {
            let surgeriesArray = [...this.state.SurgeryRequestsAddListed];
            surgeriesArray = surgeriesArray.map((data, i) => {
              let obj = { ...data }
              obj.priority = 51;
              obj.priority_desc = 'Stat';
              return obj;
            });
            this.setState(prevState => ({
              SurgeryRequestsAddListed: surgeriesArray,
            }));
            this.setState({
              surgeryOtDataRequired : false,
            })
          }
          prevItems[index]['priority'] = value;
          prevItems[index]['priority_desc'] = props.title;
        }
        if (field == 'surgery_surgeon') {
          prevItems[index]['surgery_surgeon_name'] = props.label;
        }
        if (field == 'surgery_est_timetype') {
          if (value == 1) {
            $(`.time${index} .ant-form-item-explain-error`).show();
          } else {
            $(`.time${index} .ant-form-item-explain-error`).hide();
          }
        }
        prevItems[index][field] = value;
        return { SurgeryRequestsAddListed: prevItems };
      });
      if (field == 'surgery_speciality') {
        this.getDoctorList(value, false, index);
      }
    }
  }
  //for edit input value
  handleChange = async (event, field, index) => {
    event.persist();
    if (field == 'no_of_units_blood') {
      this.setState(prevState => {
        const prevItems = prevState.bloodRequirementDetail;
        prevItems.no_of_units = event.target.value;
        return { bloodRequirementDetail: prevItems };
      });
    } else if (field == 'equipment_unit') {
      await this.setState(prevState => {
        const prevItems = [...prevState.EquipmentAddListed];
        prevItems[index][field] = event.target.value;
        return { EquipmentAddListed: prevItems };
      });
    } else if (field == 'implant_unit') {
      await this.setState(prevState => {
        const prevItems = [...prevState.ImplantsAddListed];
        prevItems[index][field] = event.target.value;
        return { ImplantsAddListed: prevItems };
      });
    } else if (field == 'remarks') {
      this.setState({ remarks: event.target.value });
    } else {
      await this.setState(prevState => {
        const prevItems = [...prevState.SurgeryRequestsAddListed];
        prevItems[index][field] = event.target.value;
        return { SurgeryRequestsAddListed: prevItems };
      });
    }
  }
  callDelete = (index, type) => {
    this.deleteIndex = index;
    this.deleteType = type;
    if (type == 'blood_request') {
      type = 'Blood Request'
    }
    let title = 'Are you sure you want to delete ' + type + '?'
    this.setState({
      deleteModalTitle: title,
      isDeleteAlertVisible: true
    });
  }
  handleDeleteModalOk = async () => {
    await this.setState({ isDeleteAlertVisible: false });
    if (this.deleteType == 'surgery') {
      this.deleteSurgery(this.deleteIndex);
    }
    if (this.deleteType == 'equipment') {
      this.deleteEquipment(this.deleteIndex);
    }
    if (this.deleteType == 'implant') {
      this.deleteImplant(this.deleteIndex);
    }
    if (this.deleteType == 'blood_request') {
      this.deleteBloodRequest();
    }
  }
  deleteSurgery = async (index) => {
    let prevValues = await this.conditionFormRef.current.getFieldsValue();
    await this.setState(prevState => ({
      docList: prevState.docList.filter((data, i) => i !== index)
    }));
    await this.setState(prevState => ({
      SurgeryRequestsAddListed: prevState.SurgeryRequestsAddListed.filter((data, i) => i !== index),
      selectSurgeryValue: null
    }));
    let surgeriesArray = [...this.state.SurgeryRequestsAddListed];
    surgeriesArray = surgeriesArray.map((data, i) => {
      let obj = { ...data }
      let findex = this.state.SurgeryRequestsAddListed.findIndex(x => x.primary === true);
      if (findex === -1) {
        if (i == 0) {
          obj.primary = true;
        }
      }
      return obj
    });
    await this.setState(prevState => ({
      SurgeryRequestsAddListed: surgeriesArray,
      selectSurgeryValue: null
    }));
    await prevValues['surgeries'].splice(index, 1);
    if (this.state.SurgeryRequestsAddListed.length === 1) {
      this.setState({ incisionType: null });
      prevValues.incision_type = null;
      prevValues.primary = 1;
    }
    await this.conditionFormRef.current.setFieldsValue({
      ...prevValues
    })
    if (this.state.SurgeryRequestsAddListed.length === 0) {
      this.setState({ SurgeryRequestsAddTable: false, surgeryOtDataRequired: true })
    }
  }
  deleteEquipment = async (index) => {
    let prevValues = await this.conditionFormRef.current.getFieldsValue();
    await this.setState(prevState => ({
      EquipmentAddListed: prevState.EquipmentAddListed.filter((data, i) => i !== index),
      selectEquipmentValue: null
    }));
    await prevValues['equipments'].splice(index, 1);
    await this.conditionFormRef.current.setFieldsValue({
      ...prevValues
    });
  }
  deleteImplant = async (index) => {
    let prevValues = await this.conditionFormRef.current.getFieldsValue();
    await this.setState(prevState => ({
      ImplantsAddListed: prevState.ImplantsAddListed.filter((data, i) => i !== index),
      selectImplantValue: null
    }));
    await prevValues['implants'].splice(index, 1);
    await this.conditionFormRef.current.setFieldsValue({
      ...prevValues
    });
  }
  handleRadio = async (e) => {
    const { name, value } = e.target;
    await this.setState({
      [name]: parseInt(value)
    });
  }
  datePickerStatus = status => {
    if (status) {
      this.setState({ calander_open: true })
    } else {
      this.setState({ calander_open: false })
    }
  };
  handleTimePickerChange = async (time) => {
    let alreadyExists = false;
    const { surgery_date } = this.state;
    if (alreadyExists) {
      document.getElementById('ot_time').value = '';
      notification.error({
        message: "This slot is already taken",
        placement: 'topRight'
      })
    }
  }
  handleModalCancel = async () => {
    await this.setState({
      bloodRequestComponent: false
    });
    this.setState({
      bloodRequestModal: false
    });
    if (!Object.keys(this.state.bloodRequirementDetail).length) {
      this.setState({ bloodRequirement: 0 });
    }
  }
  handleModalSave = async (obj) => {
    await this.setState({ bloodRequirementDetail: obj });
    this.conditionFormRef.current.setFieldsValue({ no_of_units_blood: this.state.bloodRequirementDetail.no_of_units });
  }
  enableBloodRequestEdit = async () => {
    await this.setState({
      bloodRequestModal: true
    });
    this.setState({
      bloodRequestComponent: true
    });
  }
  deleteBloodRequest = async () => {
    await this.setState({ bloodRequirementDetail: {}, bloodRequirement: 0 })
  }
  onSelectEquipmentChange = async (val, props) => {
    if (val) {
      let findex = this.state.EquipmentAddListed.findIndex(x => x.equipment_id === val);
      if (findex === -1) {
        const obj = {
          equipment_id: val,
          equipment_code: props.code,
          equipment_name: props.title,
          equipment_unit: '1',
          equipment_qoh: props.qoh,
        };
        await this.setState(prevState => {
          const newItems = [...prevState.EquipmentAddListed, obj];
          return { EquipmentAddListed: newItems };
        });
        this.setState({ selectEquipmentValue: val });
      }
    }
  }
  onSelectImplantChange = async (val, props) => {
    if (val) {
      let findex = this.state.ImplantsAddListed.findIndex(x => x.implant_id === val);
      if (findex === -1) {
        const obj = {
          implant_id: val,
          implant_code: props.code,
          implant_qoh: props.qoh,
          implant_name: props.title,
          implant_unit: '1'
        };
        await this.setState(prevState => {
          const newItems = [...prevState.ImplantsAddListed, obj];
          return { ImplantsAddListed: newItems };
        });
        this.setState({ selectImplantValue: val });
      }
    }
  }
  handleOnConfirmSubmit = async (values) => {

  	const { patient } = this.state;

    const { qms_token_id } = patient;

    let anaesthesiaTypedescription = this.state.anaesthesiaTypeList.filter(obj => { if (obj.id == values.anesthesia_type) { return obj.description } });

    if (this.state.SurgeryRequestsAddListed.length < 1 || this.state.activeDiagnosisList.length < 1) {
      if (this.state.activeDiagnosisList.length < 1) {
        notification.error({
          message: "No active diagnosis available. Can't add surgery",
          placement: 'topRight'
        });
      } else {
        notification.error({
          message: 'Select at least one surgery from Surgery drop down or select from favourites.',
          placement: 'topRight'
        });
      }
    } else if (this.state.SurgeryRequestsAddListed.findIndex(x => x.primary === true) === -1 && !this.state.edit_surgery_id) {
      notification.error({
        message: "Please Select at Least One Primary checkbox !",
        placement: 'topRight'
      });
    } else {
      this.setState({ formSubmitted: true });
      let surgeries = [...this.state.SurgeryRequestsAddListed];
      surgeries = surgeries.map((data, i) => {
        let obj = { ...data }
        obj.surgery_time = parseInt(obj.surgery_time);
        obj.surgery_site = obj.surgery_site != null ? obj.surgery_site : 0;
        obj.surgery_site_name = obj.surgery_site_name != null ? obj.surgery_site_name : '';
        return obj
      });
      let equipments = [...this.state.EquipmentAddListed];
      equipments = equipments.map((data, i) => {
        let obj = { ...data }
        obj.equipment_unit = parseInt(obj.equipment_unit);
        return obj
      });
      let implants = [...this.state.ImplantsAddListed];
      implants = implants.map((data, i) => {
        let obj = { ...data }
        obj.implant_unit = parseInt(obj.implant_unit);
        return obj
      });
      let blood_requirement_detail = { ...this.state.bloodRequirementDetail };
      if (blood_requirement_detail.hasOwnProperty('no_of_units')) {
        blood_requirement_detail.no_of_units = parseInt(blood_requirement_detail.no_of_units);
      }
      if (blood_requirement_detail.hasOwnProperty('volume_per_unit')) {
        blood_requirement_detail.volume_per_unit = parseInt(blood_requirement_detail.volume_per_unit);
      }
      //Create surgery time
      let surgeryDate = this.state.surgery_date != null ? moment(this.state.surgery_date).format('YYYY-MM-DD').toString() : null;
      let surgeryDateTime = null;
      if(surgeryDate != null)
      {
        let momentObj = moment(surgeryDate + values.ot_time, 'YYYY-MM-DDLT');
        surgeryDateTime = momentObj.format('YYYY-MM-DDTHH:mm:ss');
      }

      let PARAMS = {
        enteredBy: parseInt(LS_SERVICE.get('staff_id')),
        facilityId: parseInt(LS_SERVICE.get('FACILITY_ID')),
        encounterId: parseInt(patient.qmsToken.Booking.encounter_id),
        patientId: parseInt(patient.mhea_patient_id),
        specialityId: parseInt(LS_SERVICE.get('speciality_id')),
        enterpriseId: LS_SERVICE.get('ENTERPRISE_ID'),
        //enterpriseName: LS_SERVICE.get('ENTERPRISE_NAME'),
        facilityName: LS_SERVICE.get('call_patient_detail').hospital_name,
        entered_by_name: LS_SERVICE.get('doctor').name,
        reference_id: qms_token_id,
        surgeries: surgeries,
        indications_for_surgery: this.state.diagnosisChecked,
        surgery_types: this.state.surgeryTypeChecked,
        otId: this.state.operation_id,
        otName: this.state.operation_name,
        surgery_date: surgeryDateTime,
        infected: this.state.infectedStatus,
        isPACRequired: this.state.pacRequired == 1 ? true : false,
        blood_requirement: this.state.bloodRequirement,
        blood_requirement_detail: blood_requirement_detail,
        anesthesia_type_id: values.anesthesia_type ? values.anesthesia_type : 0,
        ot_type: this.state.otId ? this.state.otId : null,
        anesthesia_type_desc: anaesthesiaTypedescription[0]?.description ?? '',
        anesthesia_doctor_id: this.state.anesthesia_doctor != "" ? this.state.anesthesia_doctor : 0,
        anesthesia_doctor_name: this.state.anesthesia_doctor_name,
        equipments: equipments,
        implant_items: implants,
        remarks: this.state.remarks,
        otStartTime: this.state.otStartTime ? this.state.otStartTime : null,
        otEndTime: this.state.otEndTime ? this.state.otEndTime : null,
      }
      if (this.state.edit_surgery_id) {
        PARAMS.id = this.state.edit_surgery_id;
        PARAMS.surgery_request_id = this.state.surgery_request_id;
        if (this.state.bloodRequirement) {
          if (this.state.blood_requirement_id) {
            PARAMS.blood_requirement_detail.id = this.state.blood_requirement_id;
            PARAMS.blood_requirement_detail.surgery_request_id = this.state.surgery_request_id;
            PARAMS.blood_requirement_detail.blood_unit_request_id = this.state.blood_unit_request_id;
          }
        }
      }
      if (surgeries.length > 1 || (this.state.edit_surgery_id && this.state.incisionType)) {
        PARAMS.incision_type = this.state.incisionType;
      }

      const submitPromise = await axios.post(SURGERY_REQUESTS_STORE, PARAMS);
      if (submitPromise?.data?.status === true && (submitPromise?.data?.errorMsg === '' || submitPromise?.data?.errorMsg === null)) {
        this.setState({ formSubmitted: false });

        if (this.state.edit_surgery_id) {
          notification.success({
            message: 'Surgery updated Successfully.',
            placement: 'topRight'
          });
        }
        else {
          notification.success({
            message: 'Surgery added Successfully.',
            placement: 'topRight'
          });
        }
        this.handleResetForm();
        this.handleSurgeryCancel();
      } else if (submitPromise?.data?.errorMsg !== '' || submitPromise?.data?.errorMsg !== null) {
        this.setState({ formSubmitted: false });
        notification.error({
          message: submitPromise?.data?.errorMsg,
          placement: 'topRight'
        });
      } else {
        this.setState({ formSubmitted: false });
        notification.error({
          message: submitPromise?.data?.message,
          placement: 'topRight'
        });
      }
    }
  }

  // For Modal popup
  handleModalPopup = (e, popupName) => {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({
      [popupName]: !this.state[popupName],
      actionText: "Add"
    });
  }
  
  clearEdit(form = "") {
    if (form === "assessmentFormRef") {
      this.assessmentFormRef.current.resetFields();
    }
  }

  surgeryListLoad () {
  	var SpanData = true;
  	var surgery_length = this.state.existingSurgeriesList.length;
  	return this.state.existingSurgeriesList.map((result, i) => {
  		let htmlData = (
        <tr>
          <td>{result.surgery_name}</td>
          <td>{result.priority_desc}</td>
          <td>{result.surgery_surgeon_name}</td>
          { SpanData ?
          	<> 
		          <td rowSpan={surgery_length}>{moment(result.surgery_date).subtract('19800','seconds').format("YYYY-MM-DD hh:mm A")}</td>
		          <td rowSpan={surgery_length}>{result.otName}</td>
		          <td rowSpan={surgery_length}>{result.isPACRequired ? "Yes" : "No"}</td>
		          <td rowSpan={surgery_length}>{result.indications_for_surgery}</td>
		          <td rowSpan={surgery_length}>{result.remarks}</td>
		          <td rowSpan={surgery_length}><Button className='btn nostyle-link delete-assessment p-0' onClick={() => { this.viewOperativeNotes(result); }}><i className='icon_view'></i></Button></td>
	          </>
	          :
	          null
	        }
        </tr>
      );
      SpanData = false;
      return htmlData;
    });
  } catch (err) {
    console.log('surgeryListLoad: ', err);
  }

  implantListLoad () {

  	if ((!this.state.implantsData || this.state.implantsData.length === 0)) {
      let htmlData = (
        <tr className="view_details">
          <td className="inner_table text-center" colSpan={4}>No Records Found.</td>
        </tr>
      )
      return htmlData;
    }
  	return this.state.implantsData.map((result, i) => {
  		let htmlData = (
        <tr>
          <td>{result.implant_code}</td>
          <td>{result.implant_name}</td>
          <td>{result.implant_qoh}</td>
          <td>{result.implant_unit}</td>
        </tr>
      )
      return htmlData;
    });
  } catch (err) {
    console.log('implantListLoad: ', err);
  }

  equipmentListLoad () {
  	if ((!this.state.equipmentsData || this.state.equipmentsData.length === 0)) {
      let htmlData = (
        <tr className="view_details">
          <td className="inner_table text-center" colSpan={4}>No Records Found.</td>
        </tr>
      )
      return htmlData;
    }
  	return this.state.equipmentsData.map((result, i) => {
  		let htmlData = (
        <tr>
          <td>{result.equipment_code}</td>
          <td>{result.equipment_name}</td>
          <td>{result.equipment_qoh}</td>
          <td>{result.equipment_unit}</td>
        </tr>
      )
      return htmlData;
    });
  } catch (err) {
    console.log('equipmentListLoad: ', err);
  }
  
  optionPriorityLoad() {
    if (!this.state.DRUGSConstants.drugUrgency || this.state.DRUGSConstants.drugUrgency.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.drugUrgency.map((obj, i) => {
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  handleChangeOT = async (value, props) => {
    this.setState({
      operation_id: parseInt(props.value),
      operation_name: props?.title,
      otStartTime: props?.startTimeStr,
      otEndTime: props?.endTimeStr,
      date_picker_disabled: false
    });
  }
  handleDatePickerChange = async (date, dateString, field) => {
    this.conditionFormRef.current.setFieldsValue({
      ot_time: null
    });
    this.setState({
      [field]: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
      date_picker_disabled: false,
    }, async () => {
      //call HIS API for available slots
      const PARAMS = {
        "enterpriseId": LS_SERVICE.get('ENTERPRISE_ID'),
        "facilityId": LS_SERVICE.get('FACILITY_ID'),
        "otId": this.state.operation_id,
        "surgeryRequestDate": new Date(this.state.surgery_date)
      }
      axios.post(GET_AVAILABLE_OT_SLOTS, PARAMS).then(async success => {
        if (success.status == 200 && success?.data?.status == true) {
          if (success?.data?.data?.selectedOTTime.length > 0) {
            this.state.allAvailableSlots = await this.filterAvailableSlots(success?.data?.data?.selectedOTTime);
            this.setState({
              time_picker_disabled: false,
              //showView: true
            });
            let current = new Date();
            let selectdate = moment(current).format("YYYY-MM-DD");
            let todaydate = moment(date).format("YYYY-MM-DD");
            if (selectdate == todaydate) {
              this.setState({
                allAvailableSlots: await this.filterTodayAvailableSlots(this.state.allAvailableSlots)
              })
            }
          }
        }
      }).catch(err => console.log(err))
    });
  }
  filterTodayAvailableSlots = async (arr) => {
    let current = new Date();
    return await arr.filter(function (el) {
      return moment(el.selectedOTTimeStr, 'hh:mm A').format('HH:mm') > moment(current).format('HH:mm')
    });
  }
  filterAvailableSlots = async (arr) => {
    return await arr.filter(function (el) {
      return el.isOccupy == false
    });
  }

  handleChangeAnesthesia = async (value, props) => {
    await this.setState({
      anesthesia_type: parseInt(value),
      anesthesia_type_desc: props?.title
    });
  }
  handleChangeAnesthesiaDoctor = async (value, props) => {
    await this.setState({
      anesthesia_doctor: parseInt(value),
      anesthesia_doctor_name: props?.title
    });
  }
  autoHeight = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  }

	render() {
		const { surgeryList, surgeryTypeList, anaesthesiaTypeList, allOTList, allAvailableSlots, anaesthesiaDoctorList, surgeryTypeChecked, incisionTypeList, favSurgeryList, activeDiagnosisList, diagnosisChecked, SurgeryRequestsAddListed, siteList, specialityList, docList, estTimeType, equipmentsList, implantsList, EquipmentAddListed, ImplantsAddListed, surgeryOtDataRequired } = this.state;
		const textareaSizeDefault = { minRows: 4, maxRows: 6 };
		return (
  		<Fragment>
  			<Form
          layout="vertical"
          className="emrfrm"
          ref={this.conditionFormRef}
          validateMessages={HANDLEVALIDANTMESSAGES}
          onFinish={this.handleOnConfirmSubmit}
        >
        	{ this.state.viewMode == false ? 
        		<>
			  			<div className='row'>
			          <div className='col-6'>
			            <div className='row'>
			              <div className='col-12'>
			                <div className="antSelectItem hasdata">
			                    <Select showSearch style={{ width: 100 + '%' }}
			                    	id="ns_surgery_name"
			                    	name="ns_surgery_name"
			                      className="select-diagnosis"
			                    	notFoundContent={this.state.fetching_surgery ? LOADER_BLACK : null}
			                    	loading={this.state.fetching_surgery}
			                      onChange={this.onSelectSurgeryChange}
			                      onSearch={this.onSurgerySearch}
			                      value={this.state.selectSurgeryValue}
			                      filterOption={false}>
			                      {surgeryList !== undefined && surgeryList.length > 0 ? surgeryList.map((obj, i) => (
			                        <Option key={i} serviceid={obj.serviceId} value={obj.id} type={obj.department} /* fav={obj.fav} */ title={obj.serviceLabel} surgery_code={obj.testCode} instructions={obj.orderInstructions}>{obj.serviceLabel}{/* <span className="float-right text-primary"><i className={obj.fav ? 'icon_star favourite mr-2' : 'icon_star mr-2'}></i></span> */}</Option>
			                      )) : null}
			                    </Select>
			                </div>
			              </div>
			            </div>
			            <div className='row mt-3'>
			              {favSurgeryList !== undefined && favSurgeryList.length > 0 ? favSurgeryList.map((obj, i) => (
		              		<div className='col-12'>
		                    <div key={i} className="custom-control custom-checkbox mr-2">
		                      <input type="checkbox" className="custom-control-input" id={`surgery_checkbox_${i}`} data-id={`surgery_checkbox_${i}`} name={`surgery_checkbox_${i}`} checked={SurgeryRequestsAddListed.findIndex(x => x.surgery_id === obj.test_id) !== -1} onChange={e => this.handleInputChange(e, obj)} />
		                      <label class="custom-control-label" htmlFor={`surgery_checkbox_${i}`}>{obj.descriptionWithCode}</label>
		                    </div>
		                  </div>
		                )) : null}
			            </div>
			          </div>

			          <div className='col-6'>
			            <div className='container-fluid' style={{ backgroundColor: '#eff0f0', padding: '0.75rem 0.5rem', border: 'solid 1px #ccc' }}>
			              <div className='row'>
			                <h6 className='col-12 mb-0'>INDICATION FOR SURGERY</h6>
			              </div>
			              <div className='row mt-3'>
			              	<div className='col-12'>
				                {(!activeDiagnosisList || activeDiagnosisList.length === 0) ? 'No active diagnosis.' :
			                      <Form.List
			                        name="diagnosis"
			                        rules={[
			                          {
			                            validator: async (_, diagnosis) => {
			                              if (diagnosis.filter((d, i) => d.surgery_diagnosis === true).length == 0) {
			                                return Promise.reject(new Error('Select at least one diagnosis for surgery'));
			                              }
			                            },
			                          },
			                        ]}>
			                        {([], { }, { errors }) => (
			                          <>
			                            {activeDiagnosisList.map((data, i) => (
			                              <React.Fragment key={i}>
			                                <Form.Item className="mb-0" initialValue={activeDiagnosisList.length == 1 ? true : false} valuePropName="checked" name={[i, 'surgery_diagnosis']} fieldKey={[i, 'surgery_diagnosis']} onChange={() => this.handleDiagnosisChange(data)}>
			                                  <Checkbox> {(data.description) ? data.description + ' ' : data.diagnosisNotes || data.diagnosis_notes
			                                      + ' '}{data.icd_code ? data.icd_code : ""}
			                                  </Checkbox>
			                                </Form.Item>
			                              </React.Fragment>
			                            ))}
			                            <Form.ErrorList errors={errors} />
			                          </>
			                        )}
			                      </Form.List>
			                    }
		                    </div>
			              </div>
			            </div>
			            <div className='row mt-3'>
			              <div className='col-12'>
			                <div className='d-flex'>
			                  <div className='font-weight-bold text-grey'>Surgery Type:&ensp;</div>
				                  {surgeryTypeList !== undefined && surgeryTypeList.length > 0 ? surgeryTypeList.map((obj, i) => (
			                    	<div key={i} className="custom-control custom-checkbox custom-control-inline mr-5">
				                      <input type="checkbox" className="custom-control-input" id={`surgery_type_checkbox_${i}`} data-id={`surgery_type_checkbox_${i}`} name={`surgery_type_checkbox_${i}`} /* checked={surgeryTypeChecked.includes(obj.id)} */ checked={surgeryTypeChecked.findIndex(x => x.surgery_type_id === obj.id) !== -1} onChange={e => this.handleSurgeryTypeChange(e, obj)} />
				                      <label class="custom-control-label" htmlFor={`surgery_type_checkbox_${i}`}>{obj.description}</label>
		                    		</div>
		                  		)) : null}
			                	</div>
			              	</div>
			            	</div>
			          	</div>
			        	</div>
			        <div className='row mt-3' style={{ display: this.state.SurgeryRequestsAddTable ? 'block' : 'none' }}>
			          <div className='col-12'>
			            <div className="table-responsive" id="tempo">
			              <table className="table table-bordered rounded-0 surgerytable">
			                <thead>
			                  <tr>
			                    <th valign="middle" width="5%">S.No.</th>
			                    <th valign="middle" width="7%">CODE</th>
			                    <th valign="middle" width="15%">SURGERY NAME</th>
			                    <th valign="middle" width="12%">PRIORITY</th>
			                    <th valign="middle" width="7%">PRIMARY </th>
			                    <th valign="middle" width="10%">SURGERY SITE</th>
			                    <th valign="middle" width="10%">SPECIALITY</th>
			                    <th valign="middle" width="10%">SURGEON</th>
			                    <th className="text-center" valign="middle" width="14%">Estimated Duration</th>
			                    <th valign="middle" width="5%">fav</th>
			                    <th valign="middle" width="5%">del</th>
			                  </tr>
			                </thead>
			                <tbody>
			                  {(!SurgeryRequestsAddListed || SurgeryRequestsAddListed.length === 0) ? null :
		                      <Form.List name="surgeries">
		                        {() => (
		                          <>
		                            {SurgeryRequestsAddListed.map((data, i) => (
		                              <tr key={i}>
		                                <td valign="middle">{(i + 1)}</td>
		                                <td valign="middle">{data.surgery_code}</td>
		                                <td valign="middle">{data.surgery_name}</td>
		                                <td valign="middle" id="Priority">
		                                  <Select showSearch
		                                    style={{ width: 100 + '%' }}
		                                    id="priority"
		                                    name="priority"
		                                    onChange={(val, props) => this.onSelectChangeList(val, props, 'priority', i)}
		                                    value={data.priority}
                                        disabled={!surgeryOtDataRequired}
		                                    getPopupContainer={() => document.getElementById('tempo')}
		                                    className="drug-input Priority"
		                                    filterOption={(input, option) => {
		                                      return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
		                                    }}>
		                                    {this.optionPriorityLoad()}
		                                  </Select>
		                                </td>
		                                <td valign="middle">
		                                  <div className="custom-control custom-checkbox custom-control-inline ml-3">
		                                    <input type="checkbox" className="custom-control-input" id={`primary_checkbox_${i}`} data-id={`primary_checkbox_${i}`} name={`primary_checkbox_${i}`} disabled={SurgeryRequestsAddListed.findIndex(x => x.primary === true) !== -1 && !data.primary} checked={data.primary} onChange={e => this.handlePrimaryChange(e, data, 'primary', i)} />
		                                    <label class="custom-control-label" htmlFor={`primary_checkbox_${i}`}>&nbsp;</label>
		                                  </div>
		                                </td>
		                                <td valign="middle">
		                                  <div className={data.surgery_site ? 'antSelectItem mt-1 hasdata position-relative' : 'antSelectItem mt-1 position-relative'} id="surgery_sitee">
		                                    <Form.Item name={[i, 'surgery_site']} fieldKey={[i, 'surgery_site']} label="Site"
		                                  		>
		                                      <Select showSearch id={`surgery_site_${i}`} name={`surgery_site_${i}`} style={{ width: 100 + '%' }}
		                                        optionFilterProp="children"
		                                        className="select-diagnosis"
		                                        onChange={(val, props) => this.onSelectChangeList(val, props, 'surgery_site', i)}
		                                        defaultValue={data.surgery_site}
		                                        value={data.surgery_site}
		                                        defaultActiveFirstOption
		                                        filterOption={(input, option) => {
		                                          return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
		                                        }}
		                                        getPopupContainer={() => document.getElementById('tempo')}>
		                                        {siteList !== undefined && siteList.length > 0 ? siteList.map((d, i) => {
		                                          return <Option key={i} value={d.id} label={d.description}>{d.description}</Option>
		                                        }) : null}
		                                      </Select>
		                                    </Form.Item>
		                                  </div>
		                                </td>
		                                <td valign="middle">
		                                  <div className={data.surgery_speciality ? 'antSelectItem mt-1 hasdata position-relative' : 'antSelectItem mt-1 position-relative'} id="Speciality">
		                                    <Form.Item initialValue={data.surgery_speciality} name={[i, 'surgery_speciality']} fieldKey={[i, 'surgery_speciality']} label="Speciality"
		                                      rules={[{
		                                        required: true,
		                                        message: 'Missing speciality.',
		                                      }]}>
		                                      <Select showSearch id={`surgery_speciality${i}`} name={`surgery_speciality${i}`} style={{ width: 100 + '%' }}
		                                        optionFilterProp="children"
		                                        className="select-diagnosis"

		                                        onChange={(val, props) => this.onSelectChangeList(val, props, 'surgery_speciality', i)}
		                                        defaultValue={data.surgery_speciality}
		                                        filterOption={(input, option) => {
		                                          return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
		                                        }}
		                                        getPopupContainer={() => document.getElementById('tempo')}>
		                                        {specialityList !== undefined && specialityList.length > 0 ? specialityList.map((d, i) => (
		                                          <Option key={i} code={d.code} value={d.id} label={d.description}>{d.description}</Option>
		                                        )) : null}
		                                      </Select>
		                                    </Form.Item>
		                                  </div>
		                                </td>
		                                <td valign="middle">
		                                  <div className={data.surgery_surgeon ? 'antSelectItem mt-1 hasdata position-relative' : 'antSelectItem mt-1 position-relative'} >
		                                    <Form.Item initialValue={data.surgery_surgeon} name={[i, 'surgery_surgeon']} fieldKey={[i, 'surgery_surgeon']} label="Surgeon"
		                                      rules={[{
		                                        required: true,
		                                        message: 'Missing surgeon.',
		                                      }]}>
		                                      <Select showSearch id={`surgery_surgeon${i}`} name={`surgery_surgeon${i}`} style={{ width: 100 + '%' }}
		                                        optionFilterProp="children"
		                                        className="select-diagnosis"
		                                        onChange={(val, props) => this.onSelectChangeList(val, props, 'surgery_surgeon', i)}
		                                        defaultValue={data.surgery_surgeon}
		                                        filterOption={(input, option) => {
		                                          return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
		                                        }}
		                                        getPopupContainer={() => document.getElementById('tempo')}>
		                                        {docList[i] !== undefined && docList[i].length > 0 ? docList[i].map((d, i) => (
		                                          <Option key={i} code={d.code} value={d.id} label={d.empName}>{d.titleName} {d.empName}</Option>
		                                        )) : null}
		                                      </Select>
		                                    </Form.Item>
		                                  </div>
		                                </td>
		                                <td valign="middle">
		                                  <div className="row mt-1">
		                                    <div className={`col-5 pr-0 time${i}`}>
		                                      <div className={data.surgery_time ? 'antInputItem hasdata' : 'antInputItem'}>
		                                        <Form.Item initialValue={data.surgery_time} name={[i, 'surgery_time']} fieldKey={[i, 'surgery_time']} label={data.surgery_est_timetype == 1 ? 'Min' : 'Hrs'} id={`surgery_time${i}`}
		                                          rules={[
		                                            { required: true, message: 'Required.' },
		                                            { pattern: /^\d+$/, message: 'Not a number!' },
		                                            {
		                                              validator: async (_, surgery_time) => {
		                                                if (surgery_time <= 0 && surgery_time != "") {
		                                                  return Promise.reject(new Error("Estimated duration min 1"));
		                                                }
		                                                if (data.surgery_est_timetype == 1) {
		                                                  if (surgery_time < 30 && surgery_time != "") {
		                                                    return Promise.reject(new Error("Min 30 mins required"));
		                                                  } else if (surgery_time % 30 !== 0 && surgery_time > 30 && surgery_time != "") {
		                                                    return Promise.reject(new Error("Estimated duration should be multiple of 30 mins"));
		                                                  }
		                                                } else {
		                                                  if ((surgery_time * 60) % 30 !== 0 && surgery_time > 30 && surgery_time != "" && surgery_time > 0) {
		                                                    return Promise.reject(new Error("Estimated duration should be multiple of 30 mins"));
		                                                  }
		                                                }
		                                              },
		                                            },
		                                          ]}
		                                          onChange={(e) => this.handleChange(e, 'surgery_time', i)}
		                                        >
		                                          <Input defaultValue={data.surgery_time} value={data.surgery_time} autoComplete="off" maxLength={3} />
		                                        </Form.Item>
		                                      </div>
		                                    </div>
		                                    <div className="col-7 pl-1">
		                                      <div className="antSelectItem position-relative" /* style={{ display: 'inline-block' }} */>
		                                        <Form.Item initialValue={data.surgery_est_timetype} name={[i, 'surgery_est_timetype']} fieldKey={[i, 'surgery_est_timetype']}
		                                          rules={[{
		                                            required: true,
		                                            message: 'Missing time.',
		                                          }]}>
		                                          <Select id={`surgery_est_timetype${i}`} name={`surgery_est_timetype${i}`}
		                                            className="select-diagnosis"
		                                            onChange={(val, props) => this.onSelectChangeList(val, props, 'surgery_est_timetype', i)}
		                                            defaultValue={data.surgery_est_timetype}
		                                            value={data.surgery_est_timetype}
		                                            getPopupContainer={() => document.getElementById('tempo')}>
		                                            {estTimeType !== undefined && estTimeType.length > 0 ? estTimeType.map((d, i) => (
		                                              <Option key={i} value={d.id} label={d.description}>{d.description}</Option>
		                                            )) : null}
		                                          </Select>
		                                        </Form.Item>
		                                      </div>
		                                    </div>
		                                  </div>
		                                </td>
		                                <td valign="middle" className="text-center">
		                                  <Button className='btn nostyle-link p-0' onClick={() => { this.markFavSurgery(data); }}>
		                                    <i className={`icon_star ${favSurgeryList.findIndex(x => x.test_id === data.surgery_id) !== -1 ? 'favourite' : ''}`} />
		                                  </Button>
		                                </td>
		                                <td valign="middle" className="text-center">
		                                  <Tooltip placement="topLeft" arrowPointAtCenter={true} title="Delete" >
		                                    <Button className='btn nostyle-link delete-assessment p-0' onClick={() => { this.callDelete(i, 'surgery') }}>
		                                      <i className='icon_delete'></i>
		                                    </Button>
		                                  </Tooltip>
		                                </td>
		                              </tr>
		                            ))}
		                          </>
		                        )}
		                      </Form.List>
		                    }
			                </tbody>
			              </table>
			              </div>
			          </div>
			        </div>

			        <div className='row mt-4'>
			          <div className='col-7'>
			            <div className='row'>
			              <div className='col-4'>
			                <div className={(this.state.anesthesia_doctor || this.state.anesthesia_doctor) ? 'antInputItem hasdata' : 'antInputItem hasdata'} id="anesthesia_doctor">
			                  <Form.Item name="anesthesia_doctor" label="Anaesthetist">
			                    <Select showSearch
			                      placeholder="Select"
			                      style={{ width: 100 + '%' }}
			                      id="anesthesia_doctor"
			                      name="anesthesia_doctor"
			                      onChange={this.handleChangeAnesthesiaDoctor}
			                      className="select-drugs"
			                      value={this.state.anesthesia_doctor}
			                      filterOption={(input, option) => {
			                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
			                      }}
			                      getPopupContainer={() => document.getElementById('anesthesia_doctor')}>
			                      {anaesthesiaDoctorList !== undefined && anaesthesiaDoctorList.length > 0 ? anaesthesiaDoctorList.map((obj, i) => (
			                        <Option key={i} value={obj.id} title={obj.empName}>{obj.titleName} {obj.empName}</Option>
			                      )) : null}
			                    </Select>
		                  	</Form.Item>
			                </div>
			              </div>

			              <div className='col-4'>
			                <div className={(this.state.anesthesia_type || this.state.anesthesia_type) ? 'antInputItem hasdata'
		                  : 'antInputItem hasdata'} id="anesthesia_type">
			                  <Form.Item name="anesthesia_type" label="Anaesthesia Type">
			                    <Select showSearch
			                      placeholder="Select"
			                      style={{ width: 100 + '%' }}
			                      id="anesthesia_type"
			                      name="anesthesia_type"
			                      onChange={this.handleChangeAnesthesia}
			                      className="select-drugs"
			                      value={this.state.anesthesia_type}
			                      filterOption={(input, option) => {
			                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
			                      }}
			                      getPopupContainer={() => document.getElementById('anesthesia_type')}>
			                      {anaesthesiaTypeList !== undefined && anaesthesiaTypeList.length > 0 ? anaesthesiaTypeList.map((obj, i) => (
			                        <Option key={i} value={obj.id} title={obj.description} anaesthesia_code={obj.code}>{obj.description}</Option>
			                      )) : null}
			                    </Select>
			                  </Form.Item>
		                	</div>
			              </div>
			              <div className='col-4'>
			                <div className={(this.state.allOTList || this.state.allOTList) ? 'antInputItem hasdata position-relative'
		                  : 'antInputItem hasdata positxion-relative'} id="ot_type">
			                  <Form.Item name="ot_type" label="Operation Theater" rules={[{ required: surgeryOtDataRequired, message: "Please fill the required field." }]}>
			                    <Select showSearch
			                      placeholder="Select"
			                      style={{ width: 100 + '%' }}
			                      id="ot_type"
			                      name="ot_type"
			                      onChange={this.handleChangeOT}
			                      className="select-drugs"
			                      filterOption={(input, option) => {
			                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
			                      }}
			                      getPopupContainer={() => document.getElementById('ot_type')}>
			                      {allOTList !== undefined && allOTList.length > 0 ? allOTList.map((obj, i) => (
			                        <Option key={i} value={obj.id} title={obj.orName} or_code={obj.orCode} startTimeStr={obj.startTimeStr} endTimeStr={obj.endTimeStr}>{obj.orName}</Option>
			                      )) : null}
			                    </Select>
			                  </Form.Item>
		                	</div>
			              </div>
			            </div>
			          </div>

			          <div className='col-5'>
			            <div className='row'>
			              <div className='col-6'>
			                <div className={(this.state.surgery_date || this.state.calander_open) ? 'antInputItem hasdata position-relative'
		                  : 'antInputItem hasdata positxion-relative'} id="surgery_dates">
			                  <Form.Item name="surgery_date" id="surgery_date" label="Proposed Date" rules={[{ type: 'object', required: surgeryOtDataRequired, message: "Please fill the required field." }]} >
			                    <DatePicker
			                      scrollableYearDropdown
			                      yearDropdownItemNumber={100}
			                      format="DD MMM, YYYY"
			                      disabledDate={(current) => {
			                        return current && current < moment().add(-1, 'days').endOf('day');
			                      }}
			                      showNow={true}
			                      className="form-control datetimepicker"
			                      onChange={(date, dateString) => this.handleDatePickerChange(date, dateString, 'surgery_date')}
			                      onClick={() => this.datePickerStatus(true)}
			                      onOpenChange={this.datePickerStatus}
			                      getPopupContainer={() => document.getElementById('surgery_dates')} />
			                  </Form.Item>
		                	</div>
			              </div>
			              <div className='col-6'>
			                <div className={(allAvailableSlots && allAvailableSlots.length) ? 'antInputItem hasdata position-relative'
		                  : 'antInputItem hasdata positxion-relative'} id="ot_time">
			                  <Form.Item name="ot_time" label="Available Time Slots" rules={[{ required: surgeryOtDataRequired, message: "Please fill the required field." }]}>
			                    <Select
			                      //disabled={this.state.time_picker_disabled} 
			                      showSearch placeholder="Select"
			                      style={{ width: 100 + '%' }} id="ot_time" name="ot_time" value={this.state.ot_time} onChange={this.handleTimePickerChange} className="select-drugs"
			                      filterOption={(input, option) => {
			                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
			                      }}
			                      getPopupContainer={() => document.getElementById('ot_time')}>
			                      {allAvailableSlots !== undefined && allAvailableSlots.length > 0 ? allAvailableSlots.map((obj, i) => (
			                        <Option key={i} value={obj.selectedOTTimeStr} title={obj.selectedOTTimeStr} >{obj.selectedOTTimeStr}</Option>
			                      )) : null}
			                    </Select>
			                  </Form.Item>
		                	</div>
			              </div>
			            </div>

			          </div>

			        </div>

			        <div className='row mt-2'>
			          <div className='col-6 d-flex align-items-center'>
			            <label className="d-inline font-weight-semibold text-dark mr-3 size-h6 mb-0">Infected <span className='text-danger'>*</span> </label>
			            <div className="mr-2 d-md-inline">
			              <div className="custom-control custom-radio custom-control-inline mr-4">
		                  <input type="radio" id="frequency-Yes" name="infectedStatus" className="custom-control-input" value={1} checked={this.state.infectedStatus === 1} onChange={this.handleRadio} />
		                  <label className="custom-control-label" htmlFor="frequency-Yes">Yes</label>
		                </div>
		                <div className="custom-control custom-radio custom-control-inline mr-4">
		                  <input type="radio" id="frequency-No" name="infectedStatus" className="custom-control-input" value={0} checked={this.state.infectedStatus === 0} onChange={this.handleRadio} />
		                  <label className="custom-control-label" htmlFor="frequency-No">No</label>
		                </div>
			            </div>
			          </div>
			          <div className='col-6 d-flex align-items-center'>
			            <label className="d-inline font-weight-semibold text-dark mr-3 size-h6 mb-0">PAC Clearance <span className='text-danger'>*</span> </label>
			            <div className="mr-2 d-md-inline">
			              <div className="custom-control custom-radio custom-control-inline mr-4">
		                  <input type="radio" id="pac-Yes" name="pacRequired" className="custom-control-input" value={1} checked={this.state.pacRequired === 1} onChange={this.handleRadio} />
		                  <label className="custom-control-label" htmlFor="pac-Yes">Yes</label>
		                </div>
		                <div className="custom-control custom-radio custom-control-inline mr-4">
		                  <input type="radio" id="pac-No" name="pacRequired" className="custom-control-input" value={0} checked={this.state.pacRequired === 0} onChange={this.handleRadio} />
		                  <label className="custom-control-label" htmlFor="pac-No">No</label>
		                </div>
			            </div>
			          </div>
			          <div className="col-2 mt-2" style={{ display: SurgeryRequestsAddListed.length > 1 ? 'block' : 'none' }}>
		              <div className={this.state.incisionType ? 'antSelectItem hasdata' : 'antSelectItem'}>
		                <Form.Item initialValue={this.state.incisionType} name="incision_type" label="Incision Type"
		                  rules={[{
		                    required: SurgeryRequestsAddListed.length > 1,
		                    message: 'Missing incision type.',
		                  }]}>
		                  <Select name="incision_type" style={{ width: 100 + '%' }} disabled={this.state.billVerified} className="select-diagnosis"
		                    onChange={(val, props) => this.onSelectChangeList(val, props, 'incision_type')} defaultValue={this.state.incisionType}>
		                    {incisionTypeList !== undefined && incisionTypeList.length > 0 ? incisionTypeList.map((d, i) => (
		                      <Option key={i} value={d.id} label={d.description}>{d.description}</Option>
		                    )) : null}
		                  </Select>
		                </Form.Item>
		              </div>
		            </div>
			        </div>

			        <div className="row mt-3">
		            <div className="col card cardbg p-0 mr-2">
		              <div style={{ backgroundColor: '#8c8c8c' }}>
		                <h6 className="card-title text-light p-2 m-0 border-radius">EQUIPMENT</h6>
		              </div>
		              <div className="col-md-12 pt-2 pl-2 ">
		                <div className="form-group mt-2 position-relative" id="ns_equipments">
		                  <Select showSearch id="ns_equipment" name="ns_equipment" style={{ width: 100 + '%' }}
		                    onChange={this.onSelectEquipmentChange} disabled={this.state.billVerified} className="select-drugs" value={this.state.selectEquipmentValue}
		                    filterOption={(input, option) => {
		                      return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
		                    }}
		                    getPopupContainer={() => document.getElementById('ns_equipments')}>
		                    {equipmentsList !== undefined && equipmentsList.length > 0 ? equipmentsList.map((obj, i) => (
		                      <Option key={i} value={obj.id} title={obj.description} code={obj.code} qoh={obj.qoh}>{obj.description}</Option>
		                    )) : null}
		                  </Select>
		                  <label htmlFor="ns_equipment">Equipment</label>
		                </div>
		                <div className="row my-2" style={{ display: EquipmentAddListed.length ? 'block' : 'none' }}>
		                  <div className="col-md-12 table-responsive">
		                    <table className="table table-bordered rounded-0 surgerytable">
		                      <thead>
		                        <tr>
		                          <th valign="middle" width="50px">S.NO.</th>
		                          <th valign="middle" width="160px">Equipment</th>
		                          <th valign="middle" width="70px">QOH</th>
		                          <th valign="middle" width="70px">UNIT</th>
		                          <th valign="middle" width="50px">delete</th>
		                        </tr>
		                      </thead>
		                      <tbody>
		                        {(!EquipmentAddListed || EquipmentAddListed.length === 0) ? null :
		                          <Form.List name="equipments">
		                            {() => (
		                              <>
		                                {EquipmentAddListed.map((data, i) => (
		                                  <tr key={i}>
		                                    <td valign="middle" align="center">{(i + 1)}</td>
		                                    <td valign="middle" align="center">{data.equipment_name}</td>
		                                    <td valign="middle" align="center">{data.equipment_qoh}</td>
		                                    <td>
		                                      <div className={data.equipment_unit ? 'antInputItem mt-2 hasdata' : 'antInputItem mt-2 hasdata'}>
		                                        <Form.Item initialValue={data.equipment_unit ? data.equipment_unit : '1'} name={[i, 'equipment_unit']} fieldKey={[i, 'equipment_unit']} id={`equipment_unit${i}`} label='Unit'
		                                          onChange={(e) => this.handleChange(e, 'equipment_unit', i)}
		                                          rules={[
		                                            { required: true, message: 'Required.' },
		                                            { pattern: /^\d+$/, message: 'Not a number!' },
		                                            { max: 1, message: 'Max 1 digit.' }
		                                          ]}>
		                                          <Input disabled={this.state.billVerified} defaultValue={data.equipment_unit} className="text-right" />
		                                        </Form.Item>
		                                      </div>
		                                    </td>
		                                    <td valign="middle" className="text-center">
		                                      <Tooltip placement="topLeft" arrowPointAtCenter={true} title="Delete" >
		                                        <Button disabled={this.state.billVerified} className='btn nostyle-link delete-assessment p-0' onClick={() => { this.callDelete(i, 'equipment') }}>
		                                          <i className='icon_delete'></i>
		                                        </Button>
		                                      </Tooltip>
		                                    </td>
		                                  </tr>
		                                ))}
		                              </>
		                            )}
		                          </Form.List>
		                        }
		                      </tbody>
		                    </table>
		                  </div>
		                </div>
		              </div>
		            </div>
		            <div className="col card cardbg p-0">
		              <div style={{ backgroundColor: '#8c8c8c' }}>
		                <h6 className="card-title text-light p-2 m-0 border-radius">IMPLANTS</h6>
		              </div>
		              <div className="col-md-12 pt-2 pl-2 ">
		                <div className="form-group mt-2 position-relative" id="ns_implant">
		                  <Select disabled={this.state.billVerified} showSearch id="ns_implants" name="ns_implants" style={{ width: 100 + '%' }}
		                    onChange={this.onSelectImplantChange}
		                    className="select-drugs"
		                    value={this.state.selectImplantValue}
		                    filterOption={(input, option) => {
		                      return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
		                    }}
		                    getPopupContainer={() => document.getElementById('ns_implant')}>
		                    {implantsList !== undefined && implantsList.length > 0 ? implantsList.map((obj, i) => (
		                      <Option key={i} value={obj.id} title={obj.label} code={obj.code} qoh={obj.qoh}>{obj.label}</Option>
		                    )) : null}
		                  </Select>
		                  <label htmlFor="ns_implants">Implants</label>
		                </div>
		                <div className="row my-2" style={{ display: ImplantsAddListed.length ? 'block' : 'none' }}>
		                  <div className="col-md-12 table-responsive">
		                    <table className="table table-bordered rounded-0 surgerytable">
		                      <thead>
		                        <tr>
		                          <th valign="middle" width="50px">S.NO.</th>
		                          <th valign="middle" width="160px">NAME</th>
		                          <th valign="middle" width="60px">QOH</th>
		                          <th valign="middle" width="60px">UNIT</th>
		                          <th valign="middle" width="50px">delete</th>
		                        </tr>
		                      </thead>
		                      <tbody>
		                        {(!ImplantsAddListed || ImplantsAddListed.length === 0) ? null :
		                          <Form.List name="implants">
		                            {() => (
		                              <>
		                                {ImplantsAddListed.map((data, i) => (
		                                  <tr key={i}>
		                                    <td valign="middle" align="center">{(i + 1)}</td>
		                                    <td valign="middle" align="center">{data.implant_name}</td>
		                                    <td valign="middle" align="center">{data.implant_qoh}</td>
		                                    <td>
		                                      <div className={data.implant_unit ? 'antInputItem mt-2 hasdata' : 'antInputItem mt-2 hasdata'}>
		                                        <Form.Item initialValue={data.implant_unit ? data.implant_unit : '1'} name={[i, 'implant_unit']} fieldKey={[i, 'implant_unit']} id={`implant_unit${i}`} label='Unit'
		                                          onChange={(e) => this.handleChange(e, 'implant_unit', i)}
		                                          rules={[
		                                            { required: true, message: 'Required.' },
		                                            { pattern: /^\d+$/, message: 'Not a number!' },
		                                            { max: 1, message: 'Max 1 digit.' }
		                                          ]}>
		                                          <Input disabled={this.state.billVerified} defaultValue={data.implant_unit} className="text-right" />
		                                        </Form.Item>
		                                      </div>
		                                    </td>
		                                    <td valign="middle" className="text-center">
		                                      <Tooltip placement="topLeft" arrowPointAtCenter={true} title="Delete" >
		                                        <Button disabled={this.state.billVerified} className='btn nostyle-link delete-assessment p-0' onClick={() => { this.callDelete(i, 'implant') }}>
		                                          <i className='icon_delete'></i>
		                                        </Button>
		                                      </Tooltip>
		                                    </td>
		                                  </tr>
		                                ))}
		                              </>
		                            )}
		                          </Form.List>
		                        }
		                      </tbody>
		                    </table>
		                  </div>
		                </div>
		              </div>
		            </div>
		          </div>


			        <div className='row mt-4'>
			          <div className='col-8'>
			            <div className={this.state.remarks ? 'antInputItem hasdata' : 'antInputItem'}>
		                <Form.Item name="remarks" id="remarks" label="Remarks" autoSize={textareaSizeDefault} onChange={(e) => this.handleChange(e, 'remarks')}>
		                  <Input.TextArea value={this.state.remarks} maxLength={255} showCount onInput={(e) => this.autoHeight(e)} />
		                </Form.Item>
		              </div>
			          </div>
			          <div className="col-4 text-right">
		                <Button type="button" key="back" className="btn btn-outline-secondary px-4 mr-3" onClick={this.handleResetForm}>
		                  CLEAR
		                </Button>
		                <Button disabled={this.state.formSubmitted} type="primary" htmlType="submit" className="btn btn-primary px-4">{this.state.submitButtonText}{this.state.formSubmitted ? LOADER : null}</Button>
			          </div>
			        </div>
		        </>

	        : null
	      }

	        { this.state.viewMode ? 

		        <div className='row mt-3'>
							<div className='col-12'>
								<div className="table-responsive">
									<table className="table table-bordered rounded-0 surgerytable">
										<thead>
											<tr>
												<th>SURGERY NAME</th>
												<th>PRIORITY</th>
												<th>SURGEON NAME</th>
												<th width="14%">REQUESTED DATE</th>
												<th>OT Name</th>
												<th>PAC Clearance</th>
												<th>DIAGNOSIS</th>
												<th>COMMENTS</th>
												<th>OPERATIVE DETAILS</th>
											</tr>
										</thead>
										<tbody>
											{ this.surgeryListLoad() }
										</tbody>
									</table>
								</div>
							</div>
						</div>
						:
						null
					}
        </Form>

        {/* Common Modal for deleting */}
        <Modal id="alert_confirm_message"
          style={{ top: '25%', border: '1px solid #ccc', boxShadow: '0px 0px 5px #eee', padding: '0px' }}
          wrapClassName="ant-modal-wrap1" className="alert_confirm_message" title={this.state.deleteModalTitle}
          visible={this.state.isDeleteAlertVisible} onOk={() => this.handleDeleteModalOk()}
          bodyStyle={{ padding: 0 + 'px' }} onCancel={() => this.setState({ isDeleteAlertVisible: false })} closable={false}
          footer={[
            <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={() => this.setState({ isDeleteAlertVisible: false })}>
              CANCEL
            </Button>,
            <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" onClick={() => this.handleDeleteModalOk()}>
              DELETE
            </Button>,
          ]}>
        </Modal>

      {/* View Modals */}
        <Modal
          destroyOnClose={true}
          title={"Operative Details"}
          visible={this.state.ViewDetailsModal}
          footer={false}
          width={850}
          onCancel={(e) => this.setState({ViewDetailsModal:false})}
          className="emrfrm surgery_modal"
        >         
        	<div className="row my-2">
            <div className="col-6 table-responsive">
              <table className="table table-bordered rounded-0 surgerytable">
                <thead>
                  <tr>
										<th>Equipment CODE</th>
										<th>NAME</th>
										<th>QOH</th>
										<th>QUANTITY</th>
									</tr>
                </thead>
                <tbody>
                { this.equipmentListLoad() }
                </tbody>
              </table>
            </div>
            <div className='col-6'>
								<div className="table-responsive">
									<table className="table table-bordered rounded-0 surgerytable">
										<thead>
											<tr>
												<th>IMPLANT CODE</th>
												<th>NAME</th>
												<th>QOH</th>
												<th>QUANTITY</th>
											</tr>
										</thead>
										<tbody>
											{ this.implantListLoad() }
										</tbody>
									</table>
								</div>
							</div>
          </div>
        </Modal>
  		</Fragment>
		);
  }
}

export default NewSurgeryForm;