import Axios from 'axios';
import axios from 'axios';
import React, { Component } from 'react';
import qs from 'qs';
import LS_SERVICE from '../../../utils/localStorage';
import {
  REFERENCE_TYPE,
  LOADER_RED,
  PATS_VISIT,
  LOADER,
  DIAGNOSIS_DO_NOT_PRINT,
  COMPLETED_DOCTOR_VISIT,
  SUBJECTIVE_INFRMATION_CHIEF_COMPLAINTS,
  TRAINING_DATA_API,
  TRAINING_DATA_AUTH_TOKEN,
  SUBJECTIVE_STORE_CHIEF_COMPLAINTS,
  SOAP_DETAILS,
  MEDICINES_GETALL_CURRENT_MEDICINES,
  CURRENT_MEDICINES_STOP,
  VIEW_VISIT,
  HOSPITALDEFAULTS,
  CIMS_INTERACTION_EXISTS,
  CIMSINTERACTION,
  CIMSALLOW,
  GET_DEFAULT_TEXT,
  CIMS_CAREPLAN_INTERACTION_EXISTS,
  CIMS_CAREPLAN_INTERACTION
} from '../../../utils/constant';
import { Link } from 'react-router-dom';
import { headerService$, practiceService, speechService, speechTextService } from '../../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../../../pages/speech-to-text/speech-recognition";
import { jQueryLabels, FixedCta } from '../../../utils/jQueryLabels';
import { Modal, Select, Tooltip, Input, Image, notification } from 'antd';
import moment from "moment";
import MedicineTable from '../../../components/drugs/medicine-table';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import CopyToVisitSS from "../../copy-to-visit/copytovisitss";
import NotesSaveModel from "../../notes-model/NotesSaveModel";
import TextSaveImg from "../../../assets/images/textsave.png";
import { doctorNotesService } from "../../../utils/rxjs-sharing";

class ChiefComplaintsHPI extends Component {
  trainingData = [];
  constructor(props) {
    super(props);
    this.state = {
      detect_change: false,
      syncing_to_server: false,
      visitDataLoaded: false,
      patient: props.patient,
      hospital_id: props.hospital_id,
      patient_id: props.patient.patient_id,
      ss_configuration: LS_SERVICE.get('ss_configuration'),
      sourcescreen: props.sourcescreen,
      consult_type: props.consult_type,
      reference_id: props.patient.qms_token_id,
      allowEdit: props.allowEdit,
      isPracticing: props.isPracticing,
      details: null,
      subjective_fields: null,
      chief_complaint: '',
      review_of_systems: '',
      copyPrescriptionModal: false,
      showPrescriptionDetails: true,
      showFinalCopyPrescriptionModal: false,
      prescriptionDataLoading: false,
      selectPrescription: '',
      selected_prescription_id: null,
      isConfirm: false,
      medicinerefDetails: [],
      currentMedsLists: [],
      duplicateMedModel: false,
      showDupMed: [],
      presMedicine: [],
      selectedInputId: "",
      refId: "",
      polyPharmacyModal: false,
      showPolyModal: false,
      activeMed: [],
      referenceID:0,
      speech: {
        identifier: null,
        state: false
      },
      addedAssessmentList:0,
      totalVisit: 0,
      isModalInteraction:false,
      cimsallow: CIMSALLOW,
      drug_id_Interaction_Modalurl:[],
      referenceType:0,
      patientId: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
      referenceId: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
      StoreId: LS_SERVICE.get("staff_id") != undefined ? LS_SERVICE.get("staff_id") : 0,
      FacilityId: LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
      enterpriseId: LS_SERVICE.get("ENTERPRISE_ID") != undefined ? LS_SERVICE.get("ENTERPRISE_ID") : 0,
      orederSetId:0,
      prescriptionDetailsData: [],
      checkForEditsTrainingData: {
        "chief_complaint": undefined,
        "review_of_systems": undefined
      },
      trainingDatafieldtypeSetter: {
        "chief_complaint": 0,
        "review_of_systems": 2
      },
      // notesModel:false,
      content:'',
      input_type:'',
      model_type:'',
      notesModelActive:false,
      default_text : props.default_text
    };
    this.handleOnChangeprescription = this.handleOnChangeprescription.bind(this);
    this.handleProceedPrescription = this.handleProceedPrescription.bind(this);
    this.prescriptionLink =false
    this.resetModel = this.resetModel.bind(this);
    this.saveTextBoxData = this.saveTextBoxData.bind(this);
    this.resetNotesModel = this.resetNotesModel.bind(this);
  }

  callTrainingDataApi = (trainingData) => {
    Axios({
      method: 'post',
      url: TRAINING_DATA_API,
      data: trainingData,
      headers: {
        Authorization: TRAINING_DATA_AUTH_TOKEN
      }
    });
  }

  autoHeight = (e) => {
    if (e) {
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";
    }
  }

  async componentWillUnmount() {
    this.subscription.unsubscribe();
    if (this.state.detect_change) {
      await this.handleSubjectiveFormSubmit();
    }
  }

  async componentDidUpdate() {
    setTimeout(() => {
      jQueryLabels();
      FixedCta();
      let Elem = document.querySelectorAll('.subjective-field');
      Elem.forEach(function (el) { el.style.height = el.scrollHeight + "px"; });
    }, 100);

    if (this.state.detect_change && !this.state.syncing_to_server) {
      await this.handleSubjectiveFormSubmit();
    }
  }

  async componentDidMount() {
    await this.handleSubjectivesData();

    this.subscription = speechTextService.status().subscribe(async data => {
      const { patient } = this.state;
      if (this.state.speech.state) {
        if (this.state.speech.identifier === 'chief_complaint' || this.state.speech.identifier === 'review_of_systems') {
          if (this.state.speech.identifier === 'chief_complaint') {
            this.setState({ chief_complaint: data.text, detect_change: true }, () => { /* this.handleConfirmBtnStatus() */ });
            LS_SERVICE.set("chief_complaint_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
          }
          if (this.state.speech.identifier === 'review_of_systems') {
            this.setState({ review_of_systems: data.text, detect_change: true }, () => { /* this.handleConfirmBtnStatus() */ });
            LS_SERVICE.set("review_of_systems_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
          }
          document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
        }
      }
    });
    this.handlePrescriptionData();
    let list = LS_SERVICE.get('call_patient_detail').list;
    if(list == 'worklist' || list == 'pending'){
    this.handelDefaultText();
    }

    /* setInterval(() => { 
      if(this.state.detect_change && !this.state.syncing_to_server){
        console.log(this.state.detect_change)
        this.handleSubjectiveFormSubmit();
      } 
    }, 2000); */
  }

  handleSubjectivesData = async () => {
    const { patient } = this.state;
    const subjectivePromise = await Axios.get(SUBJECTIVE_INFRMATION_CHIEF_COMPLAINTS({ reference_type: REFERENCE_TYPE, reference_id: this.state.reference_id }));
    if (subjectivePromise.data.status) {
      let { data } = subjectivePromise.data;
      let { details, subjectives, chief_complaint, review_of_systems } = data;

      details.forEach(sub => {
        if (sub.subjective_id == '0') {
          chief_complaint = sub.description
        } else if (sub.subjective_id == '2') {
          review_of_systems = sub.description;
        }
      });

      this.setState({
        details,
        subjective_fields: subjectives,
        chief_complaint: LS_SERVICE.get("chief_complaint_" + patient.patient_id + "_" + patient.qms_token_id) !== null ? LS_SERVICE.get("chief_complaint_" + patient.patient_id + "_" + patient.qms_token_id) : (chief_complaint || ''),
        review_of_systems: LS_SERVICE.get("review_of_systems_" + patient.patient_id + "_" + patient.qms_token_id) !== null ? LS_SERVICE.get("review_of_systems_" + patient.patient_id + "_" + patient.qms_token_id) : (review_of_systems || ''),
        visitDataLoaded: true,
      });
    }
  }

  handlePrescriptionData = () => {
    const { patient } = this.state;
    const { patient_id } = patient;
    let  doctor_id = LS_SERVICE.get("staff_id");
    let details = LS_SERVICE.get('call_patient_detail');
    let is_junior_doctor = LS_SERVICE.get('is_junior_doctor');
    if (is_junior_doctor == true && is_junior_doctor != null && is_junior_doctor != undefined) {
      doctor_id = details.doctor_id;
    }
    Axios.get(COMPLETED_DOCTOR_VISIT({ patient_id, doctor_id }))
      .then(success => {
        if (success.data.hasOwnProperty("visits") === true) {
          var final_prescription = [];
          for (var prescription of success.data.visits) {
            if (
              (LS_SERVICE.get('slot_hospital_master') == 'Y' && prescription.own_master == 'Y' && LS_SERVICE.get('slot_hospital_id') == prescription.hospitalId)
              ||
              (LS_SERVICE.get('slot_hospital_master') == 'N' && prescription.own_master == 'N')
            ) {
              final_prescription.push(prescription);
            }
          }
          this.setState({
            prescriptionData: final_prescription,
            totalVisit: final_prescription.length
          });
        }

      }).catch(err => console.log(err));

  }

  setFocusedElement(event) {
    this.focusedElement = event.currentTarget;
  }

  handleOnChange = event => {
    const { name, value } = event.currentTarget;
    const selectionStart = event.currentTarget.selectionStart;
    const selectionEnd = event.currentTarget.selectionEnd;
    const currentElemenVal = value.charAt(0).toUpperCase() + value.slice(1);
    const { patient } = this.state;
    var identifierValue = event.target.id;

    LS_SERVICE.set(event.currentTarget.name + "_" + patient.patient_id + "_" + patient.qms_token_id, event.currentTarget.value);
    this.setState((prevState) => ({
      [name]: currentElemenVal,
      detect_change: true,
      speech: {
        ...prevState.speech,
        identifier: identifierValue,
        initialText: currentElemenVal
      },
      checkForEditsTrainingData: {
        ...prevState.checkForEditsTrainingData,
        [event.target.id]: true
      }
    }), () => {
      this.focusedElement.setSelectionRange(selectionStart,selectionEnd);
    })

  }

  initSpeech(info) {
    let initialText = '';
    if (info.identifier === 'chief_complaint') {
      initialText = this.state.chief_complaint != undefined ? this.state.chief_complaint : '';
    }
    if (info.identifier === 'review_of_systems') {
      initialText = this.state.review_of_systems != undefined ? this.state.review_of_systems : '';
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {

        // console.log("In IF info.identifier===state.speech calling with : ", this.state.speech)
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
        }

      });
    } else {
      // console.log("IN Different MIC Previous Unclosed")
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
          this.handleRestartMic(info, initialText)
        }
        // console.log("State in init:",this.state.speech)

      });

    }
  }

  handleRestartMic = (info, initialText) => {
    // debugger
    this.setState({
      speech: {
        identifier: info.identifier,
        state: true,
        initialText: initialText
      }
    }, () => {
      // console.log("State in init:",this.state.speech)

      if (!this.state.speech.state) {
        this.setState((prevState) => ({
          checkForEditsTrainingData: {
            ...this.state.checkForEditsTrainingData,
            [info.identifier]: false
          }
        }))
      }

      speechService.init(this.state.speech);
    });

  }

  handleOnFocus = (e) => {
    var identifierValue = this.state.speech.identifier === e.target.id ? e.target.id : this.state.speech.identifier;
    this.setState(prevState => ({
      speech: {
        ...prevState.speech,
        identifier: identifierValue,
        state: false,
        initialText: identifierValue === "chief_complaint" ? this.state.chief_complaint : identifierValue === "review_of_systems" ? this.state.review_of_systems : ''
      },
      checkForEditsTrainingData: {
        ...prevState.checkForEditsTrainingData,
        [e.target.id]: false
      }
    }), () => {
      speechService.init(this.state.speech)
      // this.initSpeech({identifier: e.target.id})
    })

  }

   handleSubjectiveFormSubmit = async(data) => {
    const { review_of_systems, chief_complaint, patient } = this.state;

    //Collecting Training Data
    this.setState(prevState => ({
      syncing_to_server: true,
      /*  speech: {
         ...prevState.speech,
         state: false,
       } */
    }), () => {
      //speechService.init(this.state.speech)
      var targetArrays = []
      targetArrays.push({ id: 'chief_complaint' }, { id: 'review_of_systems' });
      //Collecting Training Data
      targetArrays.map(e => {
        var obj = {
          booking_id: +this.state.reference_id,
          source: +LS_SERVICE.get('staff_id'),
          doctor_id: 1605,
          step: this.state.checkForEditsTrainingData[e.id] ? 1 : 0,
          input_text: e.id === "chief_complaint" ? chief_complaint : e.id === "review_of_systems" ? review_of_systems : '',
          field_type: this.state.trainingDatafieldtypeSetter[e.id]
        }
        this.trainingData.push(obj)
      })
      this.trainingData.map(e => {
        this.callTrainingDataApi(e)
      })
      //Collecting Training Data
    })

    //Collecting Training Data

    this.trainingData = [];
    
    const { qms_token_id, patient_id } = patient;

    const PARAMS = {
      reference_type: REFERENCE_TYPE,
      reference_id: qms_token_id,
      subjectives: [{
        id: 0,
        description: chief_complaint
      }, {
        id: 2,
        description: review_of_systems
      }]
    };

    let submitPromise;

    if (this.state.allowEdit) {
      submitPromise = await Axios.post(SUBJECTIVE_STORE_CHIEF_COMPLAINTS, PARAMS);
      this.props.subjectiveChange('cc_hpi');
      // if(data != undefined){
      //   this.setState({notesModel:false})
      // }
    }

    if (this.state.allowEdit || submitPromise.data?.status) {
      await this.setState({
        detect_change: false,
        syncing_to_server: false,
      })
      LS_SERVICE.set("chief_complaint_" + patient_id + "_" + qms_token_id, "");
      LS_SERVICE.set("review_of_systems_" + patient_id + "_" + qms_token_id, "");
    }
  }

  handleModalPopup = (e, popupName, data) => {
    
    if (e !== null) e.preventDefault();
    this.setState({
      [popupName]: !this.state[popupName]
    }, () => {
      this.setState({
        showPrescriptionDetails: true,
        prescriptionDataLoading: false
      }, () => {

        const { copyPrescriptionModal, showFinalCopyPrescriptionModal } = this.state;
        if (!showFinalCopyPrescriptionModal) {
          this.setState({
            selected_prescription_id: null
          })
        }
        if (copyPrescriptionModal) {
          this.setState({
            prescriptionTitle: "Please select the prescription you wish to copy from"
          })
        }

      })
    })
  }

  prescriptionDetailsBack = (e) => {
    e.preventDefault();
    this.setState({
      showPrescriptionDetails: true,
      prescriptionTitle: "Please select the prescription you wish to copy from"
    })
  }

  prescriptionViewDetails = (e, name, date, referenceID) => {

    
    e.preventDefault();
    this.setState({
      showPrescriptionDetails: false,
      prescriptionDataLoading: true,
      prescriptionTitle: (
        <div className="row pb-2 emrfrm">
          <div className="col-md-6">
            <div className="text-left page-title btn-bg-accordion ml-0 rounded px-2 py-2 mt-1">{name}
              <small className="float-right">{moment(date).format('DD MMM, YYYY | hh:mm A')}</small></div>
          </div>
          <div className="col-md-6 text-right" id="accordionPastVisit">
             <div className="btn btn-outline-primary small text-uppercase mr-2 mt-1 d-inline-flex align-items-center icon-hover-white"
              onClick={(e) => this.IsApplyOrdersets(e, referenceID, true)}>
              {/* <img height="20" src={copyImg} alt="Copy" />  */}
              <i className="icon-copy"></i>
              COPY FROM THIS PRESCRIPTION</div>
            
            <a href="#" className="btn btn-outline-secondary px-3 small text-uppercase mr-4 mt-1" onClick={e => this.prescriptionDetailsBack(e)}>BACK</a>
          </div>
        </div>
      )
    })
    const PARAMS = {
      reference_type: REFERENCE_TYPE,
      patient_id: this.state.patient.patient_id,
      reference_id: referenceID,  //this.state.patient.qms_token_id,
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      type: 'SS'
    }

    PARAMS.store_id = LS_SERVICE.get("STORE_ID");
    PARAMS.facility_id = LS_SERVICE.get("FACILITY_ID");
    PARAMS.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");

    Axios.post(SOAP_DETAILS, qs.stringify(PARAMS))
      .then((success) => {
        if (success.data.hasOwnProperty("details") === true) {
          this.setState({
            prescriptionDetailsData: success.data.details,
            prescriptionDataLoading: false,
          })
          
          
        }
      })
      .catch((err) => { console.log(err) })
  }

  showPolyModal = () => {
    this.setState({ polyPharmacyModal: true });
  };

  handlePolyCancel = (e) => {
    this.setState({ polyPharmacyModal: false, showPolyModal: false, refId: "" });
  };

  handlePolyOk = async (e) => {
    await this.setState({ polyPharmacyModal: false, showPolyModal: true })
    if (!this.state.showPrescriptionDetails) {
      this.handleProceedPrescription(e, this.state.refId, true);
    } else {
      this.handleOnChangeprescription(null, this.state.refId);
    }
  }

  handleOnChangeprescription = async (e, referenceID) => {
    if (e != null) {
      this.setState({ selectedInputId: e.target.id })
    }
    if (this.state.refId == "") {
      const requestOne = Axios.post(VIEW_VISIT, qs.stringify({ referenceType: REFERENCE_TYPE, referenceId: referenceID }));;
      const requestTwo = Axios.get(MEDICINES_GETALL_CURRENT_MEDICINES({ patient_id: this.state.patient.patient_id, reference_type: 0, reference_id: referenceID }));
      await Axios.all([requestOne, requestTwo]).then(Axios.spread((...responses) => {
        const responseOne = responses[0]
        const responseTwo = responses[1]
        if (responseOne.data.hasOwnProperty("data") === true) {
          this.setState({
            medicinerefDetails: responseOne.data.data.drugs_data,
          })
        }

        this.setState({
          currentMedsLists: responseTwo.data.data.details?.current_medicines,
        })

      })).catch((err) => { console.log(err) })
    }
    const { currentMedsLists, medicinerefDetails } = this.state
    if (currentMedsLists.length > 0) {
      let activeMed = currentMedsLists.filter((med) => { if (med.prescriptionDrugActive == 'Y') { return med.drug_id } })
      activeMed = activeMed.map((med) => { if (med.prescriptionDrugActive == 'Y') { return med.drug_id } })
      activeMed = activeMed.filter(function (item, pos) { return activeMed.indexOf(item) == pos; })
      this.setState({
        activeMed: activeMed,
      })
      //Show poly pharmacy model
      if (this.state.activeMed?.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.showPolyModal) {
        document.getElementById(this.state.selectedInputId).checked = false;
        this.setState({
          refId: referenceID
        })
        this.showPolyModal()
        return;
      }
    }

    var dupMedicine = [];
    currentMedsLists.map((med, i) => {

      medicinerefDetails.map((element) => {
        if (element.drug_id == med.drug_id && med.prescriptionDrugActive == 'Y')
          dupMedicine.push(med)
      })
    })
    var ids = dupMedicine.map(o => o.drug_id)
    var filteredMed = dupMedicine.filter(({ drug_id }, index) => !ids.includes(drug_id, index + 1))

    // show medicine model
    if (dupMedicine.length > 0) {
      await this.setState({ presMedicine: dupMedicine, showDupMed: filteredMed, duplicateMedModel: true, refId: referenceID })
      document.getElementById(this.state.selectedInputId).checked = false;
    } else {
      await this.setState({
        selectPrescription: referenceID,
        isConfirm: true,
        refId: "",
        currentMedsLists: [],
        medicinerefDetails: []
      })
    }

  }

  async handleMedPrecribedCancel() {
    await this.setState({
      duplicateMedModel: false,
      refId: "",
      currentMedsLists: [],
      medicinerefDetails: []
    })

  }

  stopCurentMeds(e) {
    const { presMedicine } = this.state;
    let stopDrugIds = presMedicine.map((drugDetailsId) => { return drugDetailsId.prescriptionDrugId })
    let doctor_id = LS_SERVICE.get('staff_id');
    let params = {
      stopDrugIds: stopDrugIds,
    }
    let stopPrescriptionDrugId = null;
    Axios.put(CURRENT_MEDICINES_STOP({ stopPrescriptionDrugId, doctor_id }), params).then(res => {
      this.setState({
        duplicateMedModel: false, currentMedsLists: [],
        medicinerefDetails: []
      })
      if (!this.state.showPrescriptionDetails) {
        this.handleProceedPrescription(null, this.state.refId, false);
      } else {
        this.handleOnChangeprescription(null, this.state.refId);
      }
      notification.success({
        message: 'Drug stopped successfully',
        placement: 'topRight'
      });
    }).catch(
      err => {
        notification.error({
          message: 'failed to stop medicine',
          placement: 'topRight'
        });
        console.log(err);
      }
    );
  }

  handleCancelProceed = (e) => {
    this.setState({
      selectPrescription: '',
      isConfirm: false
    })
    this.handleModalPopup(e, 'confirmPrescriptionModal');
  }

  handleConfirmPrescription  = async (e) => {
    const PARAMS = {
      reference_type: REFERENCE_TYPE,
      patient_id: this.state.patient.patient_id,
      reference_id: this.state.selectPrescription,  //this.state.patient.qms_token_id,
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      type: 'SS'
    }

    PARAMS.store_id = LS_SERVICE.get("STORE_ID");
    PARAMS.facility_id = LS_SERVICE.get("FACILITY_ID");
    PARAMS.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");

    await Axios.post(SOAP_DETAILS, qs.stringify(PARAMS))
      .then((success) => {
      
        if (success.data.hasOwnProperty("details") === true) {
          this.setState({
            prescriptionDetailsData: success.data.details,
            prescriptionDataLoading: false,
            
          })
          
          
        }
      })
      .catch((err) => { console.log(err) })
      this.IsApplyConfirmsets(e);
  }

  handleProceedPrescription = async (e, referenceID, showPopup) => {
    if (typeof showPopup != 'undefined' && showPopup == true) {
      const requestOne = Axios.post(VIEW_VISIT, qs.stringify({ referenceType: REFERENCE_TYPE, referenceId: referenceID }));;
      const requestTwo = Axios.get(MEDICINES_GETALL_CURRENT_MEDICINES({ patient_id: this.state.patient.patient_id, reference_type: 0, reference_id: referenceID }));
      await Axios.all([requestOne, requestTwo]).then(Axios.spread((...responses) => {
        const responseOne = responses[0]
        const responseTwo = responses[1]
        if (responseOne.data.hasOwnProperty("data") === true) {
          this.setState({
            medicinerefDetails: responseOne.data.data.drugs_data,
          })
        }

        this.setState({
          currentMedsLists: responseTwo.data.data.details?.current_medicines,
        })

      })).catch((err) => { console.log(err) })

      const { currentMedsLists, medicinerefDetails } = this.state
      if (currentMedsLists.length > 0) {
        let activeMed = currentMedsLists.filter((med) => { if (med.prescriptionDrugActive == 'Y') { return med.drug_id } })
        activeMed = activeMed.map((med) => { if (med.prescriptionDrugActive == 'Y') { return med.drug_id } })
        activeMed = activeMed.filter(function (item, pos) { return activeMed.indexOf(item) == pos; })
        this.setState({
          activeMed: activeMed,
        })
        //Show poly pharmacy model
        if (this.state.activeMed?.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.showPolyModal) {
          this.setState({
            refId: referenceID
          })
          this.showPolyModal()
          return;
        }
      }

      var dupMedicine = [];
      currentMedsLists.map((med, i) => {

        medicinerefDetails.map((element) => {
          if (element.drug_id == med.drug_id && med.prescriptionDrugActive == 'Y')
            dupMedicine.push(med)
        })
      })
      var ids = dupMedicine.map(o => o.drug_id)
      var filteredMed = dupMedicine.filter(({ drug_id }, index) => !ids.includes(drug_id, index + 1))

      // show medicine model
      if (dupMedicine.length > 0) {
        await this.setState({ presMedicine: dupMedicine, showDupMed: filteredMed, duplicateMedModel: true, refId: referenceID })
        return;
      }

    }
    const { patient, hospital_id } = this.state;
    const { qms_token_id, patient_id } = patient;
    let reference_id = this.state.selectPrescription;

   
    if (referenceID !== '') {
      reference_id = referenceID;
      this.handleModalPopup(e, 'copyPrescriptionModal');
    } else {
      this.handleModalPopup(e, 'confirmPrescriptionModal');
    }

    this.setState({
      selectPrescription: '',
      isConfirm: false,
      selected_prescription_id: reference_id,
      refId: "",
      currentMedsLists: [],
      medicinerefDetails: []
    }, () => {
      this.handleModalPopup(e, 'showFinalCopyPrescriptionModal');
      /* this.props.history.push({ // first is old reference id ( COPY PRESCRIPTION REFERNCE ID ) and second is new reference id ( IS CURRENT REFERENCE )
        pathname: `/copy-visit/selected/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}/${REFERENCE_TYPE}/${qms_token_id}`,
        state: { patient }
      }); */
    })
  }

  updateData = () => {
    this.handleModalPopup(null, 'showFinalCopyPrescriptionModal');
    this.handleSubjectivesData();
    this.props.loadCopyPrescriptionAddedData();
  }

   IsApplyConfirmsets = async (e) => {

    this.handleModalPopup(e, 'copyPrescriptionModal');
    this.handleModalPopup(e, 'confirmPrescriptionModal');

  //   console.log("DDDDDDDDDDDDDDDDDDDDDD1");
  //   return false;
  //   let arr                 = [];
  //   let drugIds             = [];
  //   let addedAssessmentList = [];

  //   let {prescriptionDetailsData,referenceId,StoreId,FacilityId,enterpriseId,patientId} = this.state;
  //   if(prescriptionDetailsData.assessments  && prescriptionDetailsData.assessments.length > 0){
  //     var drugassessments  = prescriptionDetailsData.assessments;
  //       drugassessments.map((med, i) => {
  //         addedAssessmentList.push(med.diagnosisId)
  //       });
  //       addedAssessmentList = addedAssessmentList.join('-');
  //   }else{
  //       addedAssessmentList = 0;

  //   }

  //   if(prescriptionDetailsData.medicines  && prescriptionDetailsData.medicines.length > 0){
  //     var drugmedicines  = prescriptionDetailsData.medicines;
  //     drugmedicines.map((med, i) => {
  //       drugIds.push(med.drug_id)
  //       });
  //       drugIds = drugIds.join('-')
  //   }else{
  //       drugIds = 0

  //   }
  //   let referenceType       = 0;
  //   let orederSetId         = 0;
  //   let careProtoColId      = 0;
  //   let medicineScreen      = 'careplanform';
  //   let InteractionModal    = false;

  //   if(CIMSALLOW){
  //   await axios.get(CIMS_CAREPLAN_INTERACTION_EXISTS({ drugIds,referenceType,referenceId,orederSetId,patientId,enterpriseId,FacilityId,StoreId,careProtoColId,medicineScreen,addedAssessmentList}))
  //     .then(success => {
  //       if (success.data.interaction) {
  //         InteractionModal = true;
  //         this.setState({ drug_id_Interaction_Modalurl: drugIds,referenceType:referenceType,referenceId:referenceId,orederSetId:orederSetId,enterpriseId:enterpriseId,FacilityId:FacilityId,StoreId:StoreId,patientId:patientId,isModalInteraction: InteractionModal,addedAssessmentList:addedAssessmentList});
  //       }
  //       else{
  //         this.handleModalPopup(e, 'copyPrescriptionModal');
  //         this.handleModalPopup(e, 'confirmPrescriptionModal');
  //       }
  //     })
  //     .catch(err => console.log(err))
  //   }else{
  //     this.handleModalPopup(e, 'copyPrescriptionModal');
  //     this.handleModalPopup(e, 'confirmPrescriptionModal');
  //   }
   }

  IsApplyOrdersets = async (e,referenceID) => {
    let arr                 = [];
    let drugIds             = [];
    let addedAssessmentList = [];
    let {prescriptionDetailsData,referenceId,StoreId,FacilityId,enterpriseId,patientId} = this.state;
  
    if(prescriptionDetailsData.assessments  && prescriptionDetailsData.assessments.length > 0){
      var drugassessments  = prescriptionDetailsData.assessments;
        drugassessments.map((med, i) => {
          addedAssessmentList.push(med.diagnosisId)
        });
        addedAssessmentList = addedAssessmentList.join('-');
    }else{
        addedAssessmentList = 0;

    }

    if(prescriptionDetailsData.medicines  && prescriptionDetailsData.medicines.length > 0){
      var drugmedicines  = prescriptionDetailsData.medicines;
      drugmedicines.map((med, i) => {
        drugIds.push(med.drug_id)
        });
        drugIds = drugIds.join('-')
    }else{
        drugIds = 0

    }
    let referenceType       = 0;
    let orederSetId         = 0;
    let careProtoColId      = 1;
    let medicineScreen      = 'careplanform';
    let showInteractionModal = false;
    if(CIMSALLOW){
    axios.get(CIMS_CAREPLAN_INTERACTION_EXISTS({ drugIds,referenceType,referenceId,orederSetId,patientId,enterpriseId,FacilityId,StoreId,careProtoColId,medicineScreen,addedAssessmentList}))
      .then(success => {
        if (success.data.interaction) {
          showInteractionModal = true;
          this.setState({ drug_id_Interaction_Modalurl: drugIds,referenceType:referenceType,referenceId:referenceId,orederSetId:orederSetId,enterpriseId:enterpriseId,FacilityId:FacilityId,StoreId:StoreId,patientId:patientId,isModalInteractionVisible: showInteractionModal,referenceID:referenceID,addedAssessmentList:addedAssessmentList});
        }
        else{
          this.handleProceedPrescription (e, referenceID, true)
        }
      })
      .catch(err => console.log(err))
    }else{
      this.handleProceedPrescription (e, referenceID, true)
    }
  }


  //************** IsApplyConfirmsets
  handleConfirmInteractionOk = (e) => {
    this.setState({ isModalInteraction: false});
    this.handleModalPopup(e, 'copyPrescriptionModal');
    this.handleModalPopup(e, 'confirmPrescriptionModal');
  };

//************** IsApplyConfirmsets
handleConfirmInteractionCancel = () => { this.setState({ isModalInteraction: false }); };

  

  handleInteractionOk = (e) => { 
    e.preventDefault();
    this.setState({ isModalInteractionVisible: false})
    const {referenceID} = this.state;
    this.handleProceedPrescription (e, referenceID, true)
  };

  handleInteractionCancel = () => { this.setState({ isModalInteractionVisible: false },
    ); };

  async saveNotesModel(e,model_type){
    await this.setState({notesModelActive:true})
   const {chief_complaint, review_of_systems} = this.state;
   let input_type =  e.target.name === 'chief_complaint' ? 'cc' : 'phy';
   let content = e.target.name === 'chief_complaint' ? chief_complaint : review_of_systems;
   doctorNotesService.init({
      content:content,
      // notesModel: true,
      input_type: input_type,
      model_type: model_type
    });

   this.setState({
    content:content,
    // notesModel: true,
    input_type: input_type,
    model_type: model_type
  }) 
  }

  resetModel(){ 
    // this.setState({notesModel:false})
    // this.handelDefaultText();
  }

  saveTextBoxData(data){
    const { review_of_systems, chief_complaint, patient } = this.state;
    let String = data.map(obj => obj.content).join("\n");
     let  type = data[0]?.type;
     let newString;
     let name = 'chief_complaint';
      if(type == 'cc'){
        newString = chief_complaint !='' && chief_complaint != null && chief_complaint != null  ? chief_complaint + "\n" + String : String ;  
      }else{
        name = 'review_of_systems';
        newString = review_of_systems != '' && review_of_systems != null && review_of_systems != undefined ? review_of_systems + "\n" + String : String;
      }
      this.setState({
        [name]:newString,
        notesModelActive:false
      },()=>{
        this.handleSubjectiveFormSubmit();        
      })
    
  }


  async handelDefaultText(){
    const {default_text , chief_complaint , review_of_systems} = this.state;
    let doctor_id = LS_SERVICE.get('staff_id');
    // const res = await Axios.get(GET_DEFAULT_TEXT({ doctor_id: doctor_id}));
    if (default_text.length > 0) {
      let data = default_text;
      let arr = ['phy','cc'];
      let new_list = data.filter(obj => arr.includes(obj.type));
      new_list.map((val,i)=> {
        let name = 'chief_complaint';
        if(val.type == 'phy' && review_of_systems ==''){
          name = 'review_of_systems';
          this.setState({
            [name] :  val.content
           })
        }
        if(val.type == 'cc' && chief_complaint ==''){
          this.setState({
            [name] :  val.content
           })
        }         

      })
      this.handleSubjectiveFormSubmit();
    }

  }
  
  resetNotesModel(){
    this.setState({notesModelActive:false})
  }

  render() {
    const { patient, visitDataLoaded, prescriptionDetailsData, ss_configuration, consult_type, sourcescreen } = this.state;
    if (ss_configuration) {
      var custom_parent = ss_configuration.find(o => o?.id === 3)
      var custom1 = "";
      var custom2 = "";
      var custom3 = "";
      var custom4 = "";
      var custom5 = "";
      if (custom_parent && custom_parent.sub_items) {
        custom1 = custom_parent.sub_items.find(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom2 = custom_parent.sub_items.find(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom3 = custom_parent.sub_items.find(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom4 = custom_parent.sub_items.find(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom5 = custom_parent.sub_items.find(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
      }
    }
    return (
      <div className='row'>
        {visitDataLoaded && patient !== null ? (
          <>
            {/* <MyHcSpeechRecognition /> */}
            {this.state.subjective_fields !== null ? (
              Object.values(this.state.subjective_fields).map((sf, idx) => (
                <>
                  {
                    sourcescreen != 'singlescreen'
                      ||
                      (sourcescreen == 'singlescreen' && sf.id == 0 && ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 9 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
                      ||
                      (sourcescreen == 'singlescreen' && sf.id == 2 && ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 10 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
                      ?
                      <>
                        <div className={`col-md ${idx == 0 && this.state.totalVisit > 0 && this.state.allowEdit ? 'col-md' : 'col-md-12'}`} key={idx}>
                          <div className={`form-group micwrap ant-mic-wrap ${this.state[sf.input_name] ? 'hasdata' : ''}`}>
                            {this.state.allowEdit ? <Link
                              onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: sf.input_name }) }}
                              className={(this.state.speech.identifier === sf.input_name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                            {/* <button name={sf.input_name} style={{position:'absolute',right:'135px',top:'7px',zIndex:'9'}} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e)}>T</button> */}
                            <img name={sf.input_name} onClick={(e) => this.saveNotesModel(e,'view_model')}src={TextSaveImg} style={{position:'absolute',right:'135px',top:'7px',zIndex:'9',width:'27px',height:'auto',cursor:'pointer'} }/>
                            <button name={sf.input_name} style={{position:'absolute',right:'40px',top:'7px',zIndex:'9'}} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e,'save_model')}>Save as <span style={{border:"solid 1px #5c5c5c",padding:'0.15rem 0.25rem',borderRadius:'2px'}}>T</span></button> 
                            <textarea
                              placeholder={(sf.placeholder != 'undefined') ? sf.placeholder : ""}
                              className={(sf.placeholder != 'undefined') ? "form-control subjective-field show-placeholder dont-bind-keystrokes" : "form-control subjective-field dont-bind-keystrokes"}
                              id={sf.input_name}
                              name={sf.input_name}
                              disabled={!this.state.allowEdit}
                              style={{paddingRight:'170px'}}
                              value={this.state[sf.input_name]}
                              onChange={this.handleOnChange}
                              onFocus={(e) => {
                                this.setFocusedElement(e);
                                this.handleOnFocus(e);
                              }}
                              onBlur={this.handleOnBlur}
                              rows="1"
                              onInput={(e) => this.autoHeight(e)}>
                            </textarea>
                            <label htmlFor={sf.input_name}>{sf.display_text} {sf.required && <span className="text-secondary">*</span>}</label>
                          </div>
                        </div>
                      </>
                      :
                      null
                  }
                  {
                    idx == 0 && this.state.totalVisit > 0 && this.state.allowEdit
                      ?
                      <div className='col-md-auto pr-5'>
                        <div class="float-right pt-2">
                          <a class="btn btn-outline-primary small" id="copy_prev_hx" onClick={e => this.handleModalPopup(e, this.state.isPracticing ? 'copyPrescriptionModal' : 'isPracticingModal')}>COPY PREVIOUS RX</a>
                        </div>
                      </div>
                      :
                      null
                  }
                </>
              ))
            ) : null}
            <Modal
              title={this.state.prescriptionTitle}
              visible={this.state.copyPrescriptionModal}
              onCancel={(e) => this.handleModalPopup(e, 'copyPrescriptionModal')}
              footer={false}
              style={{ top: '10vh' }}
              width="940px"
              className="emrfrm"
              wrapClassName={this.state.showPrescriptionDetails ? "" : "prescriptionHeight"}
            >
              {this.state.showPrescriptionDetails ?
                <div className="row">
                  <div className="col-12 table-responsive">
                    <table className="table table-default">
                      <thead>
                        <tr>
                          <th style={{ width: "45px" }}>&nbsp;</th>
                          <th>Date</th>
                          <th>Doctor Name</th>
                          <th>Diagnosis</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          typeof this.state.prescriptionData !== 'undefined' ?
                            this.state.prescriptionData.map((Obj, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <div className="custom-control custom-checkbox">
                                      <input type="radio" className="custom-control-input" id={"visit_" + index} name="select_prescription"
                                        onChange={(e) => this.handleOnChangeprescription(e, Obj.reference_id)} checked={this.state.selectPrescription === Obj.reference_id} />
                                      <label className="custom-control-label" htmlFor={"visit_" + index}>&nbsp;</label>
                                    </div>
                                  </td>
                                  <td>{moment(Obj.createdAt).format('D MMM, YYYY')}</td>
                                  <td>{Obj.doctorName}</td>
                                  <td>{Obj.diagnosis.map((diagnos, i) =>
                                    <div className="row" key={i}>
                                      <div className="col-10">{(DIAGNOSIS_DO_NOT_PRINT.includes(diagnos?.diagnosis_id) == true || diagnos?.diagnosis_id ==null) && diagnos.diagnosis_notes ? diagnos.diagnosis_notes : diagnos.description}</div>
                                      <div className="col-2">{diagnos.icd_code}</div>
                                    </div>
                                  )}
                                  </td>
                                  <td><a href="#" onClick={(e) => this.prescriptionViewDetails(e, Obj.doctorName, Obj.createdAt, Obj.reference_id)}><i className="icon_view"></i></a></td>
                                </tr>

                              )
                            }) : <tr><td colSpan={6}>No previous prescription</td></tr>}

                      </tbody>
                    </table>
                  </div>
                  <div className="col-12 text-center mt-4">
                    <button type="button" className="btn btn-primary text-uppercase confirm-copy" disabled={this.state.isConfirm === false ? "true" : ""} onClick={this.handleConfirmPrescription}>CONFIRM</button>
                  </div>
                </div>
                :
                this.state.prescriptionDataLoading ?
                  <div className="row"><div className="col-12 text-center">{LOADER_RED}</div></div>
                  :
                  <div className="row">
                    {typeof prescriptionDetailsData.subjectives !== 'undefined' && prescriptionDetailsData.subjectives != null && Object.keys(prescriptionDetailsData).length > 0 ?
                      <div className="col-12">
                        <h2 className="page-title">Subjective</h2>
                        <div className="row">
                          <div className="col-12">
                            {Object.values(prescriptionDetailsData.subjectives).map((subjectivOb, index) =>
                              subjectivOb.description !== '' ?
                                <div className="form-group normal" key={index}>
                                  <label for="Chief Complaints" className="control-label mb-0">{subjectivOb?.subjective_title}<span className="text-danger">{prescriptionDetailsData?.constants?.subjectives?.[index]?.required ? '*' : ''}</span></label>
                                  <div>{subjectivOb.description}</div>
                                </div>
                                : null
                            )}
                          </div>
                        </div>
                      </div>
                      : ''}

                    {typeof prescriptionDetailsData.subjective_custom !== 'undefined' && prescriptionDetailsData.subjective_custom ?
                      <div className="col-12">
                        <div className="row">
                          { prescriptionDetailsData.subjective_custom.custom1 && ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) ? 
                            <div className="col-12">
                              <div className="form-group normal">
                                <label for="Custom Field 1" className="control-label mb-0">{custom1?.custom?.value}</label>
                                <div>{prescriptionDetailsData.subjective_custom.custom1}</div>
                              </div>
                            </div>
                            :
                            null
                          }
                          {prescriptionDetailsData.subjective_custom.custom2 && ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) ? 
                            <div className="col-12">
                              <div className="form-group normal">
                                <label for="Custom Field 2" className="control-label mb-0">{custom2?.custom?.value}</label>
                                <div>{prescriptionDetailsData.subjective_custom.custom2}</div>
                              </div>
                            </div>
                            :
                            null
                          }
                          {prescriptionDetailsData.subjective_custom.custom3 && ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) ? 
                            <div className="col-12">
                              <div className="form-group normal">
                                <label for="Custom Field 3" className="control-label mb-0">{custom3?.custom?.value}</label>
                                <div>{prescriptionDetailsData.subjective_custom.custom3}</div>
                              </div>
                            </div>
                            :
                            null
                          }
                          {prescriptionDetailsData.subjective_custom.custom4 && ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))? 
                            <div className="col-12">
                              <div className="form-group normal">
                                <label for="Custom Field 4" className="control-label mb-0">{custom4?.custom?.value}</label>
                                <div>{prescriptionDetailsData.subjective_custom.custom4}</div>
                              </div>
                            </div>
                            :
                            null
                          }
                          {prescriptionDetailsData.subjective_custom.custom5 && ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) ? 
                            <div className="col-12">
                              <div className="form-group normal">
                                <label for="Custom Field 5" className="control-label mb-0">{custom5?.custom?.value}</label>
                                <div>{prescriptionDetailsData.subjective_custom.custom5}</div>
                              </div>
                            </div>
                            :
                            null
                          }
                        </div>
                      </div>
                      : ''}

                    {typeof prescriptionDetailsData.assessments !== 'undefined' && prescriptionDetailsData.assessments !== null ?
                      <div className="col-12">
                        <div className="row mt-2">
                          <h2 className="page-title ml-2">Assessment</h2>
                          <div className="col-12">
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <td>DIAGNOSIS</td>
                                    <td>ICD 10</td>
                                    <td>STAGE</td>
                                    <td>TYPE</td>
                                    <td>STATUS</td>
                                    <td>NOTES</td>
                                    <td>IMAGE REFERENCE</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {prescriptionDetailsData.assessments.map((assessmentsObj, index) => {
                                    return (<tr key={index}>
                                      <td>
                                        {(DIAGNOSIS_DO_NOT_PRINT.includes(assessmentsObj?.diagnosisInfo?.diagnosis_id) == true || !assessmentsObj?.diagnosisInfo?.icd_code || assessmentsObj?.diagnosisInfo?.icd_code == "" || assessmentsObj?.diagnosisInfo?.icd_code == null) && assessmentsObj.diagnosisNotes ? assessmentsObj.diagnosisNotes : assessmentsObj?.diagnosisInfo?.diagnose_description}
                                      </td>
                                      <td><span className="text-primary">{assessmentsObj?.diagnosisInfo?.icd_code}</span></td>
                                      <td>
                                        {(assessmentsObj.stage !== 'undefined' && assessmentsObj.stage !== null) ?
                                          prescriptionDetailsData.constants.assessmentStage[assessmentsObj.stage]
                                          : ''
                                        }
                                      </td>
                                      <td>{(assessmentsObj.type !== 'undefined' && assessmentsObj.type !== null) ?
                                        prescriptionDetailsData.constants.assessmentType[assessmentsObj.type]
                                        : ''
                                      }
                                      </td>
                                      <td className="ant_switch_button_sm">
                                        <BootstrapSwitchButton
                                          width={90} height={24}
                                          size="xs"
                                          checked={!assessmentsObj.status}
                                          onlabel='Active'
                                          offlabel='Cured'
                                          onstyle="switch" offstyle="success"
                                          disabled
                                        // onChange={() => { this.setState({ isUserAdmin: checked }) }}
                                        />
                                      </td>
                                      <td>
                                        {(DIAGNOSIS_DO_NOT_PRINT.includes(assessmentsObj?.diagnosisInfo?.diagnosis_id) == true || !assessmentsObj?.diagnosisInfo?.icd_code || assessmentsObj?.diagnosisInfo?.icd_code == "" || assessmentsObj?.diagnosisInfo?.icd_code == null) && assessmentsObj.diagnosisNotes ? '-' : (assessmentsObj.diagnosisNotes ? assessmentsObj.diagnosisNotes : '-')}
                                      </td>
                                      <td className="image-reference-column">
                                        {(assessmentsObj.VisitAssessmentImage.length > 0 && assessmentsObj.VisitAssessmentImage[0].imagePath !== null) ?
                                          <img src={assessmentsObj.VisitAssessmentImage[0].imagePath} className="img-fluid" alt='' />
                                          : ''}                    </td>
                                    </tr>)
                                  }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      : ''}

                    {(typeof prescriptionDetailsData.medicines !== 'undefined' && prescriptionDetailsData.medicines !== null) ||
                      (typeof prescriptionDetailsData.tests !== 'undefined' && prescriptionDetailsData.tests.length > 0) ?
                      <div className="col-12 mt-3">
                        <h2 className="page-title">Plan</h2>


                        <div className="row">
                          {typeof prescriptionDetailsData.medicines !== 'undefined' && prescriptionDetailsData.medicines !== null ?
                            <>
                              <div className="col-12 page-title">Medicines</div>
                              <div className="col-12">
                                <MedicineTable drugsData={prescriptionDetailsData.medicines} viewType={'copy-from-prescription'}
                                  showAction={false} showSelectCheck={false} constants={prescriptionDetailsData.constants} />
                              </div>
                            </>
                            : ''}

                          {typeof prescriptionDetailsData.tests !== 'undefined' && prescriptionDetailsData.tests.length > 0 ?
                            <>
                              <div className="col-12 page-title  mt-2">Tests</div>
                              <div className="col-12">
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <td>Test Name</td>
                                        <td>Comments</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {prescriptionDetailsData.tests.map((testsObj, index) =>
                                        <tr key={index}>
                                          <td>{testsObj.test_name}</td>
                                          <td>{testsObj.instructions}</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                            : ''}

                        </div>
                      </div>
                      : ''}

                    {typeof prescriptionDetailsData.instructions !== 'undefined' && prescriptionDetailsData.instructions !== null ?
                      <div className="col-12 mt-3">
                        <h1 className="page-title">Instructions</h1>
                        <ul className="custom-list list-success">
                          {prescriptionDetailsData.instructions.map((instructionObj, index) =>
                            <li key={index}>{instructionObj.instruction}</li>
                          )}
                        </ul>
                      </div>
                      : ''}

                    {typeof prescriptionDetailsData.supportingMaterials !== 'undefined' && prescriptionDetailsData.supportingMaterials !== null && prescriptionDetailsData.supportingMaterials.length > 0 ?
                      <div className="col-12 mt-3">
                        <h1 className="page-title">Supporting Materials</h1>
                        <ul className="custom-list list-success">
                          {prescriptionDetailsData.supportingMaterials.map((Obj, index) =>
                            <li key={index} style={{ cursor: 'default' }} >
                              <a style={{ cursor: 'default' }}>{Obj.file_name}</a>
                            </li>
                          )}
                        </ul>
                      </div>
                      : ''}
                  </div>

              }
            </Modal>

            <Modal
              title={false}
              visible={this.state.confirmPrescriptionModal}
              onCancel={(e) => this.handleModalPopup(e, 'confirmPrescriptionModal')}
              footer={false}
              closable={false}
              width="400px"
            >
              <div className="row">
                <div className="col-12 text-center">
                  Please ensure all information on this page is true to the patient's conditions as on today.
                </div>
                <div className="col-12 text-center mt-3">
                  <button type="button" className="btn btn-outline-secondary px-4 small  mr-2" onClick={(e) => this.handleCancelProceed(e)}>CANCEL</button>
                  <button type="submit" className="btn btn-primary px-4 small" onClick={(e) => this.handleProceedPrescription(e, '')}>PROCEED</button>
                </div>
              </div>
            </Modal>

            {
              this.state.selected_prescription_id != null && this.state.patient.qms_token_id && this.state.showFinalCopyPrescriptionModal ?
                <CopyToVisitSS
                  match={{ params: { reference_id: String(this.state.patient.qms_token_id), qms_token_id: this.state.selected_prescription_id, hospital_id: this.state.hospital_id, patient_id: this.state.patient_id } }}
                  location={{ state: { patient: this.state.patient, sameRxdate: '' }, pathname: '/copy-visit/selected' }}
                  type={'popup'}
                  visible={'false'}
                  updateData={this.updateData}
                />
                : null
            }

            {this.state.notesModelActive ?
            <NotesSaveModel  content={this.state.content} resetModel={this.resetModel} resetNotesModel={this.resetNotesModel} saveTextBoxData={this.saveTextBoxData} input_type={this.state.input_type} model_type={this.state.model_type}/>:null}
            
            {/*  medicine Alert  */}
            <Modal
              title="Medicine Prescribed Alert"
              visible={this.state.duplicateMedModel}
              onCancel={(e) => this.handleMedPrecribedCancel()}
              footer={false}
              closable={false}
            >
              <div className="row mb-3">
                {
                  this.state.showDupMed && this.state.showDupMed.length > 0 ?
                    <div className="col-12 text-primary"><span>
                      {this.state.showDupMed.map((drug, i) => {
                        if (this.state.showDupMed.length == i + 1) {
                          return drug.drug_name + "  "
                        } else {
                          return drug.drug_name + "  , "
                        }
                      }
                      )}
                    </span>
                      {this.state.showDupMed.length > 1 ?
                        <span className="font-weight-bold"> Medicines are already prescribed to the patient. Are you sure you want to stop the medication and prescribe again?</span>
                        :
                        <span className="font-weight-bold"> Medicines is already prescribed to the patient. Are you sure you want to stop the medication and prescribe again?</span>
                      }
                    </div>
                    : null
                }

              </div>
              <div className="modal-footer justify-content-center emrfrm pb-0">
                <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleMedPrecribedCancel(e)}>No</button>
                <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.stopCurentMeds(e)}>YES</button>
              </div>
            </Modal>


            <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
              title="Drug Interaction"
              visible={this.state.isModalInteraction}
              onOk={(e) => this.handleConfirmInteractionOk(e)}
              onCancel={() => this.handleConfirmInteractionCancel()}
              footer={false}
              style={{width:1000}}
              zIndex={9999}
              >
              
              <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMS_CAREPLAN_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.referenceId}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${0}&careProtoColId=${this.state.careProtoColId}&medicineScreen=${'careplanform'}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
              <div className="modal-footer justify-content-center emrfrm pb-0">
                <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleConfirmInteractionCancel(e)}>DON’T PROCEED</button>
                <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleConfirmInteractionOk(e)}>PROCEED TO ORDER</button>
              </div>
           </Modal>


           <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
              title="Drug Interaction"
              visible={this.state.isModalInteractionVisible}
              onOk={(e) => this.handleInteractionOk(e)}
              onCancel={() => this.handleInteractionCancel()}
              footer={false}
              style={{width:1000}}
              zIndex={9999}
              >
              
              <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMS_CAREPLAN_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.referenceId}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${0}&careProtoColId=${this.state.careProtoColId}&medicineScreen=${'careplanform'}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
            
              <div className="modal-footer justify-content-center emrfrm pb-0">
                <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleInteractionCancel(e)}>DON’T PROCEED</button>
                <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleInteractionOk(e)}>PROCEED TO ORDER</button>
              </div>
           </Modal>
            {/*  Poly pharmacy alert   */}
            <Modal
              title="Poly Pharmacy Alert"
              visible={this.state.polyPharmacyModal}
              onCancel={(e) => this.handlePolyCancel()}
              footer={false}
              closable={false}
            >
              <div className="row mb-3">
                <div className="col-12">The patient is already on {this.state.activeMed?.length} medications. Are you sure you want to prescribe more?</div>
              </div>
              <div className="modal-footer justify-content-center emrfrm pb-0">
                <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handlePolyCancel(e)}>No</button>
                <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handlePolyOk(e)}>Yes</button>
              </div>
            </Modal>

            <Modal
              destroyOnClose={true}
              maskClosable={false}
              title={``}
              visible={this.state.showFinalCopyPrescriptionModal}
              onCancel={e => this.handleModalPopup(e, 'showFinalCopyPrescriptionModal')}
              footer={false}
              width="1000px"
            >
              {this.state.selected_prescription_id != null && this.state.patient.qms_token_id ?
                <CopyToVisitSS
                  match={{ params: { reference_id: String(this.state.patient.qms_token_id), qms_token_id: this.state.selected_prescription_id, hospital_id: this.state.hospital_id, patient_id: this.state.patient_id } }}
                  location={{ state: { patient: this.state.patient, sameRxdate: '' }, pathname: '/copy-visit/selected' }}
                  type={'popup'}
                  updateData={this.updateData}
                />                
              : null}
            </Modal>
          </>
        ) : (
          <div className="w-100 align-items-center justify-content-center mt-4 d-flex">
            {LOADER_RED}
          </div>
        )}
      </div>
    )
  }
}

export default ChiefComplaintsHPI;